import React, { useState } from "react";
import style from "./PortfolioStock.module.css";
import TextField from "@mui/material/TextField";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
function PortfolioStock(props) {
  const {
    searchString,
    strikePrice,
    stockData,
    searchHandler,
    onSubmitHandle,
    holdingData,
    onSubmitStockSellHandle,
  } = props;
  const [loading, setLoading] = useState(null);
  const [inputValue, setInputValue] = useState(0);
  const [inputPrice, setInputPrice] = useState(0);

  if (strikePrice.length === 0) return <>loading...</>;
  const submitHandle = (strike) => {
    console.log(stockData[strike].LTP, inputValue, inputPrice);
    onSubmitHandle(strike, Number(inputPrice), Number(inputValue));
  };
  const submitHandleSell = (strike) => {
    onSubmitStockSellHandle(strike, inputPrice, inputValue);
  };
  return (
    <div className={style.stockDetailsContainer}>
      <TextField
        style={{ width: "47%" }}
        label=""
        id="fullWidth"
        onChange={searchHandler}
        value={searchString}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />{" "}
      {/* give me button */}
      <table className={style.tableContainer} data-theme={props.theme}>
        <thead>
          <tr>
            {/* call columns */}
            <th className={style.strike_prices}>Strike Price</th>
            <th>ATP</th> <th>LTP</th> <th>High</th> <th>Low</th> <th>Open</th>{" "}
            <th>Volume</th>
            <th>LTQ</th>
            <th>Buy/Sell</th>
          </tr>
        </thead>
        <tbody>
          {strikePrice
            .filter((item) => {
              return searchString === ""
                ? item
                : item.toLowerCase().includes(searchString.toLowerCase());
            })
            .map((strike, i) => {
              let condition = false;
              return (
                <>
                  <tr>
                    {/* call columns */}
                    <td className={style.strike_prices}>{strike}</td>
                    <td>{stockData[strike].ATP}</td>{" "}
                    <td>{stockData[strike].LTP}</td>{" "}
                    <td>{stockData[strike].High}</td>{" "}
                    <td>{stockData[strike].Low}</td>{" "}
                    <td>{stockData[strike].Open}</td>{" "}
                    <td>{stockData[strike].Volume}</td>
                    <td>{stockData[strike].LTQ}</td>
                    <td>
                      <button onClick={(e) => setLoading(i)}>Buy/Sell</button>
                    </td>
                  </tr>
                  {loading === i ? (
                    <div>
                      <div className={style.item_container}>
                        <div className={style.option_price}>
                          <input
                            type="number"
                            onChange={(e) => setInputPrice(e.target.value)}
                          />
                          Buy/Sell Price
                        </div>
                        <div>
                          <div className={style.quantity}>
                            <div
                              onClick={(e) => setInputValue(inputValue - 1)}
                              className={style.value_button}
                              id={style.decrease}
                            >
                              -
                            </div>
                            <input
                              onChange={(e) => setInputValue(e.target.value)}
                              className={style.input_number}
                              type="number"
                              id="number"
                              value={inputValue}
                            />
                            <div
                              onClick={(e) => setInputValue(inputValue + 1)}
                              className={style.value_button}
                              id="increase"
                            >
                              +
                            </div>
                          </div>
                          total quantity
                        </div>
                        <div className={style.date}>
                          <input type="date" />
                        </div>
                        {holdingData[strike] == null ? (
                          ""
                        ) : (
                          <button
                            className={style.btn}
                            onClick={() => submitHandleSell(strike)}
                          >
                            Sell
                          </button>
                        )}

                        <button
                          className={style.btn}
                          onClick={() => submitHandle(strike)}
                        >
                          Buy
                        </button>
                        <button onClick={() => setLoading(null)}>Close</button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default PortfolioStock;
