import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue, off, get } from "firebase/database";

import { futureDB } from "../../firebase";
import { Line } from "react-chartjs-2";
function generateTimestampArray(timeGap) {
  const startTimestamp = new Date();
  startTimestamp.setHours(9, 15, 0, 0); // Set the start time to 9:15 AM
  const endTimestamp = new Date();
  endTimestamp.setHours(15, 30, 0, 0); // Set the end time to 3:30 PM

  const timestamps = [];
  let currentTimestamp = new Date(startTimestamp);

  while (currentTimestamp <= endTimestamp) {
    const hours = currentTimestamp.getHours();
    const minutes = currentTimestamp.getMinutes();

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const amPm = hours < 12 ? "AM" : "PM";

    const formattedTimestamp = `${formattedHours}:${formattedMinutes} ${amPm}`;

    timestamps.push(formattedTimestamp);

    // Create a new Date object to avoid modifying the same object
    currentTimestamp = new Date(currentTimestamp.getTime() + timeGap * 60000);
  }

  return timestamps;
}

function ChartPlotting(props) {
  const {
    setChartPlottingOptions,
    chartPlottingOptions,
    expiryDates,
    strikeArray,
    theme,
  } = props;
  const [chartData, setChartData] = useState([]);
  const [timeGapStamps, setTimeGapStamps] = useState([]);
  const [loading, setLoading] = useState(false);
  const strike = chartPlottingOptions.strikPrice;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  useEffect(() => {
    setChartPlottingOptions({
      ...chartPlottingOptions,
      strikPrice: strikeArray[5],
    });
  }, [strikeArray, loading]);
  useEffect(() => {
    const timegap = chartPlottingOptions.minutes;
    const timestampsArray = generateTimestampArray(timegap);
    setTimeGapStamps(timestampsArray);
  }, [chartPlottingOptions.minutes]);
  useEffect(() => {
    let tempData = [];
    let downData = [];
    const n = expiryDates.length;
    const expiry = chartPlottingOptions.expiry;
    const index = chartPlottingOptions.index;
    console.log(`/recent data/${timeGapStamps[1]}/Option/${expiry}/${index}`);
    for (let i = 0; i < timeGapStamps.length; i++) {
      const dbRef = ref(
        futureDB,
        `/recent data/${timeGapStamps[i]}/Option/${expiry}/${index}`
      );
      onValue(dbRef, (snapshot) => {
        downData.push(snapshot.val());
      });
    }

    setChartData(downData);
  }, [chartPlottingOptions, timeGapStamps]);

  useEffect(() => {
    setLoading(false);
    const timer = setTimeout(() => {
      setLoading(true);
    }, 2500);
    return () => clearTimeout(timer);
  }, [chartPlottingOptions.index]);
  console.log(chartData);
  if (!loading) return <>loading</>;
  return (
    <div data-theme={theme}>
      <div className="option-optionchain subpage">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setChartPlottingOptions({
                  ...chartPlottingOptions,
                  index: e.target.value,
                });
              }}
              value={chartPlottingOptions.index}
            >
              <option value="NIFTY">Nifty</option>
              <option value="BANKNIFTY">BankNifty</option>
            </select>
            <p>Select Index</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setChartPlottingOptions({
                  ...chartPlottingOptions,
                  strikPrice: e.target.value,
                });
              }}
              value={chartPlottingOptions.strikPrice}
            >
              {strikeArray.map((strike) => {
                return <option value={strike}>{strike}</option>;
              })}
            </select>
            <p>Select strike</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setChartPlottingOptions({
                  ...chartPlottingOptions,
                  minutes: e.target.value,
                });
              }}
              value={chartPlottingOptions.minutes}
            >
              <option value={1}>1</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
            </select>
            <p>Select time</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setChartPlottingOptions({
                  ...chartPlottingOptions,
                  expiry: e.target.value,
                });
              }}
              value={chartPlottingOptions.expiry}
            >
              {expiryDates.map((expiry) => {
                return <option value={expiry}>{expiry}</option>;
              })}
            </select>
            <p>expiry</p>
          </div>
        </div>
      </div>

      {!chartData ? (
        <>loading...</>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: "30rem",
          }}
        >
          <div
            style={{
              margin: "1rem",
              position: "relative",
              width: "90vw",
              height: "70vh",
            }}
          >
            <Line
              redraw={true}
              data={{
                labels: timeGapStamps.map((time) => time),
                datasets: [
                  {
                    data: chartData.map((data) => {
                      return (
                        data.Calls?.[chartPlottingOptions.strikPrice]?.LTP -
                        data.Calls?.[chartPlottingOptions.strikPrice]
                          ?.Prev_Close
                      );
                    }),
                    label: `Call`,
                    borderColor: "blue",
                  },
                  {
                    data: chartData.map((data) => {
                      return (
                        data.Puts?.[chartPlottingOptions.strikPrice]?.LTP -
                        data.Puts?.[chartPlottingOptions.strikPrice]?.Prev_Close
                      );
                    }),
                    label: `Put`,
                    borderColor: "red",
                  },
                ],
              }}
              options={options}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChartPlotting;
