import React from "react";
import style from "./HoldingAndPosition.module.css";
function PortfolioHolding(props) {
  const { holdingData, holdingTitle } = props;
  if (!holdingTitle) return <>loading...</>;
  return (
    <div className={style.container}>
      <div className={style.margin_left}>
        {" "}
        <h4>Holdings</h4>
      </div>

      {holdingTitle.map((title) => {
        const avgPrice = holdingData[title]?.avgPrice.toFixed(2);
        return (
          <div className={style.holding_container}>
            <div>
              <div className={style.holding_title}>{title}</div>
              <div className={style.holding_price}>
                {holdingData[title]?.currentPrice}
              </div>
            </div>
            <div className={style.item_container}>
              <div className={style.holding_profit}>
                {(
                  avgPrice * holdingData[title]?.currentPrice -
                  avgPrice * holdingData[title]?.quantity
                ).toFixed(2)}
              </div>
              <div className={style.second_item}>
                <div className={style.avgPrice}>
                  Avg. Price ₹{holdingData[title]?.avgPrice.toFixed(2)}
                </div>
                <div className={style.holding}>
                  Quantity {holdingData[title]?.quantity}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PortfolioHolding;
