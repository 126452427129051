import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import StrategyBuilderTable from "./StrategyBuilderTable";
import StrategyBuilder from "./StrategyBuilder";
import { ref, onValue } from "firebase/database";
import { futureDB } from "../../firebase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: "6px",
};

function StrategyBuilderModal(props) {
  const { expiryDates, strikeArray, data } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
  // }, []);
  return (
    <div>
      <StrategyBuilderTable data={data} />
      <div style={{ position: "absolute", bottom: 2, left: 2 }}>
        <Button
          sx={{ backgroundColor: "black", color: "white" }}
          onClick={handleOpen}
        >
          Create Strategy
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
        <Box sx={style}>
          <StrategyBuilder
            strikeArray={strikeArray}
            expiryDates={expiryDates}
            // indexWiseExpiry={indexWiseExpiry}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default StrategyBuilderModal;
