import React from "react";

import style from "./UpTable.module.css";
function UpTable({
  topFutureList15,
  topFutureList30,
  topFutureList45,
  topFutureList60,
}) {
  if (!topFutureList15) return <>loading...</>;
  return (
    <div className={style.container}>
      <div className={`${style.items} ${style.item1}`}>
        <svg
          fill="#A4CC5D"
          width="32px"
          height="32px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z" />
        </svg>
      </div>
      <div className={`${style.items} ${style.item2} ${style.columnItem}`}>
        <div className={style.updatedtime}>15 minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          <ul>
            {topFutureList15.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item3}`}></div>
      <div className={`${style.items} ${style.item4} ${style.columnItem}`}>
        <div className={style.updatedtime}>30 minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          {" "}
          <ul>
            {topFutureList30.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item5}`}></div>
      <div className={`${style.items} ${style.item6} ${style.columnItem}`}>
        <div className={style.updatedtime}>45 minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          {" "}
          <ul>
            {topFutureList45.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item7}`}></div>
      <div className={`${style.items} ${style.item8} ${style.columnItem}`}>
        <div className={style.updatedtime}>60 minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          <ul>
            {topFutureList60.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item7}`}></div>
    </div>
  );
}

export default UpTable;
