import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue, off, get } from "firebase/database";
import style from "./Indices.module.css";
import { db, futureDB } from "../../firebase";

function Indices() {
  const [strikeName, setStrikeName] = useState([]);
  const [ivcData, setIvcData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let temp = [];
    let name;
    let strike = [];
    const dbRef = ref(futureDB, `tick/Index`);
    onValue(dbRef, (snapshot) => {
      name = snapshot.val();

      for (let key in name) {
        strike.push(key);
      }
      temp = snapshot.val();
      console.log(temp);
      console.log(strike);
      if (!loading) {
        setLoading(true);
      }
    });
    setIvcData(temp);
    setStrikeName(strike);
  }, [loading]);
  return (
    <div className={style.container}>
      <table className={style.tableContainer}>
        <thead>
          <tr>
            {/* call columns */}
            <th className={style.strike_prices}>Index</th>
            <th>value</th> <th>Change</th> <th>Adv/Dec Ratio</th>{" "}
          </tr>
        </thead>
        <tbody>
          {strikeName.map((strike) => {
            return (
              <tr>
                <td>{strike}</td>
                <td>{ivcData[strike]?.LTP}</td>
                <td>
                  {(ivcData[strike]?.LTP - ivcData[strike]?.Prev_Close).toFixed(
                    2
                  )}
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Indices;
