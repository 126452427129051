import React, { useEffect, useState } from "react";
import "../../App.css";
import { db, futureDB, app } from "../../firebase";
import { onValue, ref, off, getDatabase } from "firebase/database";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";

import style from "./StraddleAndStraggle.module.css";
function StraddleAndStrangle(props) {
  const {
    straddleAndStrangleOptions,
    optionChainData,
    optionChainOptions,
    setStraddleAndStrangleOptions,
  } = props;

  const [lockStrikePrice, setLockStrikePrice] = useState([]);
  const [lockedData, setLockedData] = useState([]);
  const [lockString, setLockString] = useState("Lock");

  const [lockAddition, setLockAddition] = useState([]);

  //option state--------------------
  const [snsOptions, setSnsOptions] = useState({
    index: "NIFTY",
    numStrikePrice: 5,

    time: 0.5,
  });
  const [strikePriceData, setStrikePriceData] = useState();
  const [strikeArray, setStrikeArray] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [expiry, setExpiry] = useState();
  const [expiryDates, setExpiryDates] = useState([]);
  const [indexWiseExpiry, setIndexWiseExpiry] = useState({
    nifty: [],
    banknifty: [],
  });

  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();
      const currentDateStartOfDay = new Date(currentDate);
      currentDateStartOfDay.setHours(0, 0, 0, 0);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const fullYear = yy >= 50 ? 1900 + yy : 2000 + yy;
        const expiryDate = new Date(fullYear, mm - 1, dd); // Adjust year to 4-digit format

        // Include dates on or after the current date (ignoring time)
        return expiryDate >= currentDateStartOfDay;
      });

      setExpiryDates(expiryArray);
      // setPortfolioOptions({
      //   ...portfolioOptions,
      //   date: expiryArray[0],
      // });
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);

  useEffect(() => {
    console.log("useEffect runn");
    let niftyDate = [];
    let bankNiftyDate = [];
    for (let i = 0; i < expiryDates.length; i++) {
      const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
      onValue(dbRef, (snapshot) => {
        const temp = snapshot.val();
        if (temp) {
          if (temp.BANKNIFTY) {
            bankNiftyDate.push(expiryDates[i]);
          }
          if (temp.NIFTY) {
            niftyDate.push(expiryDates[i]);
          }
        }
      });
      setIndexWiseExpiry({
        ...indexWiseExpiry,
        nifty: niftyDate,
        banknifty: bankNiftyDate,
      });
      // setExpiry(niftyDate[0]);
    }

    niftyDate = [];
    bankNiftyDate = [];

    // const id = setTimeout(() => {
    //   setLoading(true);
    // }, 1000);

    // return () => {
    //   clearTimeout(id);
    // };
  }, [expiryDates]);

  useEffect(() => {
    const date =
      snsOptions.index === "NIFTY"
        ? indexWiseExpiry.nifty[0]
        : indexWiseExpiry.banknifty[0];
    setExpiry(date);
  }, [snsOptions["index"], indexWiseExpiry]);
  // console.log(expiry, indexWiseExpiry);

  useEffect(() => {
    // setLoading(false);
    console.log("runned option portfolio");
    function fetchData() {
      let currentcount = expiry;
      let currentindex = snsOptions["index"];
      console.log(`tick/Option/${currentcount}/${currentindex}`);
      let index = "";
      let temp = [];
      const dbRef = ref(
        futureDB,
        `tick/Option/${currentcount}/${currentindex}`
      );
      onValue(dbRef, (snapshot) => {
        // let option = portfolioOptions["optionChain"];

        temp = snapshot.val();
        setStrikePriceData(temp);

        console.log(temp);
        // setTitleList(strikeNumber);
      });
    }
    fetchData();
    // const timeToken = setTimeout(fetchData, 1000);

    // return () => {
    //   clearTimeout(timeToken);
    // };
  }, [snsOptions, indexWiseExpiry, expiry]);

  useEffect(() => {
    console.log(snsOptions["numStrikePrice"]);
    const index = snsOptions["index"] === "NIFTY" ? "NIFTY 50" : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          snsOptions["numStrikePrice"] || 5,
          snsOptions["index"] === "NIFTY" ? 50 : 100
        );

        strikePriceArray.sort();
        // setportfolioOptions({
        //   ...portfolioOptions,
        //   strikPrice: strikePriceArray[0],
        // });
      }
    });
    setStrikeArray(strikePriceArray);
  }, [snsOptions]);

  console.log(strikePriceData);
  const lockUnlockHandler = (
    strikePrice,
    call,
    put,
    addition,
    average,
    expiry,
    option
  ) => {
    const temp = {
      call,
      put,
      addition,
      average,
      strikePrice,
      expiry,
      option,
    };

    if (lockStrikePrice.indexOf(strikePrice) > -1) {
      const temp1 = lockedData;
      const data1 = temp1.filter((data) => data.strikePrice !== strikePrice);
      setLockedData(data1);

      const lockStrikePrices = lockStrikePrice;
      const index = lockStrikePrices.indexOf(strikePrice);

      if (index > -1) {
        lockStrikePrices.splice(index, 1);

        setLockStrikePrice([...lockStrikePrices]);
      }
    } else {
      setLockStrikePrice([...lockStrikePrice, strikePrice]);
      setLockedData([...lockedData, temp]);
    }
  };
  if (!strikePriceData) return <>loading</>;
  console.log(lockStrikePrice);
  return (
    <>
      <div className="option-straddleandstraggle">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setSnsOptions({
                  ...snsOptions,
                  index: e.target.value,
                });
              }}
              value={snsOptions.index}
            >
              <option value="NIFTY">Nifty 50</option>
              <option value="BANKNIFTY">Nifty Bank</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setStraddleAndStrangleOptions({
                  ...straddleAndStrangleOptions,
                  option: e.target.value,
                });
              }}
              value={straddleAndStrangleOptions.option}
            >
              <option value="Straddle">Straddle</option>
              <option value="Straggle">Straggle</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-expiry"
              id="option-optionchain-expiry"
              className="subpage-dropdown"
              onChange={(e) => {
                setExpiry(e.target.value);
              }}
              value={expiry}
            >
              {snsOptions.index === "NIFTY" ? (
                <>
                  {indexWiseExpiry.nifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <p>Expiry</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-numstrikeprice"
              id="option-optionchain-numstrikeprice"
              className="subpage-dropdown"
              onChange={(e) => {
                setSnsOptions({
                  ...snsOptions,
                  numStrikePrice: e.target.value,
                });
              }}
              value={snsOptions.numStrikePrice}
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={7}>7</option>
            </select>
            <p>Strike Prices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-time"
              id="option-straddleandstraggle-time"
              className="subpage-dropdown"
              onChange={(e) => {
                setSnsOptions({
                  ...snsOptions,
                  time: e.target.value,
                });
              }}
            >
              <option value={0}>Live</option>
              <option value={0.5}>30 Seconds</option>
              <option value={1}>1 Minute</option>
              <option value={2}>2 Minutes</option>
              <option value={3}>3 Minutes</option>
              <option value={4}>4 Minutes</option>
              <option value={5}>5 Minutes</option>
            </select>
            <p>Time</p>
          </div>
        </div>
      </div>
      <div>
        <table className={style.tableContainer}>
          <thead>
            <tr>
              {/* call columns */}
              <th className={style.strike_prices}>Strike Price</th>
              <th>Call</th> <th>Put</th> <th>Addition</th> <th>Average</th>{" "}
              <th>
                Lock<br></br>Unclok
              </th>{" "}
              <th>Change</th>
            </tr>
          </thead>
          <tbody>
            {strikeArray.map((strikePrice, index) => {
              const addition =
                ((strikePriceData?.Puts?.[strikePrice]?.["LTP"] +
                  strikePriceData?.Calls?.[strikePrice]?.["LTP"]) *
                  100) /
                (100).toFixed(2);

              const call = strikePriceData.Calls[strikePrice]?.["LTP"];
              const put = strikePriceData.Puts[strikePrice]?.["LTP"];
              const average = (
                (((strikePriceData.Calls[strikePrice]?.["LTP"] +
                  strikePriceData.Puts[strikePrice]?.["LTP"]) /
                  2) *
                  100) /
                100
              ).toFixed(2);
              const lockAddition = lockedData.filter(
                (data) => data.strikePrice === strikePrice
              );

              return (
                <>
                  {strikePriceData.Calls[strikePrice] &&
                  strikePriceData.Puts[strikePrice] ? (
                    <tr>
                      <td>
                        <b
                          style={
                            index === strikeArray.length / 2 - 0.5
                              ? {
                                  color: "rgb(30,64,186)",
                                  backgroundColor: "#D0D2DE",
                                  padding: "4px",
                                }
                              : {}
                          }
                        >
                          {strikePrice}
                        </b>
                      </td>
                      <td>{strikePriceData.Calls[strikePrice]?.["LTP"]}</td>
                      <td>{strikePriceData.Puts[strikePrice]?.["LTP"]}</td>
                      <td>
                        {(
                          ((strikePriceData.Puts[strikePrice]?.["LTP"] +
                            strikePriceData.Calls[strikePrice]?.["LTP"]) *
                            100) /
                          100
                        ).toFixed(2)}
                      </td>
                      <td>
                        {(
                          (((strikePriceData.Calls[strikePrice]?.["LTP"] +
                            strikePriceData.Puts[strikePrice]?.["LTP"]) /
                            2) *
                            100) /
                          100
                        ).toFixed(2)}
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          lockUnlockHandler(
                            strikePrice,
                            call,
                            put,
                            addition.toFixed(2),
                            average,
                            expiry,
                            straddleAndStrangleOptions.option
                          )
                        }
                      >
                        {lockStrikePrice.indexOf(strikePrice) > -1 ? (
                          <>Unlock</>
                        ) : (
                          <>Lock</>
                        )}
                      </td>
                      <td>
                        {lockAddition.length > 0 ? (
                          <span
                            style={{
                              color: `${
                                addition - lockAddition[0].addition < 0
                                  ? "red"
                                  : "green"
                              }`,
                            }}
                          >
                            {(addition - lockAddition[0].addition).toFixed(2)}
                          </span>
                        ) : (
                          <>0</>
                        )}
                      </td>
                    </tr>
                  ) : (
                    <>
                      <tr></tr>
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
        <br />
        {lockedData.length === 0 ? (
          <></>
        ) : (
          <>
            <div style={{ marginLeft: "1rem" }}> Locked Strike Prices</div>
            <table className={style.tableContainer} data-theme={props.theme}>
              <thead>
                <tr>
                  {/* call columns */}
                  <th className={style.strike_prices}>Strike Price</th>
                  <th>Call</th> <th>Put</th>
                  <th>Live Call</th> <th>Live Put</th>
                  <th>Addition</th> <th>Average</th>
                  <th>Live Add</th> <th>Live Avg</th>
                  <th>Expiry</th>
                  <th>Type</th>{" "}
                </tr>
              </thead>
              <tbody>
                {lockedData.map((strikePrice, index) => {
                  if (lockStrikePrice.indexOf(strikePrice.strikePrice) < 0)
                    return;
                  console.log(strikePrice.strikePrice);

                  const addition = (
                    ((strikePriceData?.Puts?.[strikePrice.strikePrice]?.[
                      "LTP"
                    ] +
                      strikePriceData?.Calls?.[strikePrice.strikePrice]?.[
                        "LTP"
                      ]) *
                      100) /
                    100
                  ).toFixed(2);

                  const call =
                    strikePriceData.Calls[strikePrice.strikePrice]?.["LTP"];
                  const put =
                    strikePriceData.Puts[strikePrice.strikePrice]?.["LTP"];
                  const average = (
                    (((strikePriceData.Calls[strikePrice.strikePrice]?.["LTP"] +
                      strikePriceData.Puts[strikePrice.strikePrice]?.["LTP"]) /
                      2) *
                      100) /
                    100
                  ).toFixed(2);

                  return (
                    <>
                      {strikePrice && (
                        <tr>
                          <td>
                            <b
                              style={
                                index === strikeArray.length / 2 - 0.5
                                  ? {
                                      color: "rgb(30,64,186)",
                                      backgroundColor: "#D0D2DE",
                                      padding: "4px",
                                    }
                                  : {}
                              }
                            >
                              {strikePrice.strikePrice}
                            </b>
                          </td>
                          <td>{strikePrice.call}</td>
                          <td>{strikePrice.put}</td>
                          <td>{call}</td>
                          <td>{put}</td>
                          <td>{strikePrice.addition}</td>
                          <td>{strikePrice.average}</td>
                          <td>{addition}</td>
                          <td>{average}</td>
                          <td>{strikePrice.expiry}</td>
                          <td>{strikePrice.option}</td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
}

export default StraddleAndStrangle;
