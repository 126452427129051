import React, { useEffect, useState } from "react";
import OptionChainBelowTable from "./OptionChainBelowTable";
import { getDatabase, ref, onValue, off, get } from "firebase/database";
import { app } from "../../firebase";
import { db, futureDB } from "../../firebase";

import style from "./OptionAnalysisTable.module.css";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";
import OptionAnalysisBelowTable from "./OptionAnalysisBelowTable";
function OptionAnalysisTable(props) {
  const { optionChainOptions, optionChainData, setOptionChainOptions } = props;
  const [expiryDates, setExpiryDates] = useState();
  const [componentLoading, setComponentLoading] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [optionAnalysisOptions, setOptionAnalysisOptions] = useState({
    index: "nifty50",
    lotOrValue: "lot",
    expiry: 0,
    numStrikePrice: 5,
    strikePrices: [],
    time: 0,
    count: 0,
  });
  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();

      const timeZoneOffset = 330; // 5 hours and 30 minutes in minutes
      currentDate.setMinutes(currentDate.getMinutes() + timeZoneOffset);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const date = new Date(2000 + yy, mm - 1, dd); // Adjust year to 4-digit format

        return date >= currentDate;
      });

      setExpiryDates(expiryArray);

      setOptionAnalysisOptions({
        ...optionAnalysisOptions,
        expiry: expiryArray[0],
      });
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);
  useEffect(() => {
    setLoading(false);

    const index =
      optionAnalysisOptions["index"] === "nifty50" ? "NIFTY" : "BANKNIFTY";
    const date = optionAnalysisOptions.expiry;
    console.log(`/tick/Option/${date}`);
    let niftyData = [];

    const rtdb = getDatabase(app);
    const Strikeindex =
      optionAnalysisOptions["index"] === "nifty50" ? "NIFTY 50" : "NIFTY BANK";
    const niftyLTPRef = ref(rtdb, `tick/Index/${Strikeindex}/LTP`);
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        let strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          5,
          optionAnalysisOptions["index"] === "nifty50" ? 50 : 100
        );

        strikePriceArray.sort();
        setOptionAnalysisOptions({
          ...optionAnalysisOptions,
          strikePrices: strikePriceArray,
        });
        let strikePriceHighOI = buildStrikePricesArray(
          niftyLTPData,
          5,
          optionAnalysisOptions["index"] === "nifty50" ? 50 : 100
        );

        strikePriceArray.sort();
        setOptionAnalysisOptions({
          ...optionAnalysisOptions,
          strikePrices: strikePriceArray,
        });
      }
    });

    const dbRef = ref(futureDB, `/tick/Option/${date}/${index}`);
    onValue(dbRef, (snapshot) => {
      niftyData = snapshot.val();
      if (niftyData === null) {
        console.log(optionAnalysisOptions.count);
        let countnumber = optionAnalysisOptions["count"] + 1;
        let index =
          optionAnalysisOptions["index"] === "nifty50"
            ? "NIFTY BANK"
            : "NIFTY 50";
        if (countnumber > expiryDates?.length) countnumber = 0;
        console.log(countnumber);
        setOptionAnalysisOptions({
          ...optionAnalysisOptions,
          expiry: expiryDates?.[countnumber],
          count: countnumber,
          // index: index,
        });
      }
    });
    setData(niftyData);
    setLoading(true);
  }, [optionAnalysisOptions]);
  console.log(optionAnalysisOptions);
  console.log(optionChainData);
  console.log(data);
  //   if (optionAnalysisOptions["strikePrices"].length <= 0) return <>loading...</>;
  if (!data || optionAnalysisOptions["strikePrices"].length <= 0)
    return <>loading...</>;
  return (
    <div className={style.container}>
      <div className="option-optionanalysis">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-optionanalysis-index"
              id="option-optionanalysis-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionAnalysisOptions({
                  ...optionAnalysisOptions,
                  index: e.target.value,
                });
              }}
            >
              <option value="nifty50">Nifty 50</option>
              <option value="niftybank">Nifty Bank</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionanalysis-lotorvalue"
              id="option-optionanalysis-lotorvalue"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionAnalysisOptions({
                  ...optionAnalysisOptions,
                  lotOrValue: e.target.value,
                });
              }}
            >
              <option value="lot">Lot Wise</option>
              <option value="value">Value Wise</option>
            </select>
            <p>Lot Wise/Value Wise</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionanalysis-expiry"
              id="option-optionanalysis-expiry"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionAnalysisOptions({
                  ...optionAnalysisOptions,
                  expiry: e.target.value,
                });
              }}
            >
              {expiryDates.map((expiryDate, index) => {
                return (
                  <option key={index} value={expiryDate}>
                    {expiryDate}
                  </option>
                );
              })}
            </select>
            <p>Expiry</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionanalysis-numstrikeprice"
              id="option-optionanalysis-numstrikeprice"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionAnalysisOptions({
                  ...optionAnalysisOptions,
                  numStrikePrice: e.target.value,
                });
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
            <p>Strike Prices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionanalysis-time"
              id="option-optionanalysis-time"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionAnalysisOptions({
                  ...optionAnalysisOptions,
                  time: e.target.value,
                });
              }}
            >
              <option value={0}>Live</option>
              <option value={0.5}>30 Seconds</option>
              <option value={1}>1 Minute</option>
              <option value={2}>2 Minutes</option>
              <option value={3}>3 Minutes</option>
              <option value={4}>4 Minutes</option>
              <option value={5}>5 Minutes</option>
            </select>
            <p>Time</p>
          </div>
        </div>
      </div>
      <OptionAnalysisBelowTable
        optionChainData={data}
        optionChainOptions={optionAnalysisOptions}
      />
    </div>
  );
}

export default OptionAnalysisTable;
