import React from "react";

function OiDirection(props) {
  const { data, strikeArray, intradaySignalsOptions } = props;
  let number = 1;
  if (intradaySignalsOptions.index === "NIFTY") {
    number = intradaySignalsOptions.lotOrValue === "value" ? 50 : 1;
  }
  if (intradaySignalsOptions.index === "BANKNIFTY") {
    number = intradaySignalsOptions.lotOrValue === "value" ? 15 : 1;
  }
  if (!data) return <>loading...</>;
  function callPercent() {
    const callTotal = Math.abs(
      strikeArray
        .reduce((acc, curr) => {
          const change = Number(
            data.Calls[curr].OI - data.Calls[curr].Prev_Open_Int_Close
          );

          return acc + change;
        }, 0)
        .toFixed(0)
    );

    const putTotal = Math.abs(
      strikeArray.reduce((acc, curr) => {
        const change = Number(
          data.Puts[curr].OI - data.Puts[curr].Prev_Open_Int_Close
        );

        return acc + change;
      }, 0)
    );
    const sumOfCallPut = callTotal + putTotal;
    console.log(callTotal, putTotal, sumOfCallPut);
    console.log(typeof callTotal);
    return (callTotal / sumOfCallPut) * 100;
  }
  function putPercent() {
    const callTotal = Math.abs(
      strikeArray
        .reduce((acc, curr) => {
          const change = Number(
            data.Calls[curr].OI - data.Calls[curr].Prev_Open_Int_Close
          );

          return acc + change;
        }, 0)
        .toFixed(2)
    );

    const putTotal = Math.abs(
      strikeArray.reduce((acc, curr) => {
        const change = Number(
          data.Puts[curr].OI - data.Puts[curr].Prev_Open_Int_Close
        );

        return acc + change;
      }, 0)
    );
    const sumOfCallPut = callTotal + putTotal;
    console.log(callTotal, sumOfCallPut);
    return (putTotal / sumOfCallPut) * 100;
  }
  return (
    <div>
      <table
        className="info-table"
        style={{ width: "95%", marginLeft: "1rem", marginRight: "1rem" }}
        data-theme={props.theme}
      >
        <thead>
          <tr>
            <th colSpan={2}>Call</th>{" "}
            <th className="strike-prices">Strike Prices</th>{" "}
            <th colSpan={2}>Put</th>
          </tr>
          <tr>
            {/* call columns */}
            <th>OI</th> <th>Change</th>
            <th className="strike-prices"></th>
            {/* put columns */}
            <th>Change</th> <th>OI</th>
          </tr>
        </thead>

        <tbody>
          {strikeArray.map((strike) => {
            const callChange = (
              data.Calls[strike].OI - data.Calls[strike].Prev_Open_Int_Close
            ).toFixed(2);
            const putChange = (
              data.Puts[strike].OI - data.Puts[strike].Prev_Open_Int_Close
            ).toFixed(2);
            return (
              <tr>
                {/* call columns */}
                <td>{data.Calls[strike].OI / number}</td>{" "}
                <td style={{ color: `${callChange < 0 ? "red" : "green"}` }}>
                  {callChange / number}
                </td>
                <td className="strike-prices">{strike}</td>
                {/* put columns */}
                <td style={{ color: `${putChange < 0 ? "red" : "green"}` }}>
                  {putChange / number}
                </td>{" "}
                <td>{data.Puts[strike].OI / number}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={2}>
              {strikeArray
                .reduce((acc, curr) => {
                  const change = Number(
                    (
                      data.Calls[curr].OI - data.Calls[curr].Prev_Open_Int_Close
                    ).toFixed(2)
                  );

                  return acc + change;
                }, 0)
                .toFixed(2) / number}
            </td>
            <td></td>
            <td colSpan={2}>
              {strikeArray
                .reduce((acc, curr) => {
                  const change = Number(
                    (
                      data.Puts[curr].OI - data.Puts[curr].Prev_Open_Int_Close
                    ).toFixed(2) / number
                  );

                  return acc + change;
                }, 0)
                .toFixed(2) / number}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        Call {callPercent().toFixed(2)}
        %<br />
        Put {putPercent().toFixed(2)}%
      </div>
    </div>
  );
}

export default OiDirection;
