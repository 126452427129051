import React, { useEffect, useState } from "react";
import style from "./MostActiveOption.module.css";
import { cloneDeep } from "lodash";
function MostActiveOption(props) {
  const {
    optionChainData,
    optionChainOptions,
    mostActiveOptionsOptions,
    setMostActiveOptionsOptions,
    setOptionChainOptions,
    indexWiseExpiry,
    strikeArray,
    setExpiry,
  } = props;
  console.log(optionChainData, strikeArray);
  const [mostActiveCallOption, setMostActiveCallOption] = useState([]);
  const [mostActivePutOption, setMostActivePutOption] = useState([]);
  useEffect(() => {
    const tempCallData = cloneDeep(strikeArray);

    const callStrike = tempCallData;
    const tempPutData = cloneDeep(strikeArray);

    const putStrike = tempPutData;
    console.log();
    if (mostActiveOptionsOptions.parameter == "OI") {
      const callSorted = callStrike?.toSorted((a, b) => {
        return (
          optionChainData?.calls?.[b]?.["OI"] -
          optionChainData?.calls?.[a]?.["OI"]
        );
      });
      setMostActiveCallOption(callSorted);
      const putSorted = putStrike?.toSorted((a, b) => {
        return (
          optionChainData?.puts?.[b]?.["OI"] -
          optionChainData?.puts?.[a]?.["OI"]
        );
      });
      setMostActivePutOption(putSorted);
    }
    if (mostActiveOptionsOptions.parameter == "Volume") {
      console.log(mostActiveOptionsOptions.parameter);
      const callSorted = callStrike?.toSorted((a, b) => {
        return (
          optionChainData?.calls?.[b]?.["Volume"] -
          optionChainData?.calls?.[a]?.["Volume"]
        );
      });
      setMostActiveCallOption(callSorted);
      const putSorted = putStrike?.toSorted((a, b) => {
        return (
          optionChainData?.puts?.[b]?.["Volume"] -
          optionChainData?.puts?.[a]?.["Volume"]
        );
      });
      setMostActivePutOption(putSorted);
    }
    if (mostActiveOptionsOptions.parameter == "COI") {
      const callSorted = callStrike?.toSorted((a, b) => {
        return (
          optionChainData.calls?.[b]["OI"] -
          optionChainData.calls?.[b]["Prev_Open_Int_Close"] -
          (optionChainData.calls?.[a]["OI"] -
            optionChainData.calls?.[a]["Prev_Open_Int_Close"])
        );
      });
      setMostActiveCallOption(callSorted);
      const putSorted = putStrike?.toSorted((a, b) => {
        return (
          optionChainData.puts?.[b]["OI"] -
          optionChainData.puts?.[b]["Prev_Open_Int_Close"] -
          (optionChainData.puts?.[a]["OI"] -
            optionChainData.puts?.[a]["Prev_Open_Int_Close"])
        );
      });
      setMostActivePutOption(putSorted);
    }

    // setMostActiveCallOption(callSorted);
  }, [optionChainOptions, optionChainData, mostActiveOptionsOptions]);
  console.log(mostActiveCallOption);
  if (!optionChainData) return <>loading...</>;
  return (
    <div>
      <div className="option-mostactiveoptions">
        <div className="subpage-dropdowns-container">
          {/* MOST ACTIVE OPTIONS - CALL */}
          <div className="subpage-dropdowns-call">
            <div className="dropdown-container">
              <select
                name="option-mostactiveoptions-call-index"
                id="option-mostactiveoptions-call-index"
                className="subpage-dropdown"
                onChange={(e) => {
                  setOptionChainOptions({
                    ...optionChainOptions,

                    index: e.target.value,
                  });
                }}
              >
                <option value="nifty50">Nifty 50</option>
                <option value="niftybank">Nifty Bank</option>
              </select>
              <p>Select Indices</p>
            </div>
            <div className="dropdown-container">
              <select
                name="option-optionchain-expiry"
                id="option-optionchain-expiry"
                className="subpage-dropdown"
                onChange={(e) => setExpiry(e.target.value)}
              >
                {optionChainOptions.index === "nifty50" ? (
                  <>
                    {indexWiseExpiry.nifty.map((expiryDate, index) => {
                      return (
                        <option key={index} value={expiryDate}>
                          {expiryDate}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                      return (
                        <option key={index} value={expiryDate}>
                          {expiryDate}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
              <p>Expiry</p>
            </div>
            <div className="dropdown-container">
              <select
                name="option-mostactiveoptions-call-time"
                id="option-mostactiveoptions-call-time"
                className="subpage-dropdown"
                onChange={(e) => {
                  setMostActiveOptionsOptions({
                    ...mostActiveOptionsOptions,

                    parameter: e.target.value,
                  });
                }}
              >
                <option value="COI">COI</option>
                <option value="Volume">Volume</option>
                <option value="OI">OI</option>
              </select>
              <p>Parameter</p>
            </div>
          </div>
        </div>
      </div>
      <table className={style.tableContainer}>
        <thead>
          <tr>
            {/* call columns */}
            <th className={style.strike_prices}>Option</th>
            <th>Strike Price</th> <th>LTP</th> <th>Change</th> <th>High</th>
            <th>Low</th>
            <th>Volume</th>
            <th>OI</th>{" "}
          </tr>
        </thead>
        <tbody>
          {mostActiveCallOption?.map((strikePrice, index) => {
            if (index >= 5) return;
            return (
              <tr key={index}>
                <td>Call</td>
                <td>{strikePrice}</td>
                <td>{optionChainData?.calls?.[strikePrice]?.["LTP"]}</td>
                <td>
                  {(
                    optionChainData?.calls?.[strikePrice]?.["LTP"] -
                    optionChainData.calls?.[strikePrice]?.["Prev_Close"]
                  ).toFixed(2)}
                </td>
                <td>{optionChainData?.calls?.[strikePrice]?.["High"]}</td>
                <td>{optionChainData?.calls?.[strikePrice]?.["Low"]}</td>
                <td>{optionChainData?.calls?.[strikePrice]?.["Volume"]}</td>
                <td>{optionChainData?.calls?.[strikePrice]?.["OI"]}</td>
              </tr>
            );
          })}
          {mostActivePutOption?.map((strikePrice, index) => {
            if (index >= 5) return;
            return (
              <tr key={index}>
                <td>Put</td>
                <td>{strikePrice}</td>
                <td>{optionChainData?.puts?.[strikePrice]?.["LTP"]}</td>
                <td>
                  {optionChainData.puts?.[strikePrice]?.["OI"] -
                    optionChainData.puts?.[strikePrice]?.[
                      "Prev_Open_Int_Close"
                    ]}
                </td>
                <td>{optionChainData?.puts?.[strikePrice]?.["High"]}</td>
                <td>{optionChainData?.puts?.[strikePrice]?.["Low"]}</td>
                <td>{optionChainData?.puts?.[strikePrice]?.["Volume"]}</td>
                <td>{optionChainData?.puts?.[strikePrice]?.["OI"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default MostActiveOption;
