import React, { useEffect, useState } from "react";

function OptionChainTable(props) {
  const { optionChainData, optionChainOptions, strikeArray } = props;
  const [optionChainTable, setOptionChainData] = useState();
  let number = 1;
  if (optionChainOptions.index === "nifty50") {
    number = optionChainOptions.lotOrValue === "value" ? 50 : 1;
  }
  if (optionChainOptions.index === "niftybank") {
    number = optionChainOptions.lotOrValue === "value" ? 15 : 1;
  }
  useEffect(() => {
    setOptionChainData(optionChainData);
  }, [optionChainData, optionChainOptions]);
  // console.log(strikeArray);
  console.log(optionChainData);

  if (!optionChainTable?.calls || !optionChainTable?.puts) return <>loading</>;
  return (
    <table className="info-table" data-theme={props.theme}>
      <thead>
        <tr style={{ height: "1rem" }}>
          <th colSpan={11}>Call</th>{" "}
          <th className="strike-prices">Strike Prices</th>{" "}
          <th colSpan={11}>Put</th>
        </tr>
        <tr>
          {/* call columns */}
          <th>OI</th> <th>COI</th> <th>Volume</th> <th>Change Vol</th>{" "}
          <th>IV</th> <th>LTP</th>
          <th>LTP Change</th> <th>Bid Quantity</th> <th>Bid Price</th>{" "}
          <th>Ask Price</th> <th>Ask Quantity</th>
          <th className="strike-prices"></th>
          {/* put columns */}
          <th className="askQuantity">Ask Quantity</th> <th>Ask Price</th>{" "}
          <th>Bid Price</th> <th>Bid Quantity</th> <th>LTP Change</th>
          <th>LTP</th> <th>IV</th> <th>Change Vol</th> <th>Volume</th>{" "}
          <th>COI</th> <th>OI</th>
        </tr>
      </thead>
      <tbody>
        {strikeArray.map((strikePrice, index) => {
          // console.log(strikeArray.length / 2 - 0.5);

          return (
            <>
              {optionChainTable.calls[strikePrice] &&
              optionChainTable.puts[strikePrice] ? (
                <tr key={index}>
                  <td>{optionChainTable.calls[strikePrice]["OI"] / number}</td>
                  <td>
                    {(optionChainTable.calls[strikePrice]["OI"] -
                      optionChainTable.calls[strikePrice][
                        "Prev_Open_Int_Close"
                      ]) /
                      number}
                  </td>
                  <td>
                    {optionChainTable.calls[strikePrice]["Volume"] / number}
                  </td>
                  <td>
                    {(optionChainTable.calls[strikePrice]["Volume"] -
                      optionChainTable.calls[strikePrice]["LTQ"]) /
                      number}
                  </td>
                  <td>TBD</td>

                  <td>{optionChainTable.calls[strikePrice]["LTP"]}</td>
                  <td>
                    {(
                      optionChainTable.calls[strikePrice]["LTP"] -
                      optionChainTable.calls[strikePrice]["Prev_Close"]
                    ).toFixed(2)}
                  </td>
                  <td>{optionChainTable.calls[strikePrice]["Bid_Qty"]}</td>
                  <td>{optionChainTable.calls[strikePrice]["Bid"]}</td>
                  <td>{optionChainTable.calls[strikePrice]["Ask"]}</td>
                  <td>{optionChainTable.calls[strikePrice]["Ask_Qty"]}</td>
                  <td className="strike-prices">
                    <b
                      style={
                        index === strikeArray.length / 2 - 0.5
                          ? {
                              color: "rgb(30,64,186)",
                              backgroundColor: "#D0D2DE",
                              padding: "4px",
                            }
                          : {}
                      }
                    >
                      {strikePrice}
                    </b>
                  </td>

                  <td>{optionChainTable.puts[strikePrice]["Ask_Qty"]}</td>
                  <td>{optionChainTable.puts[strikePrice]["Ask"]}</td>
                  <td>{optionChainTable.puts[strikePrice]["Bid"]}</td>
                  <td>{optionChainTable.puts[strikePrice]["Bid_Qty"]}</td>
                  <td>
                    {(
                      optionChainTable.puts[strikePrice]["LTP"] -
                      optionChainTable.puts[strikePrice]["Prev_Close"]
                    ).toFixed(2)}
                  </td>
                  <td>{optionChainTable.puts[strikePrice]["LTP"]}</td>
                  <td>TBD</td>

                  <td>
                    {(optionChainTable.puts[strikePrice]["Volume"] -
                      optionChainTable.puts[strikePrice]["LTQ"]) /
                      number}
                  </td>
                  <td>
                    {optionChainTable.puts[strikePrice]["Volume"] / number}
                  </td>
                  <td>
                    {(optionChainTable.puts[strikePrice]["OI"] -
                      optionChainTable.puts[strikePrice][
                        "Prev_Open_Int_Close"
                      ]) /
                      number}
                  </td>
                  <td>{optionChainTable.puts[strikePrice]["OI"] / number}</td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      className="strike-prices"
                      style={
                        index === strikeArray.length / 2 - 0.5
                          ? {
                              color: "rgb(30,64,186)",
                              backgroundColor: "#D0D2DE",
                              padding: "4px",
                            }
                          : {}
                      }
                    >
                      <b
                        style={
                          index === strikeArray.length / 2 - 0.5
                            ? {
                                color: "rgb(30,64,186)",
                                backgroundColor: "#D0D2DE",
                                padding: "4px",
                              }
                            : {}
                        }
                      >
                        {strikePrice}
                      </b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
}

export default OptionChainTable;
