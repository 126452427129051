import React, { useEffect, useState } from "react";
import style from "./IntradayOutlook.module.css";
import { db, futureDB, app } from "../../firebase";
import { onValue, ref } from "firebase/database";
import { getDatabase, off, get } from "firebase/database";

import HighestOi from "./HighestOi";
function getMonth() {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let name = month[d.getMonth()];
  return name;
}
function IntradayOutlook(props) {
  const { setHighestOiOptions, highestOiOptions, expiryDates } = props;
  const [intradayData, setIntradayData] = useState();
  const [intradayBankData, setIntradayBankData] = useState();
  const currentDate = "23" + getMonth().slice(0, 3).toUpperCase();
  const [futureExpiry, setFutureExpiry] = useState();
  const [componentLoading, setComponentLoading] = useState(true);

  useEffect(() => {
    try {
      setComponentLoading(true);
      const rtdb = getDatabase(app);
      const futureRef = ref(rtdb, `tick/Future`);

      var fetchIndicesData = async () => {
        const niftysnap = await get(futureRef);
        const niftyData = niftysnap.val();
        const expiry = Object.keys(niftyData);
        const rbt = expiry[0];
        setFutureExpiry(rbt);
      };
      fetchIndicesData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let tempData = [];

    const dbRef = ref(futureDB, `/tick/Future/${futureExpiry}/NIFTY FUT`);
    onValue(dbRef, (snapshot) => {
      tempData = snapshot.val();

      setIntradayData(tempData);
    });
  }, [futureExpiry]);
  useEffect(() => {
    let downData = [];
    const dbRef = ref(futureDB, `/tick/Future/${futureExpiry}/BANKNIFTY FUT`);
    onValue(dbRef, (snapshot) => {
      downData = snapshot.val();

      setIntradayBankData(downData);
    });
  }, [futureExpiry]);
  console.log(intradayData);
  return (
    <div className={style.container}>
      <table className={style.tableContainer}>
        <thead>
          <tr>
            {/* call columns */}

            <th>Nifty</th>
            <th className={style.strike_prices}>Index</th>
            <th>BankNifty</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* call columns */}

            <td>{intradayData?.LTP}</td>
            <td>Price</td>
            <td>{intradayBankData?.LTP}</td>
          </tr>
          <tr>
            {/* call columns */}

            <td>{intradayData?.OI}</td>
            <td>OI</td>
            <td>{intradayBankData?.OI}</td>
          </tr>
          <tr>
            {/* call columns */}

            <td>{intradayData?.OI - intradayData?.Prev_Close}</td>
            <td>OI Change</td>
            <td>{intradayBankData?.OI - intradayBankData?.Prev_Close}</td>
          </tr>
          <tr>
            {/* call columns */}

            <td>{intradayData?.Volume}</td>
            <td>Volume</td>
            <td>{intradayBankData?.Volume}</td>
          </tr>
          <tr>
            {/* call columns */}

            <td>{(intradayData?.LTP - intradayData?.Prev_Close).toFixed(2)}</td>
            <td>Premium Decay</td>
            <td>
              {(intradayBankData?.LTP - intradayBankData?.Prev_Close).toFixed(
                2
              )}
            </td>
          </tr>
          <tr>
            {/* call columns */}

            <td>tbd</td>
            <td>PCR</td>
            <td>tbd</td>
          </tr>
        </tbody>
      </table>
      <span style={{ textAlign: "center", marginTop: "1rem" }}>Highest OI</span>
      <HighestOi
        setHighestOiOptions={setHighestOiOptions}
        highestOiOptions={highestOiOptions}
        expiryDates={expiryDates}
        tableWidth="100%"
      />
    </div>
  );
}

export default IntradayOutlook;
