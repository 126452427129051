import React, { useEffect, useState } from "react";
import { db, futureDB } from "../../firebase";
import { app } from "../../firebase";
import { getDatabase, ref, onValue, off, get } from "firebase/database";
import { ConstructionOutlined } from "@mui/icons-material";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";

function generateTimestampArray() {
  const startTimestamp = new Date();
  startTimestamp.setHours(9, 15, 0, 0); // Set the start time to 9:18 AM
  const endTimestamp = new Date();
  endTimestamp.setHours(15, 30, 0, 0); // Set the end time to 3:30 PM

  const timestamps = [];
  let currentTimestamp = new Date(startTimestamp);

  while (currentTimestamp <= endTimestamp) {
    const formattedTimestamp = currentTimestamp.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    timestamps.push(formattedTimestamp);
    currentTimestamp.setMinutes(currentTimestamp.getMinutes() + 3);
  }

  return timestamps;
}

function OiChanger(props) {
  const {
    oiChangerOptions,
    setOiChangerOptions,

    expiryDates,
    theme,
  } = props;
  const [oiChangerData, setOiChangerData] = useState([]);
  const [strikeArray, setStrikeArray] = useState([]);
  const timestampsArray = generateTimestampArray();
  const [currentTime, setCurrentTime] = useState("");
  const [expiry, setExpiry] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const rtdb = getDatabase(app);
  //   const oiChangerRef = ref(rtdb, `recent data/`);
  //   const fetchOichanger = async () => {
  //     const callsSnapshot = await get(oiChangerRef);
  //     const Data = callsSnapshot.val();
  //     console.log(Data);
  //     setOiChangerData(Data);
  //   };
  //   fetchOichanger();
  // }, []);
  const [loading, setLoading] = useState(false);
  const [indexWiseExpiry, setIndexWiseExpiry] = useState({
    nifty: [],
    banknifty: [],
  });
  useEffect(() => {
    setLoading(false);
    let niftyDate = [];
    let bankNiftyDate = [];
    for (let i = 0; i < expiryDates.length; i++) {
      const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
      onValue(dbRef, (snapshot) => {
        const temp = snapshot.val();
        if (temp) {
          if (temp.BANKNIFTY) {
            bankNiftyDate.push(expiryDates[i]);
            console.log(expiryDates[i]);
          }
          if (temp.NIFTY) {
            niftyDate.push(expiryDates[i]);
            console.log(expiryDates[i]);
          }
        }
        console.log(niftyDate, bankNiftyDate);
      });
      setIndexWiseExpiry({
        ...indexWiseExpiry,
        nifty: niftyDate,
        banknifty: bankNiftyDate,
      });
    }
    setOiChangerOptions({
      ...oiChangerOptions,
      expiry: niftyDate[niftyDate.length - 1],
    });
  }, [isLoading]);
  console.log(indexWiseExpiry);
  useEffect(() => {
    const date =
      oiChangerOptions.index === "NIFTY"
        ? indexWiseExpiry.nifty[0]
        : indexWiseExpiry.banknifty[0];
    setExpiry(date);
  }, [oiChangerOptions, indexWiseExpiry]);
  //-----strike price calculation------
  useEffect(() => {
    const index =
      oiChangerOptions["index"] === "NIFTY" ? "NIFTY 50" : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          5,
          oiChangerOptions["index"] === "NIFTY" ? 50 : 100
        );

        strikePriceArray.sort();
        // setoiChangerOptions({
        //   ...oiChangerOptions,
        //   strikPrice: strikePriceArray[0],
        // });
      }
    });
    setStrikeArray(strikePriceArray);
  }, [oiChangerOptions["index"]]);

  //OI changer data fetching------------
  useEffect(() => {
    let tempData;
    const strikePriceArray = [];
    let strikRef;
    const callorput = oiChangerOptions["callorput"];
    const index = oiChangerOptions["index"];
    // {
    //   strikRef = ref(
    //     futureDB,
    //     `/recent data/${timestampsArray[1]}/Option/${expiry}/${index}/${callorput}`
    //   );
    //   onValue(strikRef, (snapshot) => {
    //     tempData = snapshot.val();
    //     for (let key in tempData) {
    //       strikePriceArray.push(key);
    //     }
    //   });
    // }
    // console.log(
    //   `recent data/${timestampsArray[1]}/Option/${expiry}/${index}/${callorput}`
    // );
    // setStrikeArray(strikePriceArray);

    //Data Fetching code
    let downData = [];
    let dbRef;

    // const index =
    //   oiChangerOptions["index"] === "nifty50" ? "NIFTY" : "BANKNIFTY";
    // const n = expiryDates.length;
    // const expiry = oiChangerOptions["expiry"];

    // dbRef = ref(futureDB, `/recent data/`);
    for (let i = 0; i < timestampsArray.length; i++) {
      dbRef = ref(
        futureDB,
        `/recent data/${timestampsArray[i]}/Option/${expiry}/${index}`
      );
      onValue(dbRef, (snapshot) => {
        downData.push(snapshot.val());

        // if (downData === null) {
        //   setOiChangerOptions({
        //     ...oiChangerData,
        //     expiry: expiryDates[n - 1],
        //   });
        // }
        if (i == timestampsArray.length - 1) console.log("haris");
      });
    }

    setOiChangerData(downData);
    // tempData
  }, [oiChangerOptions, expiry]);
  const index = oiChangerOptions["index"];
  console.log(oiChangerData);

  return (
    <div data-theme={theme}>
      <div className="option-oichanger option-optionchain subpage">
        <div className="subpage-dropdowns-container">
          {/* TABLE 1 DROPDOWNS */}
          <div
            className="subpage-dropdowns-table1"
            style={{ marginRight: "32px" }}
          >
            <div className="dropdown-container">
              <select
                name="option-oichanger-index"
                id="option-oichanger-table1-index"
                className="subpage-dropdown"
                onChange={(e) => {
                  setOiChangerOptions({
                    ...oiChangerOptions,

                    index: e.target.value,
                  });
                }}
                value={oiChangerOptions.index}
              >
                <option value="NIFTY">Nifty</option>
                <option value="BANKNIFTY">BankNifty</option>
              </select>
              <p>Select Indices</p>
            </div>
            <div className="dropdown-container">
              <select
                name="option-straddleandstraggle-index"
                id="option-straddleandstraggle-index"
                className="subpage-dropdown"
                onChange={(e) => {
                  setExpiry(e.target.value);
                }}
                value={expiry}
              >
                {oiChangerOptions.index === "NIFTY" ? (
                  <>
                    {indexWiseExpiry.nifty.map((expiryDate, index) => {
                      return (
                        <option key={index} value={expiryDate}>
                          {expiryDate}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                      return (
                        <option key={index} value={expiryDate}>
                          {expiryDate}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
              <p>Expiry</p>
            </div>
            <div className="dropdown-container">
              <select
                name="option-oichanger-table2-time"
                id="option-oichanger-table2-time"
                className="subpage-dropdown"
                onChange={(e) => {
                  setOiChangerOptions({
                    ...oiChangerOptions,

                    value: e.target.value,
                  });
                }}
                value={oiChangerOptions.value}
              >
                <option value={50000}>50k</option>
                <option value={100000}>100k</option>
                <option value={150000}>150k</option>
                <option value={200000}>200k</option>
              </select>
              <p>Select COI value</p>
            </div>

            <div className="dropdown-container">
              <select
                name="option-oichanger-table1-callorput"
                id="option-oichanger-table1-callorput"
                className="subpage-dropdown"
                onChange={(e) => {
                  setOiChangerOptions({
                    ...oiChangerOptions,

                    callorput: e.target.value,
                  });
                }}
                value={oiChangerOptions.callorput}
              >
                <option value="Calls">Call</option>
                <option value="Puts">Put</option>
              </select>
              <p>Call or Put</p>
            </div>
          </div>

          {/* TABLE 2 DROPDOWNS */}
        </div>
        <div
          className="oichanger-tables"
          style={{ display: "flex", gap: "32px" }}
        >
          {/* <table className="info-table" data-theme={props.theme}>
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th colSpan={12}>Strike Prices</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Time</b>
              </td>
              <td>
                <b>Live Indice</b>
              </td>
            </tr>
          </tbody>
        </table> */}
          <table className="info-table" data-theme={props.theme}>
            <thead>
              <tr>
                <th colSpan={2}></th>
                <th colSpan={strikeArray.length}>Strike Prices</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Time</b>
                </td>
                <td>
                  <b>Live Indice</b>
                </td>
                {strikeArray.map((strike, i) => {
                  const coi = (
                    oiChangerData?.[oiChangerOptions.callorput]?.[strike]?.OI -
                    oiChangerData?.[oiChangerOptions.callorput]?.[strike]
                      ?.Prev_Close
                  ).toFixed(0);
                  if (coi < oiChangerOptions.value) return;
                  return <td>{strike}</td>;
                })}
              </tr>
              {timestampsArray.map((item, index) => {
                return (
                  <tr>
                    <td>{item}</td>
                    <td></td>

                    {strikeArray.map((strike, i) => {
                      const coi = (
                        oiChangerData[index]?.[oiChangerOptions.callorput]?.[
                          strike
                        ]?.OI -
                        oiChangerData[index]?.[oiChangerOptions.callorput]?.[
                          strike
                        ]?.Prev_Close
                      ).toFixed(0);
                      if (coi < oiChangerOptions.value) return;
                      return (
                        <td>
                          {coi > oiChangerOptions.value ? <>{coi}</> : <>COI</>}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OiChanger;
