import React, { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { getDatabase, off, get } from "firebase/database";
import { app } from "../../firebase";
import { futureDB } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import PremiumDecay from "./PremiumDecay";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";
import OiDirection from "./OiDirection";
import IntradayOptionChain from "./IntradayOptionChain";

function getMonth() {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let name = month[d.getMonth()];
  return name;
}

function IntradaySignal(props) {
  const { intradaySignalsOptions, setIntradaySignalsOptions } = props;
  const [optionTab, setOptionTab] = useState("optionchain");
  const [strikeArray, setStrikeArray] = useState([]);
  const [expiryDates, setExpiryDates] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [indexValue, setIndexValue] = useState();
  const [ivValue, setIvValue] = useState();
  const [futureOI, setFutureOi] = useState([]);
  const currentFutureDate = "23" + getMonth().slice(0, 3).toUpperCase();

  const [expiry, setExpiry] = useState();
  const [indexWiseExpiry, setIndexWiseExpiry] = useState({
    nifty: [],
    banknifty: [],
  });

  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();
      const currentDateStartOfDay = new Date(currentDate);
      currentDateStartOfDay.setHours(0, 0, 0, 0);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const fullYear = yy >= 50 ? 1900 + yy : 2000 + yy;
        const expiryDate = new Date(fullYear, mm - 1, dd); // Adjust year to 4-digit format

        // Include dates on or after the current date (ignoring time)
        return expiryDate >= currentDateStartOfDay;
      });

      setExpiryDates(expiryArray);
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);
  // expiry date indexwise---------
  useEffect(() => {
    console.log("useEffect runn");
    let niftyDate = [];
    let bankNiftyDate = [];
    for (let i = 0; i < expiryDates.length; i++) {
      const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
      onValue(dbRef, (snapshot) => {
        const temp = snapshot.val();
        if (temp) {
          if (temp.BANKNIFTY) {
            bankNiftyDate.push(expiryDates[i]);
          }
          if (temp.NIFTY) {
            niftyDate.push(expiryDates[i]);
          }
        }
      });
      setIndexWiseExpiry({
        ...indexWiseExpiry,
        nifty: niftyDate,
        banknifty: bankNiftyDate,
      });
      // setExpiry(niftyDate[0]);
    }

    niftyDate = [];
    bankNiftyDate = [];

    // const id = setTimeout(() => {
    //   setLoading(true);
    // }, 1000);

    // return () => {
    //   clearTimeout(id);
    // };
  }, [expiryDates]);

  useEffect(() => {
    const date =
      intradaySignalsOptions.index === "NIFTY"
        ? indexWiseExpiry.nifty[0]
        : indexWiseExpiry.banknifty[0];
    setExpiry(date);
  }, [intradaySignalsOptions["index"], indexWiseExpiry]);

  useEffect(() => {
    const index =
      intradaySignalsOptions["index"] === "NIFTY" ? "NIFTY 50" : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        console.log(niftyLTPData);
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          intradaySignalsOptions.numStrikePrice || 5,
          intradaySignalsOptions["index"] === "NIFTY" ? 50 : 100
        );

        strikePriceArray.sort();
      }
    });
    setStrikeArray(strikePriceArray);
  }, [intradaySignalsOptions["index"]]);

  useEffect(() => {
    const index = intradaySignalsOptions["index"];
    console.log(`tick/Option/${expiry}/${index}`);
    const niftyLTPRef = ref(futureDB, `tick/Option/${expiry}/${index}`);

    let strikePriceArray = [];

    onValue(niftyLTPRef, (snapshot) => {
      strikePriceArray = snapshot.val();
    });
    setData(strikePriceArray);
  }, [intradaySignalsOptions, expiry]);

  useEffect(() => {
    setLoading(true);

    const index = intradaySignalsOptions["index"];
    console.log(`greeks/Option/${expiry}/${index}`);
    const niftyLTPRef = ref(futureDB, `greeks/Option/${expiry}/${index}`);

    let strikePriceArray = [];

    onValue(niftyLTPRef, (snapshot) => {
      strikePriceArray = snapshot.val();
      setIvValue(strikePriceArray);
    });
  }, [intradaySignalsOptions, expiry]);
  useEffect(() => {
    const index =
      intradaySignalsOptions["index"] === "NIFTY"
        ? "NIFTY FUT"
        : "BANKNIFTY FUT";
    let indexName = [];
    const niftyLTPRef = ref(
      futureDB,
      `tick/Future/${currentFutureDate}/${index}`
    );
    onValue(niftyLTPRef, (snapshot) => {
      indexName = snapshot.val();
    });
    setFutureOi(indexName);
  }, [intradaySignalsOptions["index"]]);

  return (
    <>
      <div className="option-intradaysignals">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-intradaysignals-index"
              id="option-intradaysignals-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setIntradaySignalsOptions({
                  ...intradaySignalsOptions,
                  index: e.target.value,
                });
              }}
              value={intradaySignalsOptions.index}
            >
              <option value="NIFTY">Nifty 50</option>
              <option value="BANKNIFTY">Nifty Bank</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-intradaysignals-lotorvalue"
              id="option-intradaysignals-lotorvalue"
              className="subpage-dropdown"
              onChange={(e) => {
                setIntradaySignalsOptions({
                  ...intradaySignalsOptions,
                  lotOrValue: e.target.value,
                });
              }}
            >
              <option value="lot">Lot Wise</option>
              <option value="value">Value Wise</option>
            </select>
            <p>Lot Wise/Value Wise</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-intradaysignals-expiry"
              id="option-intradaysignals-expiry"
              className="subpage-dropdown"
              onChange={(e) => setExpiry(e.target.value)}
              value={expiry}
            >
              {intradaySignalsOptions.index === "NIFTY" ? (
                <>
                  {indexWiseExpiry.nifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <p>Expiry</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-intradaysignals-numstrikeprice"
              id="option-intradaysignals-numstrikeprice"
              className="subpage-dropdown"
              onChange={(e) => {
                setIntradaySignalsOptions({
                  ...intradaySignalsOptions,
                  numStrikePrice: e.target.value,
                });
              }}
              value={intradaySignalsOptions.numStrikePrice}
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={7}>7</option>
            </select>
            <p>Strike Prices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-intradaysignals-time"
              id="option-intradaysignals-time"
              className="subpage-dropdown"
              onChange={(e) => {
                setIntradaySignalsOptions({
                  ...intradaySignalsOptions,
                  time: e.target.value,
                });
              }}
            >
              <option value={0}>Live</option>
              <option value={0.5}>30 Seconds</option>
              <option value={1}>1 Minute</option>
              <option value={2}>2 Minutes</option>
              <option value={3}>3 Minutes</option>
              <option value={4}>4 Minutes</option>
              <option value={5}>5 Minutes</option>
            </select>
            <p>Time</p>
          </div>
        </div>
      </div>
      <div>
        <Nav
          className={` flex mt-2 `}
          id="dashboard-nav"
          // className="navIntra"
          value={optionTab}
          onChange={(e, newValue) => {
            setOptionTab(newValue);
          }}
          data-theme={props.theme}
          variant={window.screen.width > 1280 ? "fullWidth" : "scrollable"}
          scrollButtons="auto"

          // sx={{
          //   "& .MuiTabScrollButton-root": {
          //     color: props.theme === "dark" ? "white" : "black",
          //   },
          // }}
          // TabIndicatorProps={{
          //   style: {
          //     backgroundColor: props.theme === "dark" ? "white" : "blue",
          //   },
          // }}
        >
          <Nav.Link
            as={Link}
            onClick={() => {
              setOptionTab("optionchain");
            }}
            className={optionTab === "optionchain" ? "active" : "inactive"}
          >
            Option Chain
          </Nav.Link>
          <Nav.Link
            as={Link}
            onClick={() => {
              setOptionTab("premiumdecay");
            }}
            className={optionTab === "premiumdecay" ? "active" : "inactive"}
          >
            Premium Decay
          </Nav.Link>
          <Nav.Link
            as={Link}
            onClick={() => {
              setOptionTab("oidirection");
            }}
            className={optionTab === "oidirection" ? "active" : "inactive"}
          >
            OI Direction
          </Nav.Link>
          <Nav.Link
            as={Link}
            onClick={() => {
              setOptionTab("callputscore");
            }}
            className={optionTab === "callputscore" ? "active" : "inactive"}
          >
            Call Put Score
          </Nav.Link>
        </Nav>
      </div>
      <div>
        {optionTab === "optionchain" && (
          <>
            <IntradayOptionChain
              strikeArray={strikeArray}
              data={data}
              ivValue={ivValue}
              futureOI={futureOI}
              intradaySignalsOptions={intradaySignalsOptions}
            />
          </>
        )}
        {optionTab === "premiumdecay" && (
          <>
            <PremiumDecay strikeArray={strikeArray} data={data} />
          </>
        )}
        {optionTab === "oidirection" && (
          <>
            <OiDirection
              strikeArray={strikeArray}
              data={data}
              intradaySignalsOptions={intradaySignalsOptions}
            />
          </>
        )}
      </div>
    </>
  );
}

export default IntradaySignal;
