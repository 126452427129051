import React from "react";
import style from "./HoldingAndPosition.module.css";
function PortfolioPosition(props) {
  const { positionTitle, positionData } = props;
  return (
    <div className={style.container}>
      <div className={style.margin_left}>
        {" "}
        <h4>Position</h4>
      </div>
      {positionTitle.map((title) => {
        const avgPrice = positionData[title]?.avgPrice.toFixed(2);
        return (
          <div className={style.holding_container}>
            <div>
              <div className={style.holding_title}>{title}</div>
              <div className={style.holding_price}>
                {positionData[title].currentPrice}
              </div>
            </div>
            <div className={style.item_container}>
              <div className={style.holding_profit}>
                {avgPrice * positionData[title].currentPrice -
                  avgPrice * positionData[title].quantity}
              </div>
              <div className={style.second_item}>
                <div className={style.avgPrice}>
                  Avg. Price ₹{positionData[title].avgPrice.toFixed(2)}
                </div>
                <div className={style.holding}>
                  Quantity {positionData[title].quantity}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PortfolioPosition;
