import React, { useEffect, useState } from "react";
import style from "./StockDetails.module.css";
import { db, futureDB } from "../../firebase";
import { onValue, ref } from "firebase/database";
import { cloneDeep } from "lodash";
function GainerAndLoserStocks(props) {
  const { strikePrice, stockData } = props;
  const [loserStrikeArray, setLoserStrikeArray] = useState([]);
  const [gainerStrikeArray, setGainerStrikeArray] = useState([]);
  const [topGainers, setTopGainers] = useState([]);
  const [topLosers, setTopLosers] = useState([]);

  const calculatePreviousDate = (inputDays) => {
    try {
      // Convert the input to an integer
      const days = parseInt(inputDays, 10);

      // Get the current date
      let currentDate = new Date();

      // Calculate the previous date by subtracting the given number of days,
      // excluding weekends (Saturday and Sunday)
      for (let i = 0; i < days; i++) {
        currentDate.setDate(currentDate.getDate() - 1);

        // Skip Sundays (day 0) and Saturdays (day 6)
        while (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
          currentDate.setDate(currentDate.getDate() - 1);
        }
      }

      // Format the date in "dd-mm-yyyy" format
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;

      return formattedDate;
    } catch (error) {
      console.log("Invalid input. Please enter a valid number of days.");
    }
  };
  useEffect(() => {
    let tempData = [];

    const dbRef = ref(futureDB, `/Top Gainers And Losers/Top Gainers`);
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((snapshot) => {
        tempData.push(snapshot.val());
        // downData.push(snapshot.val());
      });
      console.log(snapshot.val());
      tempData.pop();
      setTopGainers(tempData);
    });
  }, []);

  useEffect(() => {
    let tempData = [];

    const dbRef = ref(futureDB, `/Top Gainers And Losers/Top Losers`);
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((snapshot) => {
        tempData.push(snapshot.val());
        // downData.push(snapshot.val());
      });
      console.log(snapshot.val());
      tempData.pop();
      setTopLosers(tempData);
    });
  }, []);

  console.log(topLosers);
  useEffect(() => {
    const gainerStrike = cloneDeep(strikePrice);
    setGainerStrikeArray(gainerStrike);
    const loserStrike = cloneDeep(strikePrice);
    setLoserStrikeArray(loserStrike);
  }, []);

  return (
    <div
      className={style.stockDetailsContainer}
      style={{ textAlign: "center" }}
    >
      <table className={style.tableContainer}>
        <thead>
          <tr>
            <td style={{ backgroundColor: "inherit", border: "0" }} colSpan={3}>
              Top Gainers
            </td>
          </tr>
        </thead>
        <tbody>
          {gainerStrikeArray
            .sort((a, b) => {
              return (
                stockData[b].LTP -
                2 * stockData[b].Prev_Close -
                (stockData[a].LTP - 2 * stockData[a].Prev_Close)
              );
            })
            .map((strike, i) => {
              if (i >= 5) return;
              return (
                <tr>
                  <td>{strike}</td>
                  <td>{stockData[strike].LTP}</td>
                  <td>{stockData[strike].Volume}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <table className={style.tableContainer}>
        <thead>
          <tr>
            <td style={{ backgroundColor: "inherit", border: "0" }} colSpan={3}>
              Top Losers
            </td>
          </tr>
        </thead>
        <tbody>
          {loserStrikeArray
            .sort((a, b) => {
              return (
                stockData[a].LTP -
                stockData[a].Prev_Close -
                stockData[a].Prev_Close -
                (stockData[b].LTP -
                  stockData[b].Prev_Close -
                  stockData[b].Prev_Close)
              );
            })
            .map((strike, i) => {
              if (i >= 5) return;
              return (
                <tr>
                  <td>{strike}</td>
                  <td>{stockData[strike].LTP}</td>
                  <td>{stockData[strike].Volume}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <br />
      Last 3 Days Gainers And Losers
      {topGainers.map((gainer, i) => {
        if (i < topGainers.length - 3) return;
        const date = calculatePreviousDate(topGainers.length - i);
        return (
          <table className={style.tableContainer}>
            <thead>
              <tr>
                <td
                  style={{ backgroundColor: "inherit", border: "0" }}
                  colSpan={3}
                >
                  Top Gainers ({date})
                </td>
              </tr>
            </thead>
            <tbody>
              {gainer.map((item, i) => {
                return (
                  <tr>
                    <td>{item.symbol}</td>
                    <td>{item.ltp}</td>
                    <td>{item.volume}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })}
      <br />
      {topLosers.map((gainer, i) => {
        if (i < topGainers.length - 3) return;
        const date = calculatePreviousDate(topGainers.length - i);
        return (
          <table className={style.tableContainer}>
            <thead>
              <tr>
                <td
                  style={{ backgroundColor: "inherit", border: "0" }}
                  colSpan={3}
                >
                  Top Losers ({date})
                </td>
              </tr>
            </thead>
            <tbody>
              {gainer.map((item, i) => {
                return (
                  <tr>
                    <td>{item.symbol}</td>
                    <td>{item.ltp}</td>
                    <td>{item.volume}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })}
    </div>
  );
}

export default GainerAndLoserStocks;
