import React from "react";
import "./Option.css";
import "../../App.css";
import OptionChainBelowTable from "./OptionChainBelowTable";
import OptionChainTable from "./OptionChainTable";
function OptionChain(props) {
  const {
    optionChainOptions,
    setOptionChainOptions,
    optionChainData,
    expiryDates,
    theme,
    indexWiseExpiry,
    setExpiry,
    strikeArray,
    setTime,
  } = props;
  console.log(strikeArray);
  return (
    <div data-theme={theme}>
      <div className="option-optionchain subpage">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-optionchain-index"
              id="option-optionchain-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionChainOptions({
                  ...optionChainOptions,
                  index: e.target.value,
                });
              }}
              value={optionChainOptions.index}
            >
              <option value="nifty50">Nifty 50</option>
              <option value="niftybank">Nifty Bank</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-lotorvalue"
              id="option-optionchain-lotorvalue"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionChainOptions({
                  ...optionChainOptions,
                  lotOrValue: e.target.value,
                });
              }}
              value={optionChainOptions.lotOrValue}
            >
              <option value="lot">Lot Wise</option>
              <option value="value">Value Wise</option>
            </select>
            <p>Lot Wise/Value Wise</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-expiry"
              id="option-optionchain-expiry"
              className="subpage-dropdown"
              onChange={(e) => setExpiry(e.target.value)}
            >
              {optionChainOptions.index === "nifty50" ? (
                <>
                  {indexWiseExpiry.nifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <p>Expiry</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-numstrikeprice"
              id="option-optionchain-numstrikeprice"
              className="subpage-dropdown"
              onChange={(e) => {
                setOptionChainOptions({
                  ...optionChainOptions,
                  numStrikePrice: e.target.value,
                });
              }}
              value={optionChainOptions.numStrikePrice}
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={7}>7</option>
            </select>
            <p>Strike Prices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-time"
              id="option-optionchain-time"
              className="subpage-dropdown"
              onChange={(e) => setTime(e.target.value)}
            >
              <option value={0}>Live</option>
              <option value={0.5}>30 Seconds</option>
              <option value={1}>1 Minute</option>
              <option value={2}>2 Minutes</option>
              <option value={3}>3 Minutes</option>
              <option value={4}>4 Minutes</option>
              <option value={5}>5 Minutes</option>
            </select>
            <p>Time</p>
          </div>
        </div>
      </div>
      <OptionChainTable
        optionChainData={optionChainData}
        optionChainOptions={optionChainOptions}
        strikeArray={strikeArray}
      />

      <OptionChainBelowTable
        optionChainData={optionChainData}
        optionChainOptions={optionChainOptions}
        strikeArray={strikeArray}
      />
    </div>
  );
}

export default OptionChain;
