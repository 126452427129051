import React, { useEffect, useState } from "react";
import style from "./OrderFlowAnalysis.module.css";
import { getDatabase, ref, onValue, off, get } from "firebase/database";
import { futureDB, app } from "../../firebase";
function getMonth() {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let name = month[d.getMonth()];
  return name;
}
function generateTimestampArray() {
  const startTimestamp = new Date();
  startTimestamp.setHours(9, 15, 0, 0); // Set the start time to 9:18 AM
  const endTimestamp = new Date();
  endTimestamp.setHours(15, 30, 0, 0); // Set the end time to 3:30 PM

  const timestamps = [];
  let currentTimestamp = new Date(startTimestamp);

  while (currentTimestamp <= endTimestamp) {
    const formattedTimestamp = currentTimestamp.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    timestamps.push(formattedTimestamp);
    currentTimestamp.setMinutes(currentTimestamp.getMinutes() + 1);
  }

  return timestamps;
}

export default function OrderFlowAnalysis() {
  const [niftyData, setNiftyData] = useState([]);
  const [bankNiftyData, setBankNiftyData] = useState([]);
  const [oiNiftyData, setOiNiftyData] = useState([]);
  const [oiBankNiftyData, setOiBankNiftyData] = useState([]);
  const [orderFlowAnalysisOptions, setOrderFlowAnalysisOptions] = useState({
    index: "NIFTY 50",

    time: 0,
    lotOrValue: "lot",
  });
  const timestampsArray = generateTimestampArray();
  const currentDate = "23" + getMonth().slice(0, 3).toUpperCase();
  const [futureExpiry, setFutureExpiry] = useState();
  const [componentLoading, setComponentLoading] = useState(true);
  useEffect(() => {
    try {
      setComponentLoading(true);
      const rtdb = getDatabase(app);
      const futureRef = ref(rtdb, `tick/Future`);

      var fetchIndicesData = async () => {
        const niftysnap = await get(futureRef);
        const niftyData = niftysnap.val();
        const expiry = Object.keys(niftyData);
        const rbt = expiry[0];
        setFutureExpiry(rbt);
      };
      fetchIndicesData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let tempData = [];
    let downData = [];
    let futureNiftyData = [];
    let futureBankNiftyData = [];
    let optionNifty = [];
    const index = orderFlowAnalysisOptions.index;
    for (let i = 0; i < timestampsArray.length; i++) {
      // const optionNiftyRef = ref(
      //   futureDB,
      //   `/recent data/${timestampsArray[i]}/Option/NIFTY 50`
      // );

      const dbRef = ref(
        futureDB,
        `/recent data/${timestampsArray[i]}/Index/${index}`
      );
      const bankRef = ref(
        futureDB,
        `/recent data/${timestampsArray[i]}/Index/NIFTY BANK`
      );
      const futureNiftyRef = ref(
        futureDB,
        `/recent data/${timestampsArray[i]}/Future/${futureExpiry}/NIFTY FUT`
      );
      const futureBankNiftyRef = ref(
        futureDB,
        `/recent data/${timestampsArray[i]}/Future/${futureExpiry}/BANKNIFTY FUT`
      );
      onValue(dbRef, (snapshot) => {
        downData.push(snapshot.val());

        if (i == timestampsArray.length - 1) console.log("haris");
      });
      onValue(bankRef, (snapshot) => {
        tempData.push(snapshot.val());
      });
      onValue(futureNiftyRef, (snapshot) => {
        futureNiftyData.push(snapshot.val());
      });
      onValue(futureBankNiftyRef, (snapshot) => {
        futureBankNiftyData.push(snapshot.val());
      });
    }
    setNiftyData(downData);
    setBankNiftyData(tempData);
    setOiNiftyData(futureNiftyData);
    setOiBankNiftyData(futureBankNiftyData);
  }, [orderFlowAnalysisOptions, futureExpiry]);
  console.log(niftyData);
  console.log(oiNiftyData);
  let number = 1;
  if (orderFlowAnalysisOptions.index === "NIFTY 50") {
    number = orderFlowAnalysisOptions.lotOrValue === "value" ? 50 : 1;
  }
  if (orderFlowAnalysisOptions.index === "NIFTY BANK") {
    number = orderFlowAnalysisOptions.lotOrValue === "value" ? 15 : 1;
  }

  if (niftyData.length === 0) return <>loading...</>;
  return (
    <>
      <div className="option-orderflowanalysis">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-orderflowanalysis-index"
              id="option-orderflowanalysis-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setOrderFlowAnalysisOptions({
                  ...orderFlowAnalysisOptions,
                  index: e.target.value,
                });
              }}
              value={orderFlowAnalysisOptions.index}
            >
              <option value="NIFTY 50">Nifty 50</option>
              <option value="NIFTY BANK">Nifty Bank</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-orderflowanalysis-time"
              id="option-orderflowanalysis-time"
              className="subpage-dropdown"
              onChange={(e) => {
                setOrderFlowAnalysisOptions({
                  ...orderFlowAnalysisOptions,
                  time: e.target.value,
                });
              }}
            >
              <option value={0}>Live</option>
              <option value={0.5}>30 Seconds</option>
              <option value={1}>1 Minute</option>
              <option value={2}>2 Minutes</option>
              <option value={3}>3 Minutes</option>
              <option value={4}>4 Minutes</option>
              <option value={5}>5 Minutes</option>
            </select>
            <p>Time</p>
          </div>

          <div className="dropdown-container">
            <select
              name="option-orderflowanalysis-lotorvalue"
              id="option-orderflowanalysis-lotorvalue"
              className="subpage-dropdown"
              onChange={(e) => {
                setOrderFlowAnalysisOptions({
                  ...orderFlowAnalysisOptions,
                  lotOrValue: e.target.value,
                });
              }}
            >
              <option value="lot">Lot Wise</option>
              <option value="value">Value Wise</option>
            </select>
            <p>Lot Wise/Value Wise</p>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <table className={style.tableContainer}>
          <thead>
            <tr>
              {/* call columns */}
              <th className={style.strike_prices}>Time</th>
              <th>Index</th> <th>Changed over the period</th>{" "}
              <th>Nifty Future</th> <th>Nifty Future Change</th>{" "}
              <th>Nifty Future OI</th> <th>Nifty Future OI Change</th>
              <th>Total Buying Order</th>
              <th>Total Selling Order</th>
              <th>Difference</th>
              <th>Pressure of Buyer/Seller</th>
              <th>Total Buying Quantity</th>
              <th>Total Selling Quantity</th>
              <th>Difference</th>
              <th>Pressure of Buyer and Seller</th>
            </tr>
          </thead>
          <tbody>
            {timestampsArray.map((time, i) => {
              if (!oiNiftyData[i]) return;
              return (
                <tr>
                  {/* call columns */}
                  <td className={style.strike_prices}>{time}</td>
                  <td>{niftyData[i]?.LTP}</td> <td>Cdanged </td>{" "}
                  <td>{oiNiftyData[i]?.LTP?.toFixed(0)}</td>{" "}
                  <td>
                    {" "}
                    {(oiNiftyData[i]?.LTP - oiNiftyData[i]?.Prev_Close).toFixed(
                      2
                    )}
                  </td>{" "}
                  <td>{oiNiftyData[i]?.OI?.toFixed(0) / number}</td>{" "}
                  <td>
                    {(oiBankNiftyData[i]?.OI - oiBankNiftyData[i]?.Prev_Close) /
                      number}
                  </td>
                  <td>TBD</td>
                  <td>Order</td>
                  <td>Difference</td>
                  <td>TBD</td>
                  <td>TBD</td>
                  <td>TBD</td>
                  <td>Difference</td>
                  <td>TBD</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
