import React, { useState, useEffect } from "react";
import "./Option.css";
import "./Stock.css";
import { db, futureDB } from "../../firebase";
import { onValue, ref } from "firebase/database";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StockDetails from "./StockDetails";
import GainerAndLoserStocks from "./GainerAndLoserStocks";
import AdvanceDeclineRatio from "./AdvanceDeclineRatio";
import Portfolio from "./Portfolio";

export default function Stock(props) {
  const [stockTab, setStockTab] = useState("stockdetails");
  const [stockData, setStockData] = useState([]);
  const [strikePrice, setStrikePrice] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let tempData = {};
    let downData = [];
    let strikePriceArray = [];
    const dbRef = ref(futureDB, `/tick/Stocks`);
    onValue(dbRef, (snapshot) => {
      // snapshot.forEach((snapshot) => {
      //   tempData.push(snapshot.val());
      //   downData.push(snapshot.val());
      // });
      tempData = snapshot.val();

      setStockData(tempData);

      setLoading(true);
    });
    for (let key in stockData) {
      strikePriceArray.push(key);
    }
    setStrikePrice(strikePriceArray);
  }, [loading]);

  const searchHandler = (e) => {
    setSearchString(e.target.value);
  };
  return (
    <>
      <div>
        <Tabs
          value={stockTab}
          onChange={(e, newValue) => {
            setStockTab(newValue);
          }}
          aria-label=""
          data-theme={props.theme}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabScrollButton-root": {
              color: props.theme === "dark" ? "white" : "black",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: props.theme === "dark" ? "white" : "blue",
            },
          }}
        >
          <Tab
            label="Stock Details"
            className="muiTab"
            id="simple-tab-1"
            value="stockdetails"
          />
          <Tab
            label="Top Gainers and Losers"
            className="muiTab"
            id="simple-tab-2"
            value="topgainersandlosers"
          />
          <Tab
            label="Portfolio/Position"
            className="muiTab"
            id="simple-tab-3"
            value="portfolioposition"
          />
          <Tab
            label="Advance Decline Ratio"
            className="muiTab"
            id="simple-tab-4"
            value="advancedeclineratio"
          />
        </Tabs>
      </div>
      <div>
        {stockTab === "stockdetails" && (
          <StockDetails
            stockData={stockData}
            strikePrice={strikePrice}
            searchString={searchString}
            searchHandler={searchHandler}
          />
        )}
        {stockTab === "topgainersandlosers" && (
          <GainerAndLoserStocks
            stockData={stockData}
            strikePrice={strikePrice}
            searchString={searchString}
          />
        )}
        {/* {stockTab === "portfolioposition" && (
          <Portfolio
            stockData={stockData}
            strikePrice={strikePrice}
            searchString={searchString}
            searchHandler={searchHandler}
          />
        )} */}
        {stockTab === "advancedeclineratio" && (
          <AdvanceDeclineRatio
            stockData={stockData}
            strikePrice={strikePrice}
          />
        )}
      </div>
    </>
  );
}
