import React, { useEffect, useState } from "react";
import style from "./MostActiveOption.module.css";
import { db, futureDB, app } from "../../firebase";
import { onValue, ref, off, getDatabase } from "firebase/database";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";

function BuyingSellinPressure(props) {
  const {
    optionChainData,
    optionChainOptions,
    buyingSellingPressureOptions,
    setBuyingSellingPressureOptions,
  } = props;
  const [buyingSellingData, setBuyingSellingData] = useState();
  const [strikeArray, setStrikeArray] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [expiry, setExpiry] = useState();
  const [expiryDates, setExpiryDates] = useState([]);
  const [indexWiseExpiry, setIndexWiseExpiry] = useState({
    nifty: [],
    banknifty: [],
  });

  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();
      const currentDateStartOfDay = new Date(currentDate);
      currentDateStartOfDay.setHours(0, 0, 0, 0);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const fullYear = yy >= 50 ? 1900 + yy : 2000 + yy;
        const expiryDate = new Date(fullYear, mm - 1, dd); // Adjust year to 4-digit format

        // Include dates on or after the current date (ignoring time)
        return expiryDate >= currentDateStartOfDay;
      });

      setExpiryDates(expiryArray);
      // setPortfolioOptions({
      //   ...portfolioOptions,
      //   date: expiryArray[0],
      // });
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);

  useEffect(() => {
    console.log("useEffect runn");
    let niftyDate = [];
    let bankNiftyDate = [];
    for (let i = 0; i < expiryDates.length; i++) {
      const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
      onValue(dbRef, (snapshot) => {
        const temp = snapshot.val();
        if (temp) {
          if (temp.BANKNIFTY) {
            bankNiftyDate.push(expiryDates[i]);
          }
          if (temp.NIFTY) {
            niftyDate.push(expiryDates[i]);
          }
        }
      });
      setIndexWiseExpiry({
        ...indexWiseExpiry,
        nifty: niftyDate,
        banknifty: bankNiftyDate,
      });
      // setExpiry(niftyDate[0]);
    }

    niftyDate = [];
    bankNiftyDate = [];

    // const id = setTimeout(() => {
    //   setLoading(true);
    // }, 1000);

    // return () => {
    //   clearTimeout(id);
    // };
  }, [expiryDates]);

  useEffect(() => {
    const date =
      buyingSellingPressureOptions.index === "NIFTY"
        ? indexWiseExpiry.nifty[0]
        : indexWiseExpiry.banknifty[0];
    setExpiry(date);
  }, [buyingSellingPressureOptions["index"], indexWiseExpiry]);
  // console.log(expiry, indexWiseExpiry);

  useEffect(() => {
    // setLoading(false);
    console.log("runned option portfolio");
    function fetchData() {
      let currentcount = expiry;
      let currentindex = buyingSellingPressureOptions["index"];
      console.log(`tick/Option/${currentcount}/${currentindex}`);
      let index = "";
      let temp = [];
      const dbRef = ref(
        futureDB,
        `tick/Option/${currentcount}/${currentindex}`
      );
      onValue(dbRef, (snapshot) => {
        // let option = portfolioOptions["optionChain"];

        temp = snapshot.val();
        setBuyingSellingData(temp);

        console.log(temp);
        // setTitleList(strikeNumber);
      });
    }
    fetchData();
    // const timeToken = setTimeout(fetchData, 1000);

    // return () => {
    //   clearTimeout(timeToken);
    // };
  }, [buyingSellingPressureOptions, indexWiseExpiry, expiry]);

  useEffect(() => {
    console.log(buyingSellingPressureOptions["numStrikePrice"]);
    const index =
      buyingSellingPressureOptions["index"] === "NIFTY"
        ? "NIFTY 50"
        : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          buyingSellingPressureOptions["numStrikePrice"] || 5,
          buyingSellingPressureOptions["index"] === "NIFTY" ? 50 : 100
        );

        strikePriceArray.sort();
        // setportfolioOptions({
        //   ...portfolioOptions,
        //   strikPrice: strikePriceArray[0],
        // });
      }
    });
    setStrikeArray(strikePriceArray);
  }, [buyingSellingPressureOptions]);
  console.log(optionChainData, buyingSellingData);
  if (!buyingSellingData) return <>loading...</>;
  return (
    <>
      <div className="option-buyingsellingpressure">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-buyingsellingpressure-index"
              id="option-buyingsellingpressure-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setBuyingSellingPressureOptions({
                  ...buyingSellingPressureOptions,
                  index: e.target.value,
                });
              }}
              value={buyingSellingPressureOptions.index}
            >
              <option value="NIFTY">Nifty 50</option>
              <option value="BANKNIFTY">Nifty Bank</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-expiry"
              id="option-optionchain-expiry"
              className="subpage-dropdown"
              onChange={(e) => {
                setExpiry(e.target.value);
              }}
              value={expiry}
            >
              {buyingSellingPressureOptions.index === "NIFTY" ? (
                <>
                  {indexWiseExpiry.nifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <p>Expiry</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-optionchain-numstrikeprice"
              id="option-optionchain-numstrikeprice"
              className="subpage-dropdown"
              onChange={(e) => {
                setBuyingSellingPressureOptions({
                  ...buyingSellingPressureOptions,
                  numStrikePrice: e.target.value,
                });
              }}
              value={buyingSellingPressureOptions.numStrikePrice}
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={7}>7</option>
            </select>
            <p>Strike Prices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-buyingsellingpressure-time"
              id="option-buyingsellingpressure-time"
              className="subpage-dropdown"
              onChange={(e) => {
                setBuyingSellingPressureOptions({
                  ...buyingSellingPressureOptions,
                  option: e.target.value,
                });
              }}
            >
              <option value="call">Call</option>
              <option value="put">Put</option>
            </select>
            <p>select</p>
          </div>
        </div>
        {/* SUBPAGE DROPDOWN CONTAINERS CLOSE FOR BUYING SELLING PRESSURE */}
      </div>

      <table className={style.tableContainer}>
        <thead>
          <tr>
            {/* call columns */}
            <th className={style.strike_prices}>Strike Price</th>
            <th>Buy Pressure</th> <th>Sell Pressure</th> <th>LTP</th>{" "}
            <th>Change</th>
            <th>Probable Outcome</th>
          </tr>
        </thead>
        <tbody>
          {buyingSellingPressureOptions.option === "put" ? (
            <>
              {strikeArray.map((strike, index) => {
                let bgcolour = "";
                let outcome = "Neutral";
                const buyPressure = Math.round(
                  (buyingSellingData?.Puts?.[strike]?.["Ask_Qty"] /
                    (buyingSellingData?.Puts?.[strike]?.["Ask_Qty"] +
                      buyingSellingData?.Puts?.[strike]?.["Bid_Qty"])) *
                    100
                );
                const sellPressure = 100 - buyPressure;
                const changeInLtp = (
                  buyingSellingData?.Puts?.[strike]?.["LTP"] -
                  buyingSellingData.Puts?.[strike]?.["Prev_Close"]
                ).toFixed(2);
                if (buyPressure > sellPressure && changeInLtp > 0) {
                  outcome = "Bullish";
                  bgcolour = "rgb(74,246,63)";
                }
                if (buyPressure < sellPressure && changeInLtp < 0) {
                  outcome = "Bearish";
                  bgcolour = "#FBAFB5";
                }

                return (
                  <tr>
                    {/* call columns */}
                    <td>{strike}</td>
                    <td>{buyPressure}%</td> <td>{sellPressure}%</td>
                    <td>{buyingSellingData?.Puts?.[strike]?.["LTP"]}</td>{" "}
                    <td>{changeInLtp}</td>
                    <td style={{ backgroundColor: bgcolour }}>{outcome}</td>
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              {strikeArray.map((strike, index) => {
                let bgcolour = "";
                let outcome = "Neutral";
                const buyPressure = Math.round(
                  (buyingSellingData?.Calls?.[strike]?.["Ask_Qty"] /
                    (buyingSellingData?.Calls?.[strike]?.["Ask_Qty"] +
                      buyingSellingData?.Calls?.[strike]?.["Bid_Qty"])) *
                    100
                );
                const sellPressure = 100 - buyPressure;
                const changeInLtp = (
                  buyingSellingData?.Calls?.[strike]?.["LTP"] -
                  buyingSellingData.Calls?.[strike]?.["Prev_Close"]
                ).toFixed(2);
                if (buyPressure > sellPressure && changeInLtp > 0) {
                  outcome = "Bullish";
                  bgcolour = "rgb(74,246,63)";
                }
                if (buyPressure < sellPressure && changeInLtp < 0) {
                  outcome = "Bearish";
                  bgcolour = "#FBAFB5";
                }

                return (
                  <tr>
                    {/* call columns */}
                    <td>{strike}</td>
                    <td>{buyPressure}%</td> <td>{sellPressure}%</td>
                    <td>{buyingSellingData?.Calls?.[strike]?.["LTP"]}</td>{" "}
                    <td>{changeInLtp}</td>
                    <td style={{ backgroundColor: bgcolour }}>{outcome}</td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </>
  );
}

export default BuyingSellinPressure;
