import React, { useState } from "react";
import style from "./PortfolioStock.module.css";
import TextField from "@mui/material/TextField";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
function PortfolioOption(props) {
  const {
    setPortfolioOptions,
    portfolioOptions,
    setExpiry,
    portfolioData,
    strikeArray,
    onSubmitOptionHandle,
    onSubmitOptionSellHandle,
    expiry,
    optionSearchString,
    optionSearchHandler,

    indexWiseExpiry,
  } = props;
  const [loading, setLoading] = useState(null);
  const [inputValue, setInputValue] = useState(0);
  const [inputPrice, setInputPrice] = useState(0);

  const submitHandle = (strike) => {
    console.log(strike, inputValue, inputPrice);
    onSubmitOptionHandle(strike, inputPrice, inputValue);
  };
  const submitHandleSell = (symbol) => {
    onSubmitOptionSellHandle(symbol, inputPrice, inputValue);
  };

  console.log(portfolioData);
  if (!portfolioData) return <>loading...</>;
  return (
    <div>
      <div className="option-optionchain subpage">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setExpiry(e.target.value);
              }}
              value={expiry}
            >
              {portfolioOptions.index === "NIFTY" ? (
                <>
                  {indexWiseExpiry.nifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <p>Select Expiry</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setPortfolioOptions({
                  ...portfolioOptions,
                  index: e.target.value,
                });
              }}
              value={portfolioOptions.index}
            >
              <option value="NIFTY">Nifty</option>
              <option value="BANKNIFTY">BankNifty</option>
            </select>
            <p>Select Index</p>
          </div>
        </div>
      </div>
      <TextField
        style={{ width: "47%" }}
        label=""
        id="fullWidth"
        onChange={optionSearchHandler}
        value={optionSearchString}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <table className={style.tableContainer} data-theme={props.theme}>
        <thead>
          <tr>
            {/* call columns */}
            <th className={style.strike_prices}>Strike Price</th>
            <th>ATP</th> <th>LTP</th> <th>High</th> <th>Low</th> <th>Open</th>{" "}
            <th>Volume</th>
            <th>LTQ</th>
            <th>Buy/Sell</th>
          </tr>
        </thead>
        <tbody>
          {strikeArray
            .filter((item) => {
              return optionSearchString === ""
                ? item
                : item.toString().includes(optionSearchString.toString());
            })
            .map((strike, i) => {
              return (
                <>
                  <tr>
                    {/* call columns */}
                    <td className={style.strike_prices}>{strike}CE</td>
                    <td>{portfolioData.Calls[strike].ATP}</td>{" "}
                    <td>{portfolioData.Calls[strike].LTP}</td>{" "}
                    <td>{portfolioData.Calls[strike].High}</td>{" "}
                    <td>{portfolioData.Calls[strike].Low}</td>{" "}
                    <td>{portfolioData.Calls[strike].Open}</td>{" "}
                    <td>{portfolioData.Calls[strike].Volume}</td>
                    <td>{portfolioData.Calls[strike].LTQ}</td>
                    <td>
                      <button type="button" onClick={(e) => setLoading(i)}>
                        Buy/Sell
                      </button>
                    </td>
                  </tr>
                  {loading === i ? (
                    <div>
                      <div className={style.item_container}>
                        <div className={style.option_price}>
                          <input
                            type="number"
                            onChange={(e) => setInputPrice(e.target.value)}
                          />
                          Buy/Sell Price
                        </div>
                        <div>
                          <div className={style.quantity}>
                            <div
                              onClick={(e) => setInputValue(inputValue - 1)}
                              className={style.value_button}
                              id={style.decrease}
                            >
                              -
                            </div>
                            <input
                              onChange={(e) => setInputValue(e.target.value)}
                              className={style.input_number}
                              type="number"
                              id="number"
                              value={inputValue}
                            />
                            <div
                              onClick={(e) => setInputValue(inputValue + 1)}
                              className={style.value_button}
                              id="increase"
                            >
                              +
                            </div>
                          </div>
                          total quantity
                        </div>
                        <div className={style.date}>
                          <input type="date" />
                        </div>
                        <button
                          className={style.btn}
                          onClick={() => submitHandleSell(strike)}
                        >
                          Sell
                        </button>
                        <button
                          className={style.btn}
                          onClick={() => submitHandle(strike)}
                        >
                          Buy
                        </button>
                        <button onClick={() => setLoading(null)}>Close</button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
        </tbody>{" "}
      </table>
    </div>
  );
}

export default PortfolioOption;
