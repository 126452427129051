import React, { useEffect, useState } from "react";
import style from "./OthersScanner.module.css";
import { cloneDeep } from "lodash";

function OthersScanner(props) {
  const {
    titleList,
    scannerData,
    scannerOptions,
    setScannerOptions,
    expiryDates,
  } = props;
  const [sortedTitle, setSortedTitle] = useState([]);

  const param = scannerOptions.parameter;
  useEffect(() => {
    const tempTitle = cloneDeep(titleList);

    if (param === "LTP") {
      if (scannerOptions.order === "Descending") {
        tempTitle.sort((a, b) => {
          return (
            scannerData[b][param] -
            scannerData[b]["Prev_Close"] -
            (scannerData[a][param] - scannerData[a]["Prev_Close"])
          );
        });
      }
      if (scannerOptions.order === "Ascending") {
        tempTitle.sort((a, b) => {
          return (
            scannerData[a][param] -
            scannerData[a]["Prev_Close"] -
            (scannerData[b][param] - scannerData[b]["Prev_Close"])
          );
        });
      }
    } else {
      if (scannerOptions.order === "Descending") {
        tempTitle.sort((a, b) => {
          return scannerData[b][param] - scannerData[a][param];
        });
      }
      if (scannerOptions.order === "Ascending") {
        tempTitle.sort((a, b) => {
          return scannerData[a][param] - scannerData[b][param];
        });
      }
    }
    setSortedTitle(tempTitle);
  }, [scannerOptions]);
  return (
    <>
      <div className="option-straddleandstraggle">
        <div className={style.OtherScanner}>
          {scannerOptions.option === "Option" ? (
            <>
              <div className="dropdown-container">
                <select
                  name="option-straddleandstraggle-index"
                  id="option-straddleandstraggle-index"
                  className="subpage-dropdown"
                  onChange={(e) => {
                    setScannerOptions({
                      ...scannerOptions,
                      date: e.target.value,
                    });
                  }}
                  value={scannerOptions.date}
                >
                  {expiryDates.map((expiry) => {
                    return <option value={expiry}>{expiry}</option>;
                  })}
                </select>
                <p>Select Expiry</p>
              </div>
              <div className="dropdown-container">
                <select
                  name="option-straddleandstraggle-index"
                  id="option-straddleandstraggle-index"
                  className="subpage-dropdown"
                  onChange={(e) => {
                    setScannerOptions({
                      ...scannerOptions,
                      index: e.target.value,
                    });
                  }}
                  value={scannerOptions.index}
                >
                  <option value="NIFTY">Nifty</option>
                  <option value="BANKNIFTY">BankNifty</option>
                </select>
                <p>Select Index</p>
              </div>
              <div className="dropdown-container">
                <select
                  name="option-straddleandstraggle-index"
                  id="option-straddleandstraggle-index"
                  className="subpage-dropdown"
                  onChange={(e) => {
                    setScannerOptions({
                      ...scannerOptions,
                      optionChain: e.target.value,
                    });
                  }}
                  value={scannerOptions.optionChain}
                >
                  <option value="Calls">Calls</option>
                  <option value="Puts">Puts</option>
                </select>
                <p>Select Call or Put</p>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setScannerOptions({
                  ...scannerOptions,
                  option: e.target.value,
                });
              }}
            >
              <option value="Future">Future</option>
              <option value="Option">Option</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setScannerOptions({
                  ...scannerOptions,
                  parameter: e.target.value,
                });
              }}
            >
              <option value="LTP">LTP</option>
              <option value="Volume">Volume</option>
              <option value="OI">OI</option>
            </select>
            <p>Select Parameter</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setScannerOptions({
                  ...scannerOptions,
                  order: e.target.value,
                });
              }}
            >
              <option value="Ascending">Ascending</option>
              <option value="Descending">Descending</option>
            </select>
            <p>Select Parameter</p>
          </div>
        </div>
      </div>
      <div className={style.container}>
        {sortedTitle.map((title) => {
          console.log(scannerData[title]?.[param]);
          const change = (
            scannerData[title]?.[param] - scannerData[title]?.Prev_Close
          ).toFixed(2);

          return (
            <div className={style.card_Container}>
              <div>
                <div className={style.title}>{title}</div>
                <div className={style.card_Details}>
                  <div className={style.nfo}>NFO</div>
                  <div className={style.date}>23NOV</div>
                </div>
              </div>
              <div
                className={`${style.card_Value} ${
                  change < 0 ? style.red : style.green
                }`}
              >
                <div className={style.number}>
                  {scannerData[title]?.[param]}
                </div>
                <div className={style.percentage}>
                  {change}
                  (1.39%)
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default OthersScanner;
