import React from "react";

function PremiumDecay(props) {
  const { data, strikeArray } = props;
  if (!data) return <>loading...</>;
  function callPercent() {
    const callTotal = strikeArray.reduce((acc, curr) => {
      const change = Number(
        (data.Calls[curr].LTP - data.Calls[curr].Prev_Close).toFixed(2)
      );
      console.log(acc, change);
      return acc + change;
    }, 0);

    const putTotal = Math.abs(
      strikeArray.reduce((acc, curr) => {
        const change = Number(
          (data.Puts[curr].LTP - data.Puts[curr].Prev_Close).toFixed(2)
        );
        console.log(acc, change);
        return acc + change;
      }, 0)
    );
    const sumOfCallPut = callTotal + putTotal;
    console.log(callTotal, sumOfCallPut);
    return (callTotal / sumOfCallPut) * 100;
  }
  function putPercent() {
    const callTotal = strikeArray.reduce((acc, curr) => {
      const change = Number(
        (data.Calls[curr].LTP - data.Calls[curr].Prev_Close).toFixed(2)
      );
      console.log(acc, change);
      return acc + change;
    }, 0);

    const putTotal = Math.abs(
      strikeArray.reduce((acc, curr) => {
        const change = Number(
          (data.Puts[curr].LTP - data.Puts[curr].Prev_Close).toFixed(2)
        );
        console.log(acc, change);
        return acc + change;
      }, 0)
    );
    const sumOfCallPut = callTotal + putTotal;
    console.log(callTotal, sumOfCallPut);
    return (putTotal / sumOfCallPut) * 100;
  }

  return (
    <div>
      <table
        className="info-table"
        style={{ width: "95%", marginLeft: "1rem", marginRight: "1rem" }}
        data-theme={props.theme}
      >
        <thead>
          <tr>
            <th colSpan={2}>Call</th>{" "}
            <th className="strike-prices">Strike Prices</th>{" "}
            <th colSpan={2}>Put</th>
          </tr>
          <tr>
            {/* call columns */}
            <th>LTP</th> <th>Change</th>
            <th className="strike-prices"></th>
            {/* put columns */}
            <th>Change</th> <th>LTP</th>
          </tr>
        </thead>
        <tbody>
          {strikeArray.map((strike) => {
            const callChange = (
              data.Calls[strike].LTP - data.Calls[strike].Prev_Close
            ).toFixed(2);
            const putChange = (
              data.Puts[strike].LTP - data.Puts[strike].Prev_Close
            ).toFixed(2);
            return (
              <tr>
                {/* call columns */}
                <td>{data.Calls[strike].LTP}</td>{" "}
                <td style={{ color: `${callChange < 0 ? "red" : "green"}` }}>
                  {callChange}
                </td>
                <td className="strike-prices">{strike}</td>
                {/* put columns */}
                <td style={{ color: `${putChange < 0 ? "red" : "green"}` }}>
                  {putChange}
                </td>{" "}
                <td>{data.Puts[strike].LTP}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={2}>
              {strikeArray
                .reduce((acc, curr) => {
                  const change = Number(
                    (
                      data.Calls[curr].LTP - data.Calls[curr].Prev_Close
                    ).toFixed(2)
                  );
                  console.log(acc, change);
                  return acc + change;
                }, 0)
                .toFixed(2)}
            </td>
            <td></td>
            <td colSpan={2}>
              {strikeArray
                .reduce((acc, curr) => {
                  const change = Number(
                    (data.Puts[curr].LTP - data.Puts[curr].Prev_Close).toFixed(
                      2
                    )
                  );
                  console.log(acc, change);
                  return acc + change;
                }, 0)
                .toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        Call {callPercent().toFixed(2)}
        %<br />
        Put {putPercent().toFixed(2)}%
      </div>
    </div>
  );
}

export default PremiumDecay;
