import React, { useEffect, useState } from "react";
import style from "./StockDetails.module.css";
import { db, futureDB } from "../../firebase";
import { onValue, ref } from "firebase/database";
import TextField from "@mui/material/TextField";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
function StockDetails(props) {
  const { searchString, strikePrice, stockData, searchHandler } = props;

  console.log(strikePrice);
  if (strikePrice.length === 0) return <>loading...</>;
  return (
    <div className={style.stockDetailsContainer}>
      <TextField
        style={{ width: "47%" }}
        label=""
        id="fullWidth"
        onChange={searchHandler}
        value={searchString}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />{" "}
      <table className={style.tableContainer} data-theme={props.theme}>
        <thead>
          <tr>
            {/* call columns */}
            <th className={style.strike_prices}>Strike Price</th>
            <th>ATP</th> <th>LTP</th> <th>High</th> <th>Low</th> <th>Open</th>{" "}
            <th>Volume</th>
            <th>LTQ</th>
          </tr>
        </thead>
        <tbody>
          {strikePrice
            .filter((item) => {
              return searchString === ""
                ? item
                : item.toLowerCase().includes(searchString.toLowerCase());
            })
            .map((strike, i) => {
              return (
                <tr>
                  {/* call columns */}
                  <td className={style.strike_prices}>{strike}</td>
                  <td>{stockData[strike].ATP}</td>{" "}
                  <td>{stockData[strike].LTP}</td>{" "}
                  <td>{stockData[strike].High}</td>{" "}
                  <td>{stockData[strike].Low}</td>{" "}
                  <td>{stockData[strike].Open}</td>{" "}
                  <td>{stockData[strike].Volume}</td>
                  <td>{stockData[strike].LTQ}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default StockDetails;
