import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
function AdvanceDeclineRatio(props) {
  const { stockData, strikePrice } = props;
  const [isActive, setIsActive] = useState(false);
  const [advanceChartDataSet, setAdvanceChartDataSet] = useState([]);
  // const [resultDate, setResultDate] = useState([]);

  let advancingStockCount = 0;
  let decliningStockCount = 0;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  useEffect(() => {
    let temp = [];
    let tempCount = advancingStockCount;
    temp.push(tempCount);
    for (let i = 0; i < 3; i++) {
      tempCount = tempCount / 2;
      temp.unshift(Math.round(tempCount));
    }
    temp.unshift(0);
    setAdvanceChartDataSet(temp);
  }, []);
  console.log(advanceChartDataSet);

  if (!stockData) return <>loading...</>;

  return (
    <div
      style={{
        margin: "1rem",
      }}
    >
      <div
        style={{
          margin: "2rem",
          position: "relative",
          height: "20rem",
          width: "80vw",
        }}
      >
        <Line
          data={{
            labels: [0.0, 0.2, 0.4, 0.6, 0.8],
            datasets: [
              {
                label: "Advancing Stocks",
                data: advanceChartDataSet,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
              {
                label: "Declining Stocks",
                data: [0, 10, 18, 22, 26],
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ],
          }}
          options={options}
        />
      </div>
      <div
        style={{
          margin: "1rem",
          backgroundColor: "#E0DFE4",
          padding: "0.8rem",
          cursor: "pointer",
        }}
        onClick={() => setIsActive(!isActive)}
      >
        <h5>
          View Advancing/Declining Stocks {isActive ? <>&uarr;</> : <>&darr;</>}
        </h5>
        <div style={{ display: `${isActive ? "block" : "none"}` }}>
          <b>Advancing Stocks</b>
          <ul>
            {strikePrice.map((strike, i) => {
              if (stockData[strike].LTP - stockData[strike].Prev_Close < 0)
                return;
              advancingStockCount++;
              return <li key={strike}> {strike}</li>;
            })}
          </ul>
          Total Advancing Stocks {advancingStockCount}
          <hr />
        </div>
        <div
          style={{
            display: `${isActive ? "block" : "none"}`,
          }}
        >
          <b>Declining Stocks</b>
          <ul>
            {strikePrice.map((strike, i) => {
              if (stockData[strike].LTP - stockData[strike].Prev_Close > 0)
                return;
              decliningStockCount++;
              return <li key={strike}> {strike}</li>;
            })}
          </ul>
          <br />
          <span style={{ fontWeight: "700" }}>
            {" "}
            Total Declining Stocks: {decliningStockCount}
          </span>
        </div>
        Advance/Decline Ratio:{advancingStockCount}/{decliningStockCount}
      </div>
    </div>
  );
}

export default AdvanceDeclineRatio;
