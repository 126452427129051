import React, { useEffect, useState, useId } from "react";

import "./Option.css";
import "./DailyActivities.css";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";

import {
  Tabs,
  Tab,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import {
  getDoc,
  doc,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
const genUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export default function DailyActivities(props) {
  const [dailyActivitiesTab, setDailyActivitiesTab] =
    useState("morningbreakfast");
  const [componentLoading, setComponentLoading] = useState(true);
  const [fetchedJournals, setFetchedJournals] = useState(null);
  const [currentJournal, setCurrentJournal] = useState(null);
  const [positiveValue, setPositiveValue] = useState();
  const [negativeValue, setNegativeValue] = useState();

  const [morningBreakfastData, setMorningBreakfastData] = useState({
    sgxnifty: "Yes",
    dowjones: "Yes",
    adrs: "Yes",
    anyprevdaynews: "Yes",
    eventdaycalendar: "Yes",
    fiianddiidata: "Yes",
    trend: "Yes",
    globalmarket: "Yes",
    supportandresistance: "Yes",
  });

  const { currentUser } = useAuth();

  const fetchJournals = async (userId) => {
    // firestore fetch
    const retCollection = [];
    const journalsCollection = collection(
      db,
      "user_journals",
      userId,
      "journals"
    );
    const docsSnap = await getDocs(journalsCollection);
    docsSnap?.forEach((doc) => {
      retCollection.push(doc.data());
    });
    return retCollection;
  };

  const handleCreateJournal = async (userId) => {
    setComponentLoading(true);
    if (!currentUser) {
      setComponentLoading(false);
      return;
    }
    // get an id for the journal
    const docForNextId = doc(db, "user_journals", userId);
    // check if there is a doc for the given user otherwise create one
    let docSnap = await getDoc(docForNextId);
    if (!docSnap.exists()) {
      // create a new doc for the user
      await setDoc(docForNextId, { next_journal_id: 0 });
      // update docsnap
      docSnap = await getDoc(docForNextId);
    }
    // get the next journal id
    const nextJournalId = docSnap.data().next_journal_id;
    console.log(nextJournalId);
    // create a new journal
    const journalDocRef = doc(
      db,
      "user_journals",
      userId,
      "journals",
      nextJournalId.toString()
    );
    const journalData = {
      journal_id: nextJournalId,
      title: "",
      content: "",
      createdOn: new Date(),
      lastModified: new Date(),
    };
    await setDoc(journalDocRef, journalData);
    // update the next journal id
    await setDoc(docForNextId, { next_journal_id: nextJournalId + 1 });

    // refresh the journals list
    const journals = await fetchJournals(currentUser.uid);
    setFetchedJournals(journals);

    // fetch newly created journal before settingcurrent journal
    const journalDoc = doc(
      db,
      "user_journals",
      userId,
      "journals",
      nextJournalId.toString()
    );
    const journalDocSnap = await getDoc(journalDoc);
    const journal = journalDocSnap.data();
    setCurrentJournal(journal);
    setComponentLoading(false);
  };
  const handleDeleteJournal = async (journalId) => {
    setComponentLoading(true);

    setCurrentJournal(null);
    const journalsCollection = collection(
      db,
      "user_journals",
      currentUser.uid,
      "journals"
    );
    const journalDoc = doc(journalsCollection, journalId.toString());
    await deleteDoc(journalDoc);
    const journals = await fetchJournals(currentUser.uid);
    setFetchedJournals(journals);

    setComponentLoading(false);
  };

  const updateJournal = async (userId, journalId, journalData) => {
    setComponentLoading(true);
    const journalDocRef = doc(
      db,
      "user_journals",
      userId,
      "journals",
      journalId.toString()
    );
    await setDoc(journalDocRef, journalData);
    // refresh journal list
    const journals = await fetchJournals(currentUser.uid);
    setFetchedJournals(journals);
    // fetch newly created journal before settingcurrent journal
    const journalDoc = doc(
      db,
      "user_journals",
      userId,
      "journals",
      journalId.toString()
    );
    const journalDocSnap = await getDoc(journalDoc);
    const journal = journalDocSnap.data();
    setCurrentJournal(journal);
    setComponentLoading(false);
  };

  const fetchAndSetMorningBreakfastData = async (userId) => {
    setComponentLoading(true);
    // firestore fetch
    const morningBreakfastDataDoc = doc(db, "user_morningbreakfast", userId);
    // create if not exist with all default true boolean values
    let morningBreakfastDataDocSnap = await getDoc(morningBreakfastDataDoc);
    if (!morningBreakfastDataDocSnap.exists()) {
      await setDoc(morningBreakfastDataDoc, morningBreakfastData);
      morningBreakfastDataDocSnap = await getDoc(morningBreakfastDataDoc);
    } else {
      setMorningBreakfastData(morningBreakfastDataDocSnap.data());
    }
  };
  const handleMorningBreakfastDataChange = async (
    userId,
    morningBreakfastData
  ) => {
    setComponentLoading(true);
    const morningBreakfastDataDoc = doc(db, "user_morningbreakfast", userId);
    await setDoc(morningBreakfastDataDoc, morningBreakfastData);
    setComponentLoading(false);
  };
  useEffect(() => {
    setComponentLoading(true);

    currentUser &&
      fetchJournals(currentUser.uid).then((journals) => {
        setFetchedJournals(journals);
      });

    currentUser && fetchAndSetMorningBreakfastData(currentUser.uid);

    setTimeout(() => {
      setComponentLoading(false);
    }, 1000);
  }, [currentUser]);

  useEffect(() => {
    const temp = morningBreakfastData;
    let yesCount = 0;
    let noCount = 0;
    for (let key in temp) {
      //   console.log(temp[key]);
      if (temp[key] === "Yes") yesCount++;
      if (temp[key] === "No") noCount++;
    }
    setPositiveValue(yesCount);
    setNegativeValue(noCount);
    console.log(`yes count= ${yesCount}/n no count=${noCount} `);
  }, [morningBreakfastData]);
  //   console.log(morningBreakfastData);
  return (
    <div style={{ position: "relative", height: "100%" }}>
      {componentLoading && (
        <div className="loader-overlay">
          <CircularProgress />
        </div>
      )}
      <Tabs
        value={dailyActivitiesTab}
        onChange={(e, newValue) => {
          setDailyActivitiesTab(newValue);
        }}
        aria-label="Change Daily Activities Tab"
        centered
        data-theme={props.theme}
        scrollButtons="auto"
        sx={{
          "& .MuiTabScrollButton-root": {
            color: props.theme === "dark" ? "white" : "black",
          },
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: props.theme === "dark" ? "white" : "blue",
          },
        }}
      >
        <Tab
          label="Morning Breakfast"
          className="muiTab"
          id="simple-tab-1"
          value="morningbreakfast"
        />
        <Tab
          label="Daily Journal"
          className="muiTab"
          id="simple-tab-2"
          value="dailyjournal"
        />
      </Tabs>
      {dailyActivitiesTab === "dailyjournal" && (
        <div
          className="dailyactivities-dailyjournal"
          style={{ position: "relative" }}
        >
          <div className="dailyjournal-journal-list">
            <h3 id="saved-journals-title">Saved Journals</h3>

            <Paper
              spacing={1}
              variant="none"
              className="dailyjournal-journal-list-container"
              style={{
                height: "50vh",
                overflow: "auto",
                backgroundColor: "transparent",
              }}
            >
              {fetchedJournals === null ||
              (fetchedJournals && fetchedJournals.length === 0) ? (
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80%",
                    color: "grey",
                    marginLeft: "-16px",
                  }}
                >
                  You have 0 journals.
                </p>
              ) : (
                fetchedJournals.map((journal) => {
                  return (
                    <Card
                      sx={{ maxWidth: 345, background: "transparent" }}
                      className="dailyjournal-journal-list-item"
                      onClick={() => {
                        setCurrentJournal(journal);
                      }}
                      key={journal.journal_id}
                    >
                      <CardActionArea>
                        <CardContent>
                          <Typography variant="h7" component="div">
                            <b>{journal.title}</b>
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: "12px" }}
                          >
                            Created:{" "}
                            <b>
                              {journal.createdOn
                                .toDate()
                                .toLocaleDateString("en-in") +
                                " " +
                                journal.createdOn.toDate().toLocaleTimeString()}
                            </b>
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: "12px" }}
                          >
                            Last Edited:{" "}
                            <b>
                              {journal.lastModified
                                .toDate()
                                .toLocaleDateString("en-in") +
                                " " +
                                journal.lastModified
                                  .toDate()
                                  .toLocaleTimeString()}
                            </b>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })
              )}
            </Paper>
          </div>

          <div className="dailyjournal-journal-editor-area">
            {/* Journal editor here */}
            {currentJournal ? (
              <div className="dailyjournal-journal-editor">
                <div className="dailyjournal-journal-editor-title-bar">
                  <div className="dailyjournal-journal-editor-title-bar-info">
                    <textarea
                      maxLength={100}
                      className="journal-content-title-editor-area"
                      value={currentJournal.title}
                      placeholder="Add entry title"
                      wrap="hard"
                      onChange={(e) => {
                        setCurrentJournal({
                          ...currentJournal,
                          title: e.target.value,
                        });
                      }}
                    />
                    <p style={{ display: "flex", gap: "32px" }}>
                      <span>
                        {"Created: "}{" "}
                        <b>
                          {currentJournal.createdOn
                            .toDate()
                            .toLocaleDateString("en-in")}{" "}
                          -{" "}
                          {currentJournal.createdOn
                            .toDate()
                            .toLocaleTimeString()}
                        </b>
                      </span>
                      <span>
                        {"Last Modified: "}{" "}
                        <b>
                          {currentJournal.lastModified
                            .toDate()
                            .toLocaleDateString("en-in")}{" "}
                          -{" "}
                          {currentJournal.lastModified
                            .toDate()
                            .toLocaleTimeString("en-in")}{" "}
                        </b>
                      </span>
                    </p>
                  </div>
                  <div>
                    <button
                      className="journal-editor-close"
                      onClick={() => {
                        setCurrentJournal(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
                <div className="dailyjournal-journal-editor-content">
                  <textarea
                    id={"editor-area-" + currentJournal.journal_id}
                    maxLength={1000}
                    className="journal-content-editor-area"
                    value={currentJournal.content}
                    placeholder="Write here..."
                    wrap="hard"
                    onChange={(e) => {
                      setCurrentJournal({
                        ...currentJournal,
                        content: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="save-journal-button"
                    onClick={() => {
                      updateJournal(
                        currentUser.uid,
                        currentJournal.journal_id,
                        { ...currentJournal, lastModified: new Date() }
                      );
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="delete-journal-button"
                    onClick={() => {
                      handleDeleteJournal(currentJournal.journal_id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="dailyjournal-journal-editor-empty"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p style={{ color: "grey" }}>No Journal Selected</p>
                <button
                  className="create-journal-button"
                  onClick={() => {
                    console.log(genUUID());
                    handleCreateJournal(currentUser.uid);
                  }}
                >
                  Create New Journal Entry
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {dailyActivitiesTab === "morningbreakfast" && (
        <div className="dailyactivities-morningbreakfast">
          <TableContainer
            component={Paper}
            sx={{ background: "transparent", width: "100%" }}
          >
            <Table
              aria-label="simple table"
              className="morning-breakfast-table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>SGX NIFTY</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.sgxnifty}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          sgxnifty: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          sgxnifty: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>DOW JONES</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.dowjones}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          dowjones: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          dowjones: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>ADRs</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.adrs}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          adrs: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          adrs: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>
                      ANY PREVIOUS DAY IMPORTANT NEWS WHICH CAME AFTER MARKET
                      HOURS
                    </b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.anyprevdaynews}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          anyprevdaynews: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          anyprevdaynews: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>EVENT DAY CALENDAR</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.eventdaycalendar}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          eventdaycalendar: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          eventdaycalendar: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>FII AND DII DATA</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.fiianddiidata}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          fiianddiidata: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          fiianddiidata: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>TREND</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.trend}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          trend: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          trend: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>GLOBAL MARKET</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.globalmarket}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          globalmarket: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          globalmarket: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>SUPPORT AND RESISTANCE</b>
                  </TableCell>
                  <TableCell align="center">
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={morningBreakfastData.supportandresistance}
                      onChange={(e) => {
                        setMorningBreakfastData({
                          ...morningBreakfastData,
                          supportandresistance: e.target.value,
                        });
                        handleMorningBreakfastDataChange(currentUser.uid, {
                          ...morningBreakfastData,
                          supportandresistance: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="Negative"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          Postive: {positiveValue}
          <br />
          Negative: {negativeValue}
        </div>
      )}
    </div>
  );
}
