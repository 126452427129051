import React, { useState, useEffect } from "react";
import "./Option.css";
import "./Others.css";
import { ref, onValue } from "firebase/database";
import { getDatabase, off, get } from "firebase/database";
import { app } from "../../firebase";
import { futureDB } from "../../firebase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import OthersScanner from "./OthersScanner";
import ChartPlotting from "./ChartPlotting";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";
import StrategyBuilder from "./StrategyBuilder";
import StrategyBuilderModal from "./StrategyBuilderModal";
import { useAuth } from "../../contexts/AuthContext";
function getMonth() {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let name = month[d.getMonth()];
  return name;
}

export default function Others(props) {
  const [othersTab, setOthersTab] = useState("strategybuilder");
  const [scannerData, setScannerData] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [dump, setDump] = useState([]);
  const [dumpTitle, setDumpTitle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [expiryDates, setExpiryDates] = useState([]);

  const currentDate = "23" + getMonth().slice(0, 3).toUpperCase();
  const [futureExpiry, setFutureExpiry] = useState();
  const { currentUser } = useAuth();
  console.log(currentUser);
  const [scannerOptions, setScannerOptions] = useState({
    index: "Nifty",
    optionChain: "Calls",
    option: "Future",
    parameter: "LTP",
    order: "Ascending",
    count: 0,
    date: [],
  });
  const [chartPlottingOptions, setChartPlottingOptions] = useState({
    index: "NIFTY",
    strikPrice: 0,
    minutes: 1,
    expiry: 0,
  });
  const [strikeArray, setStrikeArray] = useState([]);
  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();

      const timeZoneOffset = 330; // 5 hours and 30 minutes in minutes
      currentDate.setMinutes(currentDate.getMinutes() + timeZoneOffset);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const date = new Date(2000 + yy, mm - 1, dd); // Adjust year to 4-digit format

        return date >= currentDate;
      });

      setExpiryDates(expiryArray);
      setScannerOptions({
        ...scannerOptions,
        date: expiryArray[0],
      });
      const n = expiryArray.length - 1;
      setChartPlottingOptions({
        ...chartPlottingOptions,
        expiry: expiryArray[n],
      });
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);
  // console.log(expiryDates);
  useEffect(() => {
    try {
      setComponentLoading(true);
      const rtdb = getDatabase(app);
      const futureRef = ref(rtdb, `tick/Future`);

      var fetchIndicesData = async () => {
        const niftysnap = await get(futureRef);
        const niftyData = niftysnap.val();
        const expiry = Object.keys(niftyData);
        const rbt = expiry[0];
        setFutureExpiry(rbt);
      };
      fetchIndicesData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  console.log(`tick/Future/${futureExpiry}`);
  useEffect(() => {
    if (scannerOptions.option === "Future") {
      try {
        setComponentLoading(true);
        const rtdb = getDatabase(app);
        const futureRef = ref(rtdb, `tick/Future/${futureExpiry}`);

        var fetchIndicesData = async () => {
          const niftysnap = await get(futureRef);
          const niftyData = niftysnap.val();

          const title = [];

          for (const key in niftyData) {
            console.log(key);
            title.push(key);
            //   tempArray.push(tempfutureAnalysis[haris]);
          }

          setScannerData(niftyData);
          setTitleList(title);
        };
      } catch (error) {
        console.log("error!!!!!!");
      }

      fetchIndicesData();
    }
    let currentcount = scannerOptions["date"];
    let option = scannerOptions["optionChain"];
    if (scannerOptions.option === "Option") {
      let temp = [];
      let name;
      let index = "";
      let strikeNumber = [];
      let tempData;

      const dbRef = ref(futureDB, `tick/Option/${currentcount}`);
      onValue(dbRef, (snapshot) => {
        name = snapshot.val();

        for (let key in name) {
          index = key;
        }
        temp = snapshot.val();
        tempData = temp?.[index]?.[option];

        for (let strike in tempData) {
          strikeNumber.push(strike);
        }
        setDump(tempData);
        setDumpTitle(strikeNumber);
        setScannerData(tempData);
        setTitleList(strikeNumber);
        if (!loading) {
          setLoading(true);
        }
      });
      if (scannerOptions.index != index) {
        setScannerOptions({ ...scannerOptions, index: index });
      }
    }
    // const interval = setInterval(fetchIndicesData, 2000);
  }, [scannerOptions, loading, futureExpiry]);

  useEffect(() => {
    const index =
      chartPlottingOptions["index"] === "NIFTY" ? "NIFTY 50" : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        console.log(niftyLTPData);
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          5,
          chartPlottingOptions["index"] === "NIFTY" ? 50 : 100
        );

        strikePriceArray.sort();
        // setChartPlottingOptions({
        //   ...chartPlottingOptions,
        //   strikPrice: strikePriceArray[0],
        // });
      }
    });
    setStrikeArray(strikePriceArray);
  }, [chartPlottingOptions["index"]]);
  console.log(scannerData);
  // console.log(dumpTitle);
  console.log(scannerOptions);

  useEffect(() => {
    const dbRef = ref(futureDB, `/Strategies/${currentUser.uid}`);

    onValue(dbRef, (snapshot) => {
      let temp = [];
      snapshot.forEach((snap) => {
        temp.push(snap.val());
      });
      console.log(temp);

      setData(temp);
    });
  }, []);
  console.log(`/Strategies/${currentUser.uid}`);
  console.log(data);
  return (
    <>
      <div>
        <Tabs
          value={othersTab}
          onChange={(e, newValue) => {
            setOthersTab(newValue);
          }}
          aria-label=""
          data-theme={props.theme}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabScrollButton-root": {
              color: props.theme === "dark" ? "white" : "black",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: props.theme === "dark" ? "white" : "blue",
            },
          }}
        >
          <Tab
            label="Strategy Builder"
            className="muiTab"
            id="simple-tab-1"
            value="strategybuilder"
          />
          <Tab
            label="Chart Plotting"
            className="muiTab"
            id="simple-tab-2"
            value="chartplotting"
          />

          <Tab
            label="Scanner"
            className="muiTab"
            id="simple-tab-4"
            value="scanner"
          />
        </Tabs>
      </div>
      {othersTab === "scanner" && (
        <OthersScanner
          scannerData={scannerData}
          titleList={titleList}
          setScannerOptions={setScannerOptions}
          scannerOptions={scannerOptions}
          expiryDates={expiryDates}
          theme={props.theme}
        />
      )}
      {othersTab === "chartplotting" && (
        <>
          <ChartPlotting
            scannerData={scannerData}
            strikeArray={strikeArray}
            setChartPlottingOptions={setChartPlottingOptions}
            chartPlottingOptions={chartPlottingOptions}
            expiryDates={expiryDates}
            theme={props.theme}
          />
        </>
      )}
      {othersTab === "strategybuilder" && (
        <>
          <StrategyBuilderModal
            scannerData={scannerData}
            strikeArray={strikeArray}
            expiryDates={expiryDates}
            theme={props.theme}
            data={data}
          />
        </>
      )}
    </>
  );
}
