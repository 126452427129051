import React, { useState, useEffect } from "react";
import "./Option.css";
import "./Future.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getDatabase, ref, onValue, off, get } from "firebase/database";
import { app } from "../../firebase";

import FutureAnalysisTable from "./FutureAnalysisTable";
import IndexVolumeComparison from "./IndexVolumeComparison";
import Indices from "./Indices";

export default function Future(props) {
  const [componentLoading, setComponentLoading] = useState(true);
  const [futureAnalysis, setfutureAnalysis] = useState(true);
  const [futureTab, setFutureTab] = useState("futureanalysis");
  const [futureStocks, setfutureStocks] = useState([]);
  const [FutureAnalysisData, setFutureAnalysisData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [titleList, setTitleList] = useState([]);
  useEffect(() => {
    if (futureAnalysis) {
      try {
        setComponentLoading(true);
        const rtdb = getDatabase(app);
        const futureRef = ref(rtdb, `tick/Future`);

        var fetchIndicesData = async () => {
          const niftysnap = await get(futureRef);
          const niftyData = niftysnap.val();
          const expiry = Object.keys(niftyData);
          const rbt = expiry[0];
          let tempfutureAnalysis = [];
          const arr = [];

          for (const rishi in niftyData[rbt]) {
            const a = {
              name: rishi,
              price: niftyData[rbt][rishi]["LTP"],
              vol: niftyData[rbt][rishi]["Volume"],
              oi: niftyData[rbt][rishi]["OI"],
            };
            tempfutureAnalysis.push(niftyData[rbt]);
            arr.push(a);
          }

          setfutureStocks(arr);
          setFutureAnalysisData(tempfutureAnalysis);
          const title = [];
          const tempArray = [];
          for (const haris in FutureAnalysisData[0]) {
            title.push(haris);
            tempArray.push(FutureAnalysisData[0][haris]);
          }

          setTableData(tempArray);
          setTitleList(title);
        };
      } catch (error) {
        console.log("error!!!!!!");
      }

      fetchIndicesData();
    }

    // const interval = setInterval(fetchIndicesData, 2000);
  }, [futureAnalysis, futureStocks]);
  return (
    <div>
      <Tabs
        value={futureTab}
        onChange={(e, newValue) => {
          setFutureTab(newValue);
        }}
        data-theme={props.theme}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          "& .MuiTabScrollButton-root": {
            color: props.theme === "dark" ? "white" : "black",
          },
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: props.theme === "dark" ? "white" : "blue",
          },
        }}
      >
        <Tab
          label="Future Analysis"
          className="muiTab"
          id="simple-tab-1"
          value="futureanalysis"
        />
        <Tab
          label="Index Volume Comparison"
          className="muiTab"
          id="simple-tab-2"
          value="indexvolumecomparison"
        />
        <Tab
          label="Indices"
          className="muiTab"
          id="simple-tab-3"
          value="indices"
        />
      </Tabs>

      {futureTab === "futureanalysis" && (
        <FutureAnalysisTable tableData={tableData} titleList={titleList} />
        // <>ftureananlisis</>
      )}
      {futureTab === "indexvolumecomparison" && (
        <IndexVolumeComparison />
        // <>ftureananlisis</>
      )}
      {futureTab === "indices" && (
        <Indices />
        // <>ftureananlisis</>
      )}
    </div>
  );
}
