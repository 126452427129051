import React, { useEffect, useState } from "react";
import style from "./HighestOi.module.css";
import { cloneDeep } from "lodash";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";
import { db, futureDB } from "../../firebase";
import { onValue, ref } from "firebase/database";
function HighestOi(props) {
  const { setHighestOiOptions, highestOiOptions, expiryDates, tableWidth } =
    props;
  const [date, setDate] = useState(expiryDates);
  const [oiDataNifty, setOiDataNifty] = useState([]);
  const [oiDataBankNifty, setOiDataBankNifty] = useState([]);
  const [niftyCallStrikePrices, setNiftyCallStrikePrices] = useState([]);
  const [niftyPutStrikePrices, setNiftyPutStrikePrices] = useState([]);

  //   console.log(expiryDates);
  useEffect(() => {
    setDate(expiryDates);
    let oiBankNiftyData = [];
    let oiNiftyData = [];
    for (let i = 0; i < expiryDates.length; i++) {
      console.log("-------for loop--------");
      const dbRef = ref(futureDB, `/tick/Option/${date[i]}`);
      onValue(dbRef, (snapshot) => {
        if (snapshot.val().NIFTY) {
          console.log("outer if statement");
          snapshot.forEach((snap) => {
            console.log("loop");
            oiNiftyData.push({
              date: date[i],
              data: snap.val(),
            });
          });
        }
        if (snapshot.val().BANKNIFTY) {
          snapshot.forEach((snap) => {
            oiBankNiftyData.push({
              date: date[i],
              data: snap.val(),
            });
          });
        }
      });
    }
    setOiDataNifty(oiNiftyData);
    setOiDataBankNifty(oiBankNiftyData);
    oiBankNiftyData = [];
    oiNiftyData = [];
    // let niftyStrikePriceArray = buildStrikePricesArray(
    //   niftyLTPData,
    //   oiNiftyData[0].data.length,
    //   50
    // );
    console.log("useEffect");
  }, []);
  console.log(oiDataBankNifty);
  // OI NIFTY 50 DATA TABLE FUNCTION
  const oiNiftyTable = (index, niftyStrikeArray1, niftyPutStrike1) => {
    return (
      <>
        <tr>
          <td style={{ backgroundColor: "inherit", border: "0" }} colSpan={4}>
            {oiDataNifty[index].date}
          </td>
        </tr>
        <tr>
          <td>Call</td>
          {niftyStrikeArray1.map((strike, i) => {
            if (i >= 3) return;
            return (
              <>
                <td>
                  {strike}
                  <br />
                  {oiDataNifty[index].data.Calls?.[strike]?.OI}
                </td>
              </>
            );
          })}
        </tr>
        <tr>
          <td>Put</td>
          {niftyPutStrike1.map((strike, i) => {
            if (i >= 3) return;
            return (
              <>
                <td>
                  {strike}
                  <br />
                  {oiDataNifty[index].data.Puts?.[strike]?.OI}
                </td>
              </>
            );
          })}
        </tr>
      </>
    );
  };
  // ----------OI BANK NIFTY DATA TABLE FUNCTION

  // const oiBankNiftyTable = (index, niftyStrikeArray1, niftyPutStrike1) => {
  //   return (

  //   );
  // };

  if (oiDataNifty.length <= 0) return <>loading</>;
  return (
    <>
      <table
        style={{ width: `${tableWidth}` }}
        className={style.tableContainer}
      >
        <tbody>
          {highestOiOptions.index === "nifty50" ? (
            <>
              {oiDataNifty.map((item, index) => {
                // if (index >= 2) return;
                const niftyStrikeArray1 = [];
                for (let keys in item.data.Calls) {
                  niftyStrikeArray1.push(keys);
                }

                const niftyPutStrike1 = cloneDeep(niftyStrikeArray1);

                niftyStrikeArray1.sort((a, b) => {
                  return item.data.Calls?.[b]?.OI - item.data.Calls?.[a]?.OI;
                });
                niftyPutStrike1.sort((a, b) => {
                  return item.data.Puts?.[b]?.OI - item.data.Puts?.[a]?.OI;
                });
                if (index >= 3) return;
                return (
                  <>{oiNiftyTable(index, niftyStrikeArray1, niftyPutStrike1)}</>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              {oiDataBankNifty.map((item, index) => {
                // if (index >= 2) return;
                const niftyStrikeArray1 = [];
                for (let keys in item.data.Calls) {
                  niftyStrikeArray1.push(keys);
                }

                const niftyPutStrike1 = cloneDeep(niftyStrikeArray1);

                niftyStrikeArray1.sort((a, b) => {
                  return item.data.Calls?.[b]?.OI - item.data.Calls?.[a]?.OI;
                });
                niftyPutStrike1.sort((a, b) => {
                  return item.data.Puts?.[b]?.OI - item.data.Puts?.[a]?.OI;
                });

                return (
                  <>
                    <tr>
                      <td
                        style={{ backgroundColor: "inherit", border: "0" }}
                        colSpan={4}
                      >
                        {oiDataBankNifty[index].date}
                      </td>
                    </tr>
                    <tr>
                      <td>Call</td>
                      {niftyStrikeArray1.map((strike, i) => {
                        if (i >= 3) return;
                        return (
                          <>
                            <td>
                              {strike}
                              <br />
                              {oiDataBankNifty[index].data.Calls?.[strike]?.OI}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>Put</td>
                      {niftyPutStrike1.map((strike, i) => {
                        if (i >= 3) return;
                        return (
                          <>
                            <td>
                              {strike}
                              <br />
                              {oiDataBankNifty[index].data.Puts?.[strike]?.OI}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </>
  );
}

export default HighestOi;
