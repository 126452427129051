import React, { useEffect, useState } from "react";
import "./Option.css";
function StrikePricewiseTable(props) {
  const {
    optionChainData,
    strikeArray,
    strikeData,
    viData,
    strikePriceWiseDataOptions,
    strikePriceData,
  } = props;

  // const [strikePriceData, setOptionChainData] = useState();
  const param = strikePriceWiseDataOptions.parameter;
  // useEffect(() => {
  //   setOptionChainData(optionChainData);
  // }, [optionChainData, strikeArray]);

  // if (!optionChainTable?.Calls || !optionChainTable?.Puts) return <>loading</>;
  let number = 1;
  if (strikePriceWiseDataOptions.index === "NIFTY") {
    number = strikePriceWiseDataOptions.lotOrValue === "value" ? 50 : 1;
  }
  if (strikePriceWiseDataOptions.index === "BANKNIFTY") {
    number = strikePriceWiseDataOptions.lotOrValue === "value" ? 15 : 1;
  }
  if (!strikePriceData) return <>loading...</>;
  // console.log(strikeData.calls[strikePrice]["IV"]);
  return (
    <table
      className="info-table"
      style={{ width: "95%", marginLeft: "1rem", marginRight: "1rem" }}
      data-theme={props.theme}
    >
      <thead>
        <tr>
          <th colSpan={7}>Call</th>{" "}
          <th className="strike-prices">Strike Prices</th>{" "}
          <th colSpan={7}>Put</th>
        </tr>
        <tr>
          {/* call columns */}
          <th>OI</th> <th>COI</th> <th>Volume</th> <th>IV</th>{" "}
          <th>IV Change</th> <th>LTP</th>
          <th>Probable Outcome</th>
          <th className="strike-prices"></th>
          {/* put columns */}
          <th>Probable Outcome</th>
          <th>LTP</th> <th>IV Change</th> <th>IV</th> <th>Volume</th>{" "}
          <th>COI</th> <th>OI</th>
        </tr>
      </thead>
      <tbody>
        {strikeArray.map((strikePrice, index) => {
          // console.log(strikeArray.length / 2 - 0.5);
          // console.log(strikeData.Calls[strikePrice]["IV"]);
          const probableOutcome =
            strikePriceData.Puts[strikePrice]["LTP"] -
            strikePriceData.Calls[strikePrice]["LTP"];
          const ltpChangeCall =
            strikePriceData.Calls[strikePrice][param] -
            strikePriceData.Calls[strikePrice]["Prev_Close"];
          const ltpChangePuts =
            strikePriceData.Calls[strikePrice][param] -
            strikePriceData.Calls[strikePrice]["Prev_Close"];
          let callOutcome = "Bearish";
          let putOutcome = "Bullish";
          if (probableOutcome < 0) {
            callOutcome = "Bullish";
            putOutcome = "Bearish";
          }
          return (
            <>
              {strikePriceData.Calls[strikePrice] &&
              strikePriceData.Puts[strikePrice] ? (
                <tr key={index}>
                  <td>{strikePriceData.Calls[strikePrice]["OI"] / number}</td>
                  <td>
                    {(strikePriceData.Calls[strikePrice]["OI"] -
                      strikePriceData.Calls[strikePrice][
                        "Prev_Open_Int_Close"
                      ]) /
                      number}
                  </td>
                  <td>
                    {strikePriceData.Calls[strikePrice]["Volume"] / number}
                  </td>

                  <td>{strikeData?.Calls?.[strikePrice]?.["IV"]}</td>
                  <td>
                    {(
                      viData?.Calls?.[strikePrice]?.["IV"] -
                      strikeData?.Calls?.[strikePrice]?.["IV"]
                    ).toFixed(4)}
                  </td>
                  <td>{strikePriceData.Calls[strikePrice]["LTP"]}</td>
                  <td>
                    {ltpChangeCall > 0 ? <>{callOutcome}</> : <>Neutral</>}
                  </td>

                  <td className="strike-prices">
                    <b
                      style={
                        index === strikeArray.length / 2 - 0.5
                          ? {
                              color: "rgb(30,64,186)",
                              backgroundColor: "#D0D2DE",
                              padding: "4px",
                            }
                          : {}
                      }
                    >
                      {strikePrice}
                    </b>
                  </td>

                  <td>
                    {" "}
                    {ltpChangePuts > 0 ? <>{putOutcome}</> : <>Neutral</>}
                  </td>
                  <td>{strikePriceData.Puts[strikePrice]["LTP"]}</td>
                  <td>
                    {" "}
                    {(
                      viData?.Puts?.[strikePrice]?.["IV"] -
                      strikeData?.Puts?.[strikePrice]?.["IV"]
                    ).toFixed(4)}
                  </td>
                  <td>{strikeData?.Puts?.[strikePrice]?.["IV"]}</td>

                  <td>
                    {strikePriceData.Puts[strikePrice]["Volume"] / number}
                  </td>
                  <td>
                    {(strikePriceData.Puts[strikePrice]["OI"] -
                      strikePriceData.Puts[strikePrice][
                        "Prev_Open_Int_Close"
                      ]) /
                      number}
                  </td>
                  <td>{strikePriceData.Puts[strikePrice]["OI"] / number}</td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td
                      className="strike-prices"
                      style={
                        index === strikeArray.length / 2 - 0.5
                          ? {
                              color: "rgb(30,64,186)",
                              backgroundColor: "#D0D2DE",
                              padding: "4px",
                            }
                          : {}
                      }
                    >
                      <b
                        style={
                          index === strikeArray.length / 2 - 0.5
                            ? {
                                color: "rgb(30,64,186)",
                                backgroundColor: "#D0D2DE",
                                padding: "4px",
                              }
                            : {}
                        }
                      >
                        {strikePrice}
                      </b>
                    </td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
}

export default StrikePricewiseTable;
