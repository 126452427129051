import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress } from "@mui/material";
import { getDatabase, ref, onValue, off, get } from "firebase/database";
import { db, futureDB } from "../../firebase";
import { app } from "../../firebase";
import {
  roundToNearest50,
  buildStrikePricesArray,
  roundToNearest100,
} from "../../utils/MathAndArrayCreators";
import "../../App.css";
import "./Option.css";
import OptionChain from "./OptionChain";
import OptionChainBelowTable from "./OptionChainBelowTable";
import StraddleAndStrangle from "./StraddleAndStraggle";
import MostActiveOption from "./MostActiveOption";
import BuyingSellinPressure from "./BuyingSellinPressure";
import HighestOi from "./HighestOi";
import StrikePricewiseData from "./StrikePricewiseData";
import OiChanger from "./OiChanger";
import IntradayOutlook from "./IntradayOutlook";
import IntradaySignal from "./IntradaySignal";
import MarketLook from "./MarketLook";
import OrderFlowAnalysis from "./OrderFlowAnalysis";
import OptionAnalysis from "./OptionAnalysis";
const timeMultiplierMap = {
  0: 1,
  0.5: 30,
  1: 60,
  2: 120,
  3: 180,
  4: 240,
  5: 300,
  6: 360,
  7: 420,
  8: 480,
  9: 540,
  10: 600,
  15: 900,
};

export default function Option(props) {
  const [nifty50LTP, setNifty50LTP] = useState();
  const [niftyBankLTP, setNiftyBankLTP] = useState();
  const [loading, setLoading] = useState(false);
  const [optionChainOptions, setOptionChainOptions] = useState({
    index: "nifty50",
    lotOrValue: "lot",
    expiry: 0,
    numStrikePrice: 5,
    time: 0,
    strikePrices: [],
    count: 0,
  });
  const [oiChangerOptions, setOiChangerOptions] = useState({
    index: "NIFTY",
    value: 100000,
    callorput: "Calls",
  });
  const [straddleAndStrangleOptions, setStraddleAndStrangleOptions] = useState({
    index: "nifty50",
    time: "0",
    option: "Straddle",
  });
  const [orderFlowAnalysisOptions, setOrderFlowAnalysisOptions] = useState({
    index: "nifty50",
    numStrikePrice: 5,
    expiry: 0,
    time: 0,
    lotOrValue: "lot",
  });
  const [strikePriceWiseDataOptions, setStrikePriceWiseDataOptions] = useState({
    index: "NIFTY",
    lotOrValue: "lot",
    numStrikePrice: 5,
    parameter: "LTP",
    time: 0.5,
  });
  const [highestOiOptions, setHighestOiOptions] = useState({
    index: "nifty50",
  });
  const [intradaySignalsOptions, setIntradaySignalsOptions] = useState({
    index: "NIFTY",
    lotOrValue: "lot",
    expiry: 0,
    numStrikePrice: 5,
    time: 0,
    count: 0,
  });
  const [marketLookOptions, setMarketLookOptions] = useState({
    call: {
      index: "nifty50",
      time: "0",
    },
    put: {
      index: "nifty50",
      time: "0",
    },
  });
  const [intradayOutlookOptions, setIntradayOutlookOptions] = useState({
    call: {
      index: "nifty50",
      time: "0",
    },
    put: {
      index: "nifty50",
      time: "0",
    },
  });
  const [optionAnalysisOptions, setOptionAnalysisOptions] = useState({
    index: "nifty50",
    lotOrValue: "lot",
    expiry: 0,
    numStrikePrice: 5,
    time: 0,
  });
  const [mostActiveOptionsOptions, setMostActiveOptionsOptions] = useState({
    index: "nifty50",
    time: "0",
    parameter: "OI",
  });
  const [buyingSellingPressureOptions, setBuyingSellingPressureOptions] =
    useState({
      index: "NIFTY",
      time: 0.5,
      numStrikePrice: 5,
      option: "call",
    });

  const [optionTab, setOptionTab] = useState("optionchain");
  const [componentLoading, setComponentLoading] = useState(true);
  const [expiryDates, setExpiryDates] = useState([]);

  const [optionChainData, setOptionChainData] = useState({
    calls: [],
    puts: [],
  });
  const [oiChangerData, setOiChangerData] = useState({
    oneminute: {
      nifty50: {
        strikePrices: [],
        calls: [],
        puts: [],
      },
      banknifty: {
        strikePrices: [],
        calls: [],
        puts: [],
      },
    },
    fiveminute: {
      nifty50: {
        strikePrices: [],
        calls: [],
        puts: [],
      },
      banknifty: {
        strikePrices: [],
        calls: [],
        puts: [],
      },
    },
  });
  const [firstVisitOptionTime, setFirstVisitOptionTime] = useState(new Date());
  const [dateState, setDateState] = useState(false);
  const [time, setTime] = useState(0);
  const [expiry, setExpiry] = useState("");
  const [strikeArray, setStrikeArray] = useState([]);
  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();

      // Set the time to the beginning of the day for both dates
      const currentDateStartOfDay = new Date(currentDate);
      currentDateStartOfDay.setHours(0, 0, 0, 0);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const fullYear = yy >= 50 ? 1900 + yy : 2000 + yy;
        const expiryDate = new Date(fullYear, mm - 1, dd); // Adjust year to 4-digit format

        // Include dates on or after the current date (ignoring time)
        return expiryDate >= currentDateStartOfDay;
      });

      setExpiryDates(expiryArray);

      setOptionChainOptions({
        ...optionChainOptions,
        expiry: expiryArray[0],
      });
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);
  const [indexWiseExpiry, setIndexWiseExpiry] = useState({
    nifty: [],
    banknifty: [],
  });
  useEffect(() => {
    console.log("useEffect runn");
    let niftyDate = [];
    let bankNiftyDate = [];
    for (let i = 0; i < expiryDates.length; i++) {
      const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
      onValue(dbRef, (snapshot) => {
        const temp = snapshot.val();
        if (temp) {
          if (temp.BANKNIFTY) {
            bankNiftyDate.push(expiryDates[i]);
            console.log(expiryDates[i]);
          }
          if (temp.NIFTY) {
            niftyDate.push(expiryDates[i]);
            console.log(expiryDates[i]);
          }
        }
        console.log(niftyDate, bankNiftyDate);
      });
      setIndexWiseExpiry({
        ...indexWiseExpiry,
        nifty: niftyDate,
        banknifty: bankNiftyDate,
      });
    }
    niftyDate = [];
    bankNiftyDate = [];
    console.log("reapeat");
  }, [expiryDates]);
  // console.log(indexWiseExpiry);

  useEffect(() => {
    const date =
      optionChainOptions.index === "nifty50"
        ? indexWiseExpiry.nifty[0]
        : indexWiseExpiry.banknifty[0];
    setExpiry(date);
  }, [optionChainOptions.index, indexWiseExpiry]);

  useEffect(() => {
    if (time > 0) return;

    const index =
      optionChainOptions["index"] === "nifty50" ? "NIFTY 50" : "NIFTY BANK";
    const dbRef =
      optionChainOptions["index"] === "nifty50" ? "NIFTY" : "BANKNIFTY";

    const expiryToFetch = optionChainOptions["expiry"];
    const numStrikePrice = optionChainOptions["numStrikePrice"];

    const rtdb = getDatabase(app);

    const niftyLTPRef = ref(rtdb, `tick/Index/${index}/LTP`);

    const optionChainCallRef = ref(
      rtdb,
      `tick/Option/${expiry}/${dbRef}/Calls`
    );
    const optionChainPutRef = ref(rtdb, `tick/Option/${expiry}/${dbRef}/Puts`);

    const fetchOptionChainData = async () => {
      try {
        const callsSnapshot = await get(optionChainCallRef);
        const putsSnapshot = await get(optionChainPutRef);

        const callsData = callsSnapshot.val();
        const putsData = putsSnapshot.val();
        // console.log(callsData);
        if (callsData && putsData) {
          setOptionChainData({ calls: callsData, puts: putsData });
        }
      } catch (error) {
        // Handle the error if necessary
        console.error("Error fetching option chain data:", error);
      }
    };

    fetchOptionChainData();

    return () => {
      off(optionChainCallRef, "value");
      off(optionChainPutRef, "value");
      off(niftyLTPRef, "value");
    };
  }, [expiry, optionChainOptions, time]);

  useEffect(() => {
    console.log(optionChainOptions["numStrikePrice"]);
    const index =
      optionChainOptions["index"] === "nifty50" ? "NIFTY 50" : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          optionChainOptions["numStrikePrice"] || 5,
          optionChainOptions["index"] === "nifty50" ? 50 : 100
        );

        strikePriceArray.sort();
      }
    });
    setStrikeArray(strikePriceArray);
  }, [optionChainOptions["index"], optionChainOptions["numStrikePrice"]]);

  // console.log(strikeArray);
  // console.log(optionChainData);
  //------Live Option Code here---------------
  useEffect(() => {
    console.log("local,storage first time");
    // Save the user's first visit time in a state variable
    const savedTime = localStorage.getItem("firstVisitOptionTime");
    const savedData = localStorage.getItem("firstVisitOptionData");
    const firstVisitData = optionChainData;
    if (savedData) {
      setOptionChainData(JSON.parse(savedData));
    } else {
      localStorage.setItem(
        "firstVisitOptionData",
        JSON.stringify(firstVisitData)
      );
    }
    if (savedTime) {
      setFirstVisitOptionTime(new Date(savedTime));
    } else {
      localStorage.setItem("firstVisitOptionTime", new Date());
    }
  }, [dateState, time]);

  useEffect(() => {
    const optionTime = time * 60;
    if (time === 0) return;
    const interval = setInterval(() => {
      const currentTime = new Date();
      const timeDifference = (currentTime - firstVisitOptionTime) / 1000; // Calculate the time difference in seconds

      if (timeDifference >= optionTime) {
        // Run your function here
        console.log(
          `Function is running because the time difference is >=${optionTime} seconds.`
        );
        console.log();
        setDateState(!dateState);
      }
    }, 1000 * optionTime); // Run the function every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {componentLoading && (
        <div className="loader-overlay">
          <CircularProgress />
        </div>
      )}
      <div>
        <Tabs
          value={optionTab}
          onChange={(e, newValue) => {
            setOptionTab(newValue);
          }}
          data-theme={props.theme}
          variant={window.screen.width > 1280 ? "fullWidth" : "scrollable"}
          scrollButtons="auto"
          sx={{
            "& .MuiTabScrollButton-root": {
              color: props.theme === "dark" ? "white" : "black",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: props.theme === "dark" ? "white" : "blue",
            },
          }}
        >
          <Tab
            label="Option Chain"
            className="muiTab"
            id="simple-tab-7"
            value="optionchain"
          />
          <Tab
            label="OI Changer"
            className="muiTab"
            id="simple-tab-1"
            value="oichanger"
          />
          <Tab
            label="Straddle and Straggle"
            className="muiTab"
            id="simple-tab-2"
            value="straddleandstraggle"
          />
          <Tab
            label="Order Flow Analysis"
            className="muiTab"
            id="simple-tab-3"
            value="orderflowanalysis"
          />
          <Tab
            label="Strike Price Wise Data"
            className="muiTab"
            id="simple-tab-4"
            value="strikepricewisedata"
          />
          <Tab
            label="Highest OI"
            className="muiTab"
            id="simple-tab-5"
            value="highestoi"
          />
          <Tab
            label="Intraday Signals"
            className="muiTab"
            id="simple-tab-6"
            value="intradaysignals"
          />
          <Tab
            label="Market Look"
            className="muiTab"
            id="simple-tab-8"
            value="marketlook"
          />
          <Tab
            label="Intraday Outlook"
            className="muiTab"
            id="simple-tab-9"
            value="intradayoutlook"
          />
          <Tab
            label="Option Analysis"
            className="muiTab"
            id="simple-tab-10"
            value="optionanalysis"
          />
          <Tab
            label="Most Active Options"
            className="muiTab"
            id="simple-tab-11"
            value="mostactiveoptions"
          />
          <Tab
            label="Buying Selling Pressure"
            className="muiTab"
            id="simple-tab-12"
            value="buyingsellingpressure"
          />
        </Tabs>
      </div>
      <div
        className="option-subpage any-subpage"
        sx={{
          color: props.theme === "dark" ? "white" : "black",

          backgroundColor: props.theme === "dark" ? "white" : "blue",
        }}
        data-theme={props.theme}
      >
        {/* OPTION CHAIN */}
        {optionTab === "optionchain" && (
          <OptionChain
            optionChainOptions={optionChainOptions}
            setOptionChainOptions={setOptionChainOptions}
            optionChainData={optionChainData}
            expiryDates={expiryDates}
            theme={props.theme}
            indexWiseExpiry={indexWiseExpiry}
            setExpiry={setExpiry}
            strikeArray={strikeArray}
            setTime={setTime}
          />
          // <OptionChainBelowTable
          //   optionChainData={optionChainData}
          //   optionChainOptions={optionChainOptions}
          // />
        )}
        {optionTab === "oichanger" && (
          <OiChanger
            theme={props.theme}
            oiChangerOptions={oiChangerOptions}
            setOiChangerOptions={setOiChangerOptions}
            oiChangerData={oiChangerData}
            expiryDates={expiryDates}
            strikeArray={strikeArray}
          />
        )}
        {/* STRADDLE AND STRAGGLE */}
        {optionTab === "straddleandstraggle" && (
          <>
            <StraddleAndStrangle
              straddleAndStrangleOptions={straddleAndStrangleOptions}
              optionChainData={optionChainData}
              optionChainOptions={optionChainOptions}
              componentLoading={componentLoading}
              setStraddleAndStrangleOptions={setStraddleAndStrangleOptions}
            />
          </>
        )}
        {/* ORDER FLOW ANALYSIS */}
        {optionTab === "orderflowanalysis" && (
          <>
            <OrderFlowAnalysis />
          </>
        )}
        {/* STRIKE PRICE WISE DATA */}
        {optionTab === "strikepricewisedata" && (
          <StrikePricewiseData
            setStrikePriceWiseDataOptions={setStrikePriceWiseDataOptions}
            strikePriceWiseDataOptions={strikePriceWiseDataOptions}
            optionChainOptions={optionChainOptions}
            optionChainData={optionChainData}
          />
        )}
        {/* HIGHEST OI */}
        {optionTab === "highestoi" && (
          <>
            <div className="option-highestoi">
              <div className="subpage-dropdowns-container">
                <div className="dropdown-container">
                  <select
                    name="option-highestoi-index1"
                    id="option-highestoi-index1"
                    className="subpage-dropdown"
                    onChange={(e) => {
                      setHighestOiOptions({
                        ...highestOiOptions,
                        index: e.target.value,
                      });
                    }}
                  >
                    <option value="nifty50">Nifty 50</option>
                    <option value="niftybank">Nifty Bank</option>
                  </select>
                  <p>Select Indices</p>
                </div>
              </div>
            </div>
            <HighestOi
              setHighestOiOptions={setHighestOiOptions}
              highestOiOptions={highestOiOptions}
              expiryDates={expiryDates}
            />
          </>
        )}
        {/* INTRADAY SIGNALS */}
        {optionTab === "intradaysignals" && (
          <>
            <IntradaySignal
              intradaySignalsOptions={intradaySignalsOptions}
              setIntradaySignalsOptions={setIntradaySignalsOptions}
              expiryDates={expiryDates}
            />
          </>
        )}

        {/* MARKET LOOK */}
        {optionTab === "marketlook" && (
          <>
            <MarketLook />
          </>
        )}
        {/* INTRADAY OUTLOOK */}
        {optionTab === "intradayoutlook" && (
          <>
            <IntradayOutlook
              setHighestOiOptions={setHighestOiOptions}
              highestOiOptions={highestOiOptions}
              expiryDates={expiryDates}
            />
          </>
        )}
        {/* OPTION ANALYSIS */}
        {optionTab === "optionanalysis" && (
          <>
            <OptionAnalysis
              optionChainOptions={optionChainOptions}
              setOptionChainOptions={setOptionChainOptions}
              expiryDates={expiryDates}
              optionChainData={optionChainData}
            />
          </>
        )}
        {/* MOST ACTIVE-C OPTIONS */}
        {optionTab === "mostactiveoptions" && (
          <>
            <MostActiveOption
              optionChainData={optionChainData}
              optionChainOptions={optionChainOptions}
              mostActiveOptionsOptions={mostActiveOptionsOptions}
              setMostActiveOptionsOptions={setMostActiveOptionsOptions}
              setOptionChainOptions={setOptionChainOptions}
              indexWiseExpiry={indexWiseExpiry}
              strikeArray={strikeArray}
              setExpiry={setExpiry}
            />
          </>
        )}
        {/* BUYING SELLING PRESSURE */}
        {optionTab === "buyingsellingpressure" && (
          <>
            <BuyingSellinPressure
              setBuyingSellingPressureOptions={setBuyingSellingPressureOptions}
              buyingSellingPressureOptions={buyingSellingPressureOptions}
              optionChainData={optionChainData}
              optionChainOptions={optionChainOptions}
            />
          </>
        )}
        {/* ALL TABS DIV CLOSE */}
      </div>
    </div>
  );
}

// const OIChanger = (props) => {};
