import React, { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { getDatabase, off, get } from "firebase/database";
import { app } from "../../firebase";
import { futureDB } from "../../firebase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StockDetails from "./StockDetails";
import PortfolioOption from "./PortfolioOption";
import PortfolioStock from "./PortfolioStock";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";
import PortfolioFuture from "./PortfolioFuture";
import HoldingAndPosition from "./HoldingAndPosition";
import PortfolioHolding from "./PortfolioHolding";
import PortfolioPosition from "./PortfolioPosition";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function getMonth() {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let name = month[d.getMonth()];
  return name;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  borderRadius: "6px",
  height: "100%",
};

function Portfolio(props) {
  const { searchString, strikePrice, stockData, searchHandler } = props;
  const [optionSearchString, setOptionSearchString] = useState("");

  const [portfolioTab, setPortfolioTab] = useState("stock");
  const [portfolioData, setPortfolioData] = useState([]);
  const [strikeArray, setStrikeArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);
  const [expiryDates, setExpiryDates] = useState([]);
  const [indices, setIndices] = useState();
  const [futureData, setFutureData] = useState();
  const [futureTitle, setFutureTitle] = useState();
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalHolding, setTotalHolding] = useState(0);

  const [totalPositionReturn, setTotalPositionReturn] = useState(0);
  const [totalPositionInvestment, setTotalPositionInvestment] = useState(0);
  const [totalPositionHolding, setTotalPositionHolding] = useState(0);

  const [holdingTitle, setHoldingTitle] = useState([]);
  const [futureExpiry, setFutureExpiry] = useState();
  const [positionTitle, setPositionTitle] = useState([]);
  const [expiry, setExpiry] = useState();
  const [positionData, setPositionData] = useState({});
  const [holdingData, setHoldingData] = useState({});
  const [portfolioOptions, setPortfolioOptions] = useState({
    index: "NIFTY",

    count: 0,
    date: 0,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const optionSearchHandler = (e) => {
    setOptionSearchString(e.target.value);
  };

  useEffect(() => {
    const holdingitem = window.localStorage.getItem("holdingData");
    const holdingTitle = window.localStorage.getItem("holdingTitle");
    const totalReturn = window.localStorage.getItem("totalReturn");
    const totalInvestment = window.localStorage.getItem("totalInvestment");
    const totalHolding = window.localStorage.getItem("totalHolding");
    if (holdingitem != undefined && holdingitem !== null) {
      setHoldingData(JSON.parse(holdingitem));
    }
    if (holdingTitle != undefined && holdingTitle !== null) {
      setHoldingTitle(JSON.parse(holdingTitle));
    }
    if (totalReturn != undefined && totalReturn !== null) {
      setTotalReturn(JSON.parse(totalReturn));
    }
    if (totalInvestment != undefined && totalInvestment !== null) {
      setTotalInvestment(JSON.parse(totalInvestment));
    }
    if (totalHolding != undefined && totalHolding !== null) {
      setTotalHolding(JSON.parse(totalHolding));
    }
  }, []);

  useEffect(() => {
    const holdingitem = window.localStorage.getItem("positionData");
    const holdingTitle = window.localStorage.getItem("positionTitle");
    const totalReturn = window.localStorage.getItem("totalPositionReturn");

    const totalHolding = window.localStorage.getItem("totalPositionHolding");
    if (holdingitem != undefined && holdingitem !== null) {
      setPositionData(JSON.parse(holdingitem));
    }
    if (holdingTitle != undefined && holdingTitle !== null) {
      setPositionTitle(JSON.parse(holdingTitle));
    }
    if (totalReturn != undefined && totalReturn !== null) {
      setTotalPositionReturn(JSON.parse(totalReturn));
    }

    if (totalHolding != undefined && totalHolding !== null) {
      setTotalPositionHolding(JSON.parse(totalHolding));
    }
  }, []);

  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();

      const timeZoneOffset = 330; // 5 hours and 30 minutes in minutes
      currentDate.setMinutes(currentDate.getMinutes() + timeZoneOffset);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const date = new Date(2000 + yy, mm - 1, dd); // Adjust year to 4-digit format

        return date >= currentDate;
      });

      setExpiryDates(expiryArray);
      // setPortfolioOptions({
      //   ...portfolioOptions,
      //   date: expiryArray[0],
      // });
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);
  const [indexWiseExpiry, setIndexWiseExpiry] = useState({
    nifty: [],
    banknifty: [],
  });
  useEffect(() => {
    console.log("useEffect runn");
    let niftyDate = [];
    let bankNiftyDate = [];
    for (let i = 0; i < expiryDates.length; i++) {
      const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
      onValue(dbRef, (snapshot) => {
        const temp = snapshot.val();
        if (temp) {
          if (temp.BANKNIFTY) {
            bankNiftyDate.push(expiryDates[i]);
            console.log(expiryDates[i]);
          }
          if (temp.NIFTY) {
            niftyDate.push(expiryDates[i]);
            console.log(expiryDates[i]);
          }
        }
        console.log(niftyDate, bankNiftyDate);
      });
      setIndexWiseExpiry({
        ...indexWiseExpiry,
        nifty: niftyDate,
        banknifty: bankNiftyDate,
      });
      // setExpiry(niftyDate[0]);
    }

    niftyDate = [];
    bankNiftyDate = [];
    console.log("reapeat");
    const id = setTimeout(() => {
      setLoading(true);
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [loading]);

  useEffect(() => {
    const date =
      portfolioOptions.index === "NIFTY"
        ? indexWiseExpiry.nifty[0]
        : indexWiseExpiry.banknifty[0];
    setExpiry(date);
  }, [portfolioOptions, indexWiseExpiry]);
  // console.log(expiry, indexWiseExpiry);

  useEffect(() => {
    // setLoading(false);
    console.log("runned option portfolio");
    function fetchData() {
      let currentcount = expiry;
      let currentindex = portfolioOptions["index"];
      console.log(`tick/Option/${currentcount}/${currentindex}`);
      let index = "";
      let temp = [];
      const dbRef = ref(
        futureDB,
        `tick/Option/${currentcount}/${currentindex}`
      );
      onValue(dbRef, (snapshot) => {
        // let option = portfolioOptions["optionChain"];
        console.log(snapshot.val());
        temp = snapshot.val();
        setPortfolioData(temp);
        setIndices(index);

        console.log(temp);
        // setTitleList(strikeNumber);
      });
    }
    fetchData();
    // const timeToken = setTimeout(fetchData, 1000);

    // return () => {
    //   clearTimeout(timeToken);
    // };
  }, [portfolioOptions, indexWiseExpiry, expiry]);

  useEffect(() => {
    const index =
      portfolioOptions["index"] === "NIFTY" ? "NIFTY 50" : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          5,
          portfolioOptions["index"] === "NIFTY" ? 50 : 100
        );

        strikePriceArray.sort();
        // setportfolioOptions({
        //   ...portfolioOptions,
        //   strikPrice: strikePriceArray[0],
        // });
      }
    });
    setStrikeArray(strikePriceArray);
  }, [portfolioOptions["index"]]);
  // console.log(strikeArray);
  //Future DATA expiry UseEffect---------------
  useEffect(() => {
    try {
      setComponentLoading(true);
      const rtdb = getDatabase(app);
      const futureRef = ref(rtdb, `tick/Future`);

      var fetchIndicesData = async () => {
        const niftysnap = await get(futureRef);
        const niftyData = niftysnap.val();
        const expiry = Object.keys(niftyData);
        const rbt = expiry[0];
        setFutureExpiry(rbt);
      };
      fetchIndicesData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  //-----------Future DATA UseEffect---------------
  useEffect(() => {
    const rtdb = getDatabase(app);
    const futureRef = ref(rtdb, `tick/Future/${futureExpiry}`);
    try {
      var fetchIndicesData = async () => {
        const niftysnap = await get(futureRef);
        const niftyData = niftysnap.val();

        const title = [];

        for (const key in niftyData) {
          title.push(key);
          //   tempArray.push(tempfutureAnalysis[haris]);
        }

        setFutureData(niftyData);
        setFutureTitle(title);
      };
    } catch (error) {
      console.log("error!!!!!!");
    }

    fetchIndicesData();
  }, [futureExpiry]);

  const onSubmitHandle = (strike, price, value) => {
    console.log(stockData[strike], price, value);
    const investment = price * value;
    let postioninvestment;
    let positionQuantity;
    let avgPrice;

    if (holdingData[strike]) {
      postioninvestment = holdingData[strike].investment + investment;
      positionQuantity = holdingData[strike].quantity + value;
      avgPrice = postioninvestment / positionQuantity;
    } else {
      postioninvestment = investment;
      positionQuantity = value;
      avgPrice = postioninvestment / positionQuantity;
    }
    console.log(postioninvestment, positionQuantity, avgPrice);
    setHoldingData({
      ...holdingData,
      [strike]: {
        investment: postioninvestment,
        avgPrice: avgPrice,
        quantity: positionQuantity,
        currentPrice: stockData[strike].LTP,
      },
    });

    const currrentValue = stockData[strike].LTP * value;
    const holdingValue = totalHolding + currrentValue;
    const investreturn = currrentValue - investment;
    const temp = holdingTitle;
    const tinvestment = totalInvestment + investment;
    console.log(currrentValue, investment, investreturn);
    if (temp.indexOf(strike) === -1) {
      temp.push(strike);
    }

    setHoldingTitle(temp);
    setTotalHolding(holdingValue);
    setTotalReturn(investreturn);
    setTotalInvestment(tinvestment);

    const item = window.localStorage.setItem(
      "holdingData",
      JSON.stringify({
        ...holdingData,
        [strike]: {
          investment: postioninvestment,
          avgPrice: avgPrice,
          quantity: positionQuantity,
          currentPrice: stockData[strike].LTP,
        },
      })
    );
    window.localStorage.setItem("holdingTitle", JSON.stringify(holdingTitle));
    window.localStorage.setItem("totalHolding", JSON.stringify(holdingValue));
    window.localStorage.setItem("totalReturn", JSON.stringify(investreturn));
    window.localStorage.setItem("totalInvestment", JSON.stringify(tinvestment));
  };
  // useEffect(() => {
  //   const item = window.localStorage.setItem(
  //     "holdingData",
  //     JSON.stringify(holdingData)
  //   );
  //   window.localStorage.setItem("holdingTitle", JSON.stringify(holdingTitle));
  //   window.localStorage.setItem("totalHolding", JSON.stringify(totalHolding));
  //   window.localStorage.setItem("totalReturn", JSON.stringify(totalReturn));
  //   window.localStorage.setItem(
  //     "totalInvestment",
  //     JSON.stringify(totalInvestment)
  //   );
  // }, [holdingData]);

  // console.log(JSON.parse(localStorage.getItem("holdingData")));

  //------------------stock Sell Handle-----------------

  const onSubmitStockSellHandle = (symbol, price, value) => {
    if (!holdingData[symbol]) return;
    let positionQuantity;
    positionQuantity = holdingData[symbol].quantity - value;
    if (positionQuantity < 0)
      return alert("You dont have enough stock to sell");

    console.log(symbol, price, value);
    console.log(futureData[symbol]);
    const currrentValue = stockData[symbol]?.LTP * value;
    const investment = price * value;

    const investreturn = currrentValue - investment;
    const temp = holdingTitle;

    const returnInvest = totalPositionReturn - investreturn;

    setTotalHolding(totalPositionHolding - currrentValue);
    setTotalReturn(returnInvest);
    setTotalInvestment(totalInvestment + investment);
    let postioninvestment;

    let avgPrice;
    if (holdingData[symbol]) {
      postioninvestment = holdingData[symbol].investment - investment;

      avgPrice = postioninvestment / positionQuantity;
    }
    //  else {
    //   postioninvestment = investment;
    //   positionQuantity = value;
    //   avgPrice = postioninvestment / positionQuantity;
    // }

    setHoldingData({
      ...holdingData,
      [symbol]: {
        investment: postioninvestment,
        avgPrice: avgPrice,
        quantity: positionQuantity,
        currentPrice: stockData[symbol].LTP,
      },
    });
    if (positionQuantity == 0) {
      delete holdingData[symbol];
      temp.splice(temp.indexOf(symbol), 1);
    }
    setHoldingTitle(temp);
    window.localStorage.setItem(
      "holdingData",
      JSON.stringify({ ...holdingData })
    );
    window.localStorage.setItem("totalHolding", JSON.stringify(totalHolding));
    window.localStorage.setItem("totalReturn", JSON.stringify(totalReturn));
    window.localStorage.setItem(
      "totalInvestment",
      JSON.stringify(totalInvestment)
    );
    window.localStorage.setItem("holdingTitle", JSON.stringify(holdingTitle));
  };
  //-------------option Buy Handle---------------------------

  const onSubmitOptionHandle = (strike, price, value) => {
    console.log("option clicked");
    const currrentValue = portfolioData.Calls[strike]?.LTP * value;
    const investment = price * value;

    const investreturn = currrentValue - investment;
    const temp = positionTitle;
    if (temp.indexOf(strike) === -1) {
      temp.push(strike);
    }
    setPositionTitle(temp);
    setTotalPositionHolding(currrentValue);
    setTotalPositionReturn(investreturn);
    setTotalPositionInvestment(totalInvestment + investment);
    let postioninvestment;
    let positionQuantity;
    let avgPrice;
    if (positionData[strike]) {
      postioninvestment = positionData[strike].investment + investment;
      positionQuantity = positionData[strike].quantity + value;
      avgPrice = postioninvestment / positionQuantity;
    } else {
      postioninvestment = investment;
      positionQuantity = value;
      avgPrice = postioninvestment / positionQuantity;
    }

    setPositionData({
      ...positionData,
      [strike]: {
        investment: postioninvestment,
        avgPrice: avgPrice,
        quantity: positionQuantity,
        currentPrice: portfolioData.Calls[strike].LTP,
      },
    });

    const item = window.localStorage.setItem(
      "positionData",
      JSON.stringify({
        ...positionData,
        [strike]: {
          investment: postioninvestment,
          avgPrice: avgPrice,
          quantity: positionQuantity,
          currentPrice: portfolioData.Calls[strike].LTP,
        },
      })
    );
    window.localStorage.setItem("positionTitle", JSON.stringify(positionTitle));
    window.localStorage.setItem(
      "totalPositionHolding",
      JSON.stringify(currrentValue)
    );
    window.localStorage.setItem(
      "totalPositionReturn",
      JSON.stringify(investreturn)
    );
  };

  //-------------option sell Handle---------------
  //------------------------------------------------------------------

  const onSubmitOptionSellHandle = (symbol, price, value) => {
    console.log(symbol, price, value);
    console.log(futureData[symbol]);
    const currrentValue = portfolioData.Calls[symbol]?.LTP * value * -1;
    const investment = price * value * -1;

    const investreturn = currrentValue - investment;
    const temp = positionTitle;
    if (temp.indexOf(symbol) === -1) {
      temp.push(symbol);
    }
    const returnInvest = totalPositionReturn - investreturn;
    setPositionTitle(temp);
    setTotalPositionHolding(totalPositionHolding - currrentValue);
    setTotalPositionReturn(returnInvest);
    setTotalPositionInvestment(totalInvestment + investment);
    let postioninvestment;
    let positionQuantity;
    let avgPrice;
    if (positionData[symbol]) {
      postioninvestment = positionData[symbol].investment - investment;
      positionQuantity = positionData[symbol].quantity - value;
      avgPrice = postioninvestment / positionQuantity;
    } else {
      postioninvestment = investment;
      positionQuantity = value * -1;
      avgPrice = postioninvestment / positionQuantity;
    }
    setPositionData({
      ...positionData,
      [symbol]: {
        investment: postioninvestment,
        avgPrice: avgPrice,
        quantity: positionQuantity,
        currentPrice: portfolioData.Calls[symbol].LTP,
      },
    });
    function localset() {
      const item = window.localStorage.setItem(
        "positionData",
        JSON.stringify({
          ...positionData,
          [symbol]: {
            investment: postioninvestment,
            avgPrice: avgPrice,
            quantity: positionQuantity,
            currentPrice: portfolioData.Calls[symbol].LTP,
          },
        })
      );
      window.localStorage.setItem(
        "positionTitle",
        JSON.stringify(positionTitle)
      );
      window.localStorage.setItem(
        "totalPositionHolding",
        JSON.stringify(currrentValue)
      );
      window.localStorage.setItem(
        "totalPositionReturn",
        JSON.stringify(investreturn)
      );
    }
    localset();
  };

  // const onSubmitFutureHandle = (symbol, price, value) => {
  //   console.log(futureData[symbol]);
  //   const currrentValue = futureData[symbol]?.LTP * value;
  //   const investment = price * value;

  //   const investreturn = currrentValue - investment;
  //   const temp = positionTitle;
  //   if (temp.indexOf(symbol) === -1) {
  //     temp.push(symbol);
  //   }
  //   setPositionTitle(temp);
  //   setTotalPositionHolding(currrentValue);
  //   setTotalPositionReturn(investreturn);
  //   setTotalPositionInvestment(totalInvestment + investment);
  //   let postioninvestment;
  //   let positionQuantity;
  //   let avgPrice;
  //   if (positionData[symbol]) {
  //     postioninvestment = positionData[symbol].investment + investment;
  //     positionQuantity = positionData[symbol].quantity + value;
  //     avgPrice = postioninvestment / positionQuantity;
  //   } else {
  //     postioninvestment = investment;
  //     positionQuantity = value;
  //     avgPrice = postioninvestment / positionQuantity;
  //   }

  //   setPositionData({
  //     ...positionData,
  //     [symbol]: {
  //       investment: postioninvestment,
  //       avgPrice: avgPrice,
  //       quantity: positionQuantity,
  //       currentPrice: futureData[symbol].LTP,
  //     },
  //   });

  // };

  // const onSubmitFutureSellHandle = (symbol, price, value) => {
  //   console.log(symbol, price, value);
  //   console.log(futureData[symbol]);
  //   const currrentValue = futureData[symbol]?.LTP * value;
  //   const investment = price * value;

  //   const investreturn = currrentValue - investment;
  //   const temp = positionTitle;
  //   if (temp.indexOf(symbol) === -1) {
  //     temp.push(symbol);
  //   }
  //   const returnInvest = totalPositionReturn - investreturn;
  //   setPositionTitle(temp);
  //   setTotalPositionHolding(totalPositionHolding - currrentValue);
  //   setTotalPositionReturn(returnInvest);
  //   setTotalPositionInvestment(totalInvestment + investment);
  //   let postioninvestment;
  //   let positionQuantity;
  //   let avgPrice;
  //   if (positionData[symbol]) {
  //     postioninvestment = positionData[symbol].investment - investment;
  //     positionQuantity = positionData[symbol].quantity - value;
  //     avgPrice = postioninvestment / positionQuantity;
  //   }
  //   //  else {
  //   //   postioninvestment = investment;
  //   //   positionQuantity = value;
  //   //   avgPrice = postioninvestment / positionQuantity;
  //   // }

  //   setPositionData({
  //     ...positionData,
  //     [symbol]: {
  //       investment: postioninvestment,
  //       avgPrice: avgPrice,
  //       quantity: positionQuantity,
  //       currentPrice: futureData[symbol].LTP,
  //     },
  //   });
  // };

  console.log(holdingData);
  // console.log(positionData);
  // console.log(futureData);
  return (
    <>
      {Object.keys(holdingData).length === 0 &&
      Object.keys(positionData).length === 0 ? (
        <></>
      ) : (
        <>
          <HoldingAndPosition
            totalHolding={totalHolding}
            totalReturn={totalReturn}
            totalInvestment={totalInvestment}
            totalPositionHolding={totalPositionHolding}
            totalPositionReturn={totalPositionReturn}
            totalPositionInvestment={totalPositionInvestment}
          />
          <PortfolioHolding
            holdingData={holdingData}
            holdingTitle={holdingTitle}
          />
          <PortfolioPosition
            positionTitle={positionTitle}
            positionData={positionData}
          />
        </>
      )}

      <div style={{ marginBottom: "8px", padding: "1rem" }}>
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
          }}
          onClick={handleOpen}
        >
          Add Portfolio
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
          <Box sx={style}>
            <div>
              <Tabs
                value={portfolioTab}
                onChange={(e, newValue) => {
                  setPortfolioTab(newValue);
                }}
                aria-label=""
                data-theme={props.theme}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  "& .MuiTabScrollButton-root": {
                    color: props.theme === "dark" ? "white" : "black",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: props.theme === "dark" ? "white" : "blue",
                  },
                }}
              >
                <Tab
                  label="Stock"
                  className="muiTab"
                  id="simple-tab-1"
                  value="stock"
                />
                <Tab
                  label="Option"
                  className="muiTab"
                  id="simple-tab-2"
                  value="option"
                />
                {/* <Tab
                  label="Future"
                  className="muiTab"
                  id="simple-tab-3"
                  value="future"
                /> */}
              </Tabs>
            </div>

            {portfolioTab === "stock" && (
              <PortfolioStock
                stockData={stockData}
                strikePrice={strikePrice}
                searchString={searchString}
                searchHandler={searchHandler}
                onSubmitHandle={onSubmitHandle}
                holdingData={holdingData}
                onSubmitStockSellHandle={onSubmitStockSellHandle}
              />
            )}
            {portfolioTab === "option" && (
              <PortfolioOption
                setPortfolioOptions={setPortfolioOptions}
                portfolioOptions={portfolioOptions}
                expiry={expiry}
                setExpiry={setExpiry}
                portfolioData={portfolioData}
                strikeArray={strikeArray}
                onSubmitOptionHandle={onSubmitOptionHandle}
                onSubmitOptionSellHandle={onSubmitOptionSellHandle}
                indexWiseExpiry={indexWiseExpiry}
                optionSearchString={optionSearchString}
                optionSearchHandler={optionSearchHandler}
                // index={index}
              />
            )}
            {/* {portfolioTab === "future" && (
              <PortfolioFuture
                futureTitle={futureTitle}
                futureData={futureData}
                onSubmitOptionHandle={onSubmitFutureHandle}
                onSubmitFutureSellHandle={onSubmitFutureSellHandle}
                // expiryDates={expiryDates}
                // portfolioData={portfolioData}
                // strikeArray={strikeArray}
                // // index={index}
              />
            )} */}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Portfolio;
