import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import style from "./DownTable.module.css";
function DownTable({
  downFutureList15,
  downFutureList30,
  downFutureList45,
  downFutureList60,
}) {
  return (
    <div style={{ backgroundColor: "#F9D6DB" }} className={style.container}>
      <div className={`${style.items} ${style.item1}`}>
        <svg
          fill="#FC7F7F"
          width="32px"
          height="32px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z" />
        </svg>
      </div>
      <div className={`${style.items} ${style.item2} ${style.columnItem}`}>
        <div className={style.updatedtime}>15 minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          <ul>
            {downFutureList15.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item3}`}></div>
      <div className={`${style.items} ${style.item4} ${style.columnItem}`}>
        <div className={style.updatedtime}>30 Minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          <ul>
            {downFutureList30.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item5}`}></div>
      <div className={`${style.items} ${style.item6} ${style.columnItem}`}>
        <div className={style.updatedtime}>45 Minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          <ul>
            {downFutureList45.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item7}`}></div>
      <div className={`${style.items} ${style.item8} ${style.columnItem}`}>
        <div className={style.updatedtime}>60 Minutes</div>
        <div className={style.divider} />
        <div className={style.stocklisting}>
          <ul>
            {downFutureList60.map((data, i) => {
              if (i >= 5) return;
              return (
                <>
                  <li>{data.Symbol.replace("23OCT", " ")}</li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`${style.items} ${style.item7}`}></div>
    </div>
  );
}

export default DownTable;
