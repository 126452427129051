import React, { useEffect, useState } from "react";
import StrikePricewiseTable from "./StrikePricewiseTable";

import { db, futureDB, app } from "../../firebase";
import { onValue, ref, off, getDatabase } from "firebase/database";
import { buildStrikePricesArray } from "../../utils/MathAndArrayCreators";

function StrikePricewiseData(props) {
  const {
    setStrikePriceWiseDataOptions,
    strikePriceWiseDataOptions,
    optionChainData,
    optionChainOptions,
  } = props;
  const [strikeData, setStrikeData] = useState([]);
  const [firstVisitTime, setFirstVisitTime] = useState(new Date());
  const [dateState, setDateState] = useState(false);
  const [viData, setViData] = useState([]);
  const [time, setTime] = useState(0.5);
  const [strikePriceData, setStrikePriceData] = useState();
  //option state--------------------
  const [strikeArray, setStrikeArray] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [expiry, setExpiry] = useState();
  const [expiryDates, setExpiryDates] = useState([]);
  const [indexWiseExpiry, setIndexWiseExpiry] = useState({
    nifty: [],
    banknifty: [],
  });

  useEffect(() => {
    let tempData = {};
    let downData = [];
    const index =
      strikePriceWiseDataOptions["index"] === "NIFTY" ? "NIFTY" : "BANKNIFTY";
    // const expiry = optionChainOptions["expiry"];
    console.log(
      `/greeks/Option/${expiry}/${strikePriceWiseDataOptions["index"]}`
    );
    const dbRef = ref(futureDB, `/greeks/Option/${expiry}/${index}`);
    onValue(dbRef, (snapshot) => {
      // snapshot.forEach((snapshot) => {
      //   tempData.push(snapshot.val());
      //   downData.push(snapshot.val());
      // });
      tempData = snapshot.val();

      setStrikeData(tempData);
    });
  }, [expiry, strikePriceWiseDataOptions, indexWiseExpiry]);
  console.log(strikeData);
  useEffect(() => {
    console.log("local,storage first time");
    // Save the user's first visit time in a state variable
    const savedTime = localStorage.getItem("firstVisitTime");
    const savedData = localStorage.getItem("firstVisitData");
    const firstVisitData = strikeData;
    if (savedData) {
      setViData(strikeData);
    } else {
      localStorage.setItem("firstVisitData", firstVisitData);
    }
    if (savedTime) {
      setFirstVisitTime(new Date(savedTime));
    } else {
      localStorage.setItem("firstVisitTime", new Date());
    }
  }, [dateState, time]);

  useEffect(() => {
    const optionTime = time * 60;

    const interval = setInterval(() => {
      const currentTime = new Date();
      const timeDifference = (currentTime - firstVisitTime) / 1000; // Calculate the time difference in seconds

      if (timeDifference >= optionTime) {
        // Run your function here
        console.log(
          "Function is running because the time difference is >= 30 seconds."
        );
        console.log(viData);
        setDateState(!dateState);
      }
    }, 1000 * optionTime); // Run the function every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [firstVisitTime]);
  useEffect(() => {
    const optionTime = time * 60;

    const interval = setInterval(() => {
      const currentTime = new Date();
      const timeDifference = (currentTime - firstVisitTime) / 1000; // Calculate the time difference in seconds

      if (timeDifference >= optionTime) {
        // Run your function here
        console.log(
          "Function is running because the time difference is >= 30 seconds."
        );
        console.log(viData);
        setDateState(!dateState);
      }
    }, 1000 * optionTime); // Run the function every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [firstVisitTime]);

  //------------- Option Data code---------------

  useEffect(() => {
    setComponentLoading(true);
    const rtdb = getDatabase(app);
    // get list of expiries from realtime database first

    const expiryRef = ref(rtdb, "tick/Option");

    // Attach a listener to the "expiry" reference to listen for changes in the data
    const onExpiryValueChange = onValue(expiryRef, (snapshot) => {
      const expiryData = snapshot.val();

      // Convert the data to an array if it's an object
      var expiryArray = expiryData ? Object.keys(expiryData) : [];
      // var NiftDate=
      // remove all dates before today from expiryArray
      const currentDate = new Date();

      const currentDateStartOfDay = new Date(currentDate);
      currentDateStartOfDay.setHours(0, 0, 0, 0);

      expiryArray = expiryArray.filter((dateString) => {
        const [dd, mm, yy] = dateString.split("-").map(Number);
        const fullYear = yy >= 50 ? 1900 + yy : 2000 + yy;
        const expiryDate = new Date(fullYear, mm - 1, dd); // Adjust year to 4-digit format

        // Include dates on or after the current date (ignoring time)
        return expiryDate >= currentDateStartOfDay;
      });

      setExpiryDates(expiryArray);
      // setPortfolioOptions({
      //   ...portfolioOptions,
      //   date: expiryArray[0],
      // });
    });
    setComponentLoading(false);

    return () => {
      // Detach the listener when the component unmounts
      off(expiryRef, "value", onExpiryValueChange);
    };
  }, []);

  useEffect(() => {
    console.log("useEffect runn");
    let niftyDate = [];
    let bankNiftyDate = [];
    for (let i = 0; i < expiryDates.length; i++) {
      const dbRef = ref(futureDB, `tick/Option/${expiryDates[i]}`);
      onValue(dbRef, (snapshot) => {
        const temp = snapshot.val();
        if (temp) {
          if (temp.BANKNIFTY) {
            bankNiftyDate.push(expiryDates[i]);
          }
          if (temp.NIFTY) {
            niftyDate.push(expiryDates[i]);
          }
        }
      });
      setIndexWiseExpiry({
        ...indexWiseExpiry,
        nifty: niftyDate,
        banknifty: bankNiftyDate,
      });
      // setExpiry(niftyDate[0]);
    }

    niftyDate = [];
    bankNiftyDate = [];

    // const id = setTimeout(() => {
    //   setLoading(true);
    // }, 1000);

    // return () => {
    //   clearTimeout(id);
    // };
  }, [expiryDates]);

  useEffect(() => {
    const date =
      strikePriceWiseDataOptions.index === "NIFTY"
        ? indexWiseExpiry.nifty[0]
        : indexWiseExpiry.banknifty[0];
    setExpiry(date);
  }, [strikePriceWiseDataOptions["index"], indexWiseExpiry]);
  // console.log(expiry, indexWiseExpiry);

  useEffect(() => {
    // setLoading(false);
    console.log("runned option portfolio");
    function fetchData() {
      let currentcount = expiry;
      let currentindex = strikePriceWiseDataOptions["index"];
      console.log(`tick/Option/${currentcount}/${currentindex}`);
      let index = "";
      let temp = [];
      const dbRef = ref(
        futureDB,
        `tick/Option/${currentcount}/${currentindex}`
      );
      onValue(dbRef, (snapshot) => {
        // let option = portfolioOptions["optionChain"];

        temp = snapshot.val();
        setStrikePriceData(temp);

        console.log(temp);
        // setTitleList(strikeNumber);
      });
    }
    fetchData();
    // const timeToken = setTimeout(fetchData, 1000);

    // return () => {
    //   clearTimeout(timeToken);
    // };
  }, [strikePriceWiseDataOptions, indexWiseExpiry, expiry]);

  useEffect(() => {
    console.log(strikePriceWiseDataOptions["numStrikePrice"]);
    const index =
      strikePriceWiseDataOptions["index"] === "NIFTY"
        ? "NIFTY 50"
        : "NIFTY BANK";
    const niftyLTPRef = ref(futureDB, `tick/Index/${index}/LTP`);
    let strikePriceArray = [];
    onValue(niftyLTPRef, (snapshot) => {
      const niftyLTPData = snapshot.val();

      if (niftyLTPData) {
        strikePriceArray = buildStrikePricesArray(
          niftyLTPData,
          strikePriceWiseDataOptions["numStrikePrice"] || 5,
          strikePriceWiseDataOptions["index"] === "NIFTY" ? 50 : 100
        );

        strikePriceArray.sort();
        // setportfolioOptions({
        //   ...portfolioOptions,
        //   strikPrice: strikePriceArray[0],
        // });
      }
    });
    setStrikeArray(strikePriceArray);
  }, [
    strikePriceWiseDataOptions["index"],
    strikePriceWiseDataOptions["numStrikePrice"],
  ]);
  console.log(strikeArray);

  return (
    <>
      <div className="option-strikepricewisedata">
        <div className="subpage-dropdowns-container">
          <div className="dropdown-container">
            <select
              name="option-strikepricewisedata-index"
              id="option-strikepricewisedata-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setStrikePriceWiseDataOptions({
                  ...strikePriceWiseDataOptions,
                  index: e.target.value,
                });
              }}
              value={strikePriceWiseDataOptions.index}
            >
              <option value="NIFTY">Nifty 50</option>
              <option value="BANKNIFTY">Nifty Bank</option>
            </select>
            <p>Select Indices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-straddleandstraggle-index"
              id="option-straddleandstraggle-index"
              className="subpage-dropdown"
              onChange={(e) => {
                setExpiry(e.target.value);
              }}
              value={expiry}
            >
              {strikePriceWiseDataOptions.index === "NIFTY" ? (
                <>
                  {indexWiseExpiry.nifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {indexWiseExpiry.banknifty.map((expiryDate, index) => {
                    return (
                      <option key={index} value={expiryDate}>
                        {expiryDate}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <p>Select Expiry</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-strikepricewisedata-lotorvalue"
              id="option-strikepricewisedata-lotorvalue"
              className="subpage-dropdown"
              onChange={(e) => {
                setStrikePriceWiseDataOptions({
                  ...strikePriceWiseDataOptions,
                  lotOrValue: e.target.value,
                });
              }}
            >
              <option value="lot">Lot Wise</option>
              <option value="value">Value Wise</option>
            </select>
            <p>Lot Wise/Value Wise</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-strikepricewisedata-expiry"
              id="option-strikepricewisedata-expiry"
              className="subpage-dropdown"
              onChange={(e) => {
                setStrikePriceWiseDataOptions({
                  ...strikePriceWiseDataOptions,
                  parameter: e.target.value,
                });
              }}
            >
              <option value="LTP">LTP</option>
              <option value="OI">OI</option>
              <option value="Volume">Volume</option>
            </select>
            <p>Parameter</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-strikepricewisedata-numstrikeprice"
              id="option-strikepricewisedata-numstrikeprice"
              className="subpage-dropdown"
              onChange={(e) => {
                setStrikePriceWiseDataOptions({
                  ...strikePriceWiseDataOptions,
                  numStrikePrice: e.target.value,
                });
              }}
              value={strikePriceWiseDataOptions.numStrikePrice}
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={7}>7</option>
            </select>
            <p>Strike Prices</p>
          </div>
          <div className="dropdown-container">
            <select
              name="option-strikepricewisedata-time"
              id="option-strikepricewisedata-time"
              className="subpage-dropdown"
              onChange={(e) => {
                setTime(e.target.value);
              }}
              value={time}
            >
              <option value={0.5}>30 Seconds</option>
              <option value={1}>1 Minute</option>
              <option value={2}>2 Minutes</option>
              <option value={3}>3 Minutes</option>
              <option value={4}>4 Minutes</option>
              <option value={5}>5 Minutes</option>
            </select>
            <p>Time</p>
          </div>
        </div>
      </div>
      <StrikePricewiseTable
        strikeArray={strikeArray}
        strikePriceData={strikePriceData}
        strikeData={strikeData}
        viData={viData}
        strikePriceWiseDataOptions={strikePriceWiseDataOptions}
      />
    </>
  );
}

export default StrikePricewiseData;
