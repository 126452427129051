import React, { useEffect, useState } from "react";
import style from "./UpTable.module.css";
import UpTable from "./UpTable";
import DownTable from "./DownTable";
import FutureAnalysisContentTable from "./FutureAnalysisContentTable";
import { db, futureDB } from "../../firebase";
import { onValue, ref } from "firebase/database";

function calculateTimeDifference(timeInterval) {
  var userMinutesInput = timeInterval;

  var now = new Date();
  var startHour = 9;
  var startMinute = 15;
  var endHour = 15;
  var endMinute = 30;

  var currentHour = now.getHours();
  var currentMinute = now.getMinutes();

  // Convert current time to minutes for easier comparison
  var currentTimeInMinutes = currentHour * 60 + currentMinute;
  var startTimeInMinutes = startHour * 60 + startMinute;
  var endTimeInMinutes = endHour * 60 + endMinute;

  if (
    currentTimeInMinutes >= startTimeInMinutes &&
    currentTimeInMinutes <= endTimeInMinutes
  ) {
    var updatedTimeInMinutes = currentTimeInMinutes - userMinutesInput;
    if (updatedTimeInMinutes < startTimeInMinutes) {
      const time = endTimeInMinutes - timeInterval;
      let updatedTimeHour = Math.floor(time / 60);
      let updatedTimeMinute = time % 60;
      let formattedTimex = formatTimeWithAMPM(
        updatedTimeHour,
        updatedTimeMinute
      );
      return formattedTimex;
    }
    let updatedHour = Math.floor(updatedTimeInMinutes / 60);
    let updatedMinute = updatedTimeInMinutes % 60;
    let formattedTime = formatTimeWithAMPM(updatedHour, updatedMinute);
    return formattedTime;
  } else {
    const time = endTimeInMinutes - timeInterval;
    let updatedTimeHour = Math.floor(time / 60);
    let updatedTimeMinute = time % 60;
    let formattedTimex = formatTimeWithAMPM(updatedTimeHour, updatedTimeMinute);
    return formattedTimex;
  }
}

function formatTimeWithAMPM(hours, minutes) {
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  var formattedHour = hours.toString();
  var formattedMinute = ("0" + minutes).slice(-2); // Add a leading zero to minutes
  var formattedTime = formattedHour + ":" + formattedMinute + " " + ampm;
  return formattedTime;
}

function getMonth() {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let name = month[d.getMonth()];
  return name;
}

export default function FutureAnalysisTable({ tableData, titleList }) {
  const [topFutureList15, setTopFutureList15] = useState([]);
  const [topFutureList30, setTopFutureList30] = useState([]);
  const [topFutureList45, setTopFutureList45] = useState([]);
  const [topFutureList60, setTopFutureList60] = useState([]);
  const [downFutureList15, setDownFutureList15] = useState([]);
  const [downFutureList30, setDownFutureList30] = useState([]);
  const [downFutureList45, setDownFutureList45] = useState([]);
  const [downFutureList60, setDownFutureList60] = useState([]);

  const url15minutes = calculateTimeDifference(15);

  const url30minutes = calculateTimeDifference(30);
  const url45minutes = calculateTimeDifference(45);
  const url60minutes = calculateTimeDifference(60);
  console.log("23" + getMonth().slice(0, 3).toUpperCase());
  const currentDate = "23" + getMonth().slice(0, 3).toUpperCase();
  useEffect(() => {
    let tempData = [];
    let downData = [];
    const dbRef = ref(
      futureDB,
      `/recent data/${url15minutes}/Future/${currentDate}`
    );
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((snapshot) => {
        tempData.push(snapshot.val());
        downData.push(snapshot.val());
      });
      setTopFutureList15(dataSort(tempData));

      setDownFutureList15(dataSortDown(downData));
    });
  }, [url15minutes]);

  useEffect(() => {
    let tempData = [];
    let downData = [];
    const dbRef = ref(
      futureDB,
      `/recent data/${url30minutes}/Future/${currentDate}`
    );
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((snapshot) => {
        tempData.push(snapshot.val());
        downData.push(snapshot.val());
      });
      setTopFutureList30(dataSort(tempData));
      setDownFutureList30(dataSortDown(downData));
      console.log(topFutureList30);
    });
  }, []);
  useEffect(() => {
    let tempData = [];
    let downData = [];
    const dbRef = ref(
      futureDB,
      `/recent data/${url45minutes}/Future/${currentDate}`
    );
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((snapshot) => {
        tempData.push(snapshot.val());
        downData.push(snapshot.val());
      });
      setTopFutureList45(dataSort(tempData));
      setDownFutureList45(dataSortDown(downData));
    });
  }, []);
  useEffect(() => {
    let tempData = [];
    let downData = [];
    const dbRef = ref(
      futureDB,
      `/recent data/${url60minutes}/Future/${currentDate}`
    );
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((snapshot) => {
        tempData.push(snapshot.val());
        downData.push(snapshot.val());
      });
      setTopFutureList60(dataSort(tempData));
      setDownFutureList60(dataSortDown(downData));
    });
  }, []);

  function dataSort(tempData) {
    const tempArray = tempData.sort((a, b) => {
      return b.LTP - b.Prev_Close - (a.LTP - a.Prev_Close);
    });

    return tempArray;
  }
  function dataSortDown(tempData) {
    const tempArray = tempData.sort((a, b) => {
      return a.LTP - a.Prev_Close - (b.LTP - b.Prev_Close);
    });

    return tempArray;
  }

  if (tableData.length <= 0) return <>loading...</>;
  return (
    <div>
      <div className={style.tableContainer}>
        <UpTable
          topFutureList15={topFutureList15}
          topFutureList30={topFutureList30}
          topFutureList45={topFutureList45}
          topFutureList60={topFutureList60}
        />
        <DownTable
          downFutureList15={downFutureList15}
          downFutureList30={downFutureList30}
          downFutureList45={downFutureList45}
          downFutureList60={downFutureList60}
        />
      </div>
      <FutureAnalysisContentTable tableData={tableData} titleList={titleList} />
    </div>
  );
}
