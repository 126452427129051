import React, { useState } from "react";
import style from "./IntradayOptionChain.module.css";
function IntradayOptionChain(props) {
  const { data, strikeArray, ivValue, futureOI, intradaySignalsOptions } =
    props;
  let number = 1;
  if (intradaySignalsOptions.index === "NIFTY") {
    number = intradaySignalsOptions.lotOrValue === "value" ? 50 : 1;
  }
  if (intradaySignalsOptions.index === "BANKNIFTY") {
    number = intradaySignalsOptions.lotOrValue === "value" ? 15 : 1;
  }
  console.log(data);
  const [resultLoad, setResultLoad] = useState(false);
  const [result, setResult] = useState();
  const [checkValue, setCheckValue] = useState({
    futureoi: 0,
    premiumdecay: 0,
    coi: 0,
    volume: 0,
    iv: 0,
  });

  const [allchecked, setAllChecked] = useState({
    futureoi: false,
    premiumdecay: false,
    coi: false,
    volume: false,
    iv: false,
  });

  function handleClose() {
    setResultLoad(false);
  }
  function handleChange(e) {
    console.log(e.target.checked);
    const check = e.target.checked;
    const value = e.target.value;

    setAllChecked({ ...allchecked, [value]: check });
  }
  // console.log(checkValue);
  const handleSubmit = () => {
    let count = 0;
    for (let key in checkValue) {
      count = count + checkValue[key];
    }
    if (count > 100) window.alert("100 exceeded");

    //call----->
    const ivCall =
      (strikeArray
        .reduce((acc, curr) => {
          return acc + (ivValue?.Calls[curr]?.IV % 100000);
        }, 0)
        .toFixed(2) *
        checkValue.iv) /
      100;

    const volumeCall =
      (strikeArray.reduce((acc, curr) => {
        return acc + (data.Calls[curr].Volume % 100000);
      }, 0) *
        checkValue.volume) /
      100;

    const coiCall =
      (strikeArray.reduce((acc, curr) => {
        const change = Number(
          data?.Calls[curr]?.OI - data?.Calls[curr]?.Prev_Close
        );
        return acc + (change % 100000);
      }, 0) *
        checkValue.coi) /
      100;

    const premiumdecayCall =
      (strikeArray
        .reduce((acc, curr) => {
          const change =
            Number(data.Calls[curr]?.LTP - data.Calls[curr]?.Prev_Close) %
            100000;
          return acc + change;
        }, 0)
        .toFixed(2) *
        checkValue.premiumdecay) /
      100;

    //put----->
    const ivPut =
      (strikeArray
        .reduce((acc, curr) => {
          return acc + (ivValue?.Puts[curr]?.IV % 100000);
        }, 0)
        .toFixed(2) *
        checkValue.iv) /
      100;

    const volumePut =
      (strikeArray.reduce((acc, curr) => {
        return acc + (data.Puts[curr].Volume % 100000);
      }, 0) *
        checkValue.volume) /
      100;

    const coiPut = strikeArray.reduce((acc, curr) => {
      const change =
        (Number(data?.Puts[curr]?.OI - data?.Puts[curr]?.Prev_Close) *
          checkValue.coi) /
        100;

      return acc + (change % 100000);
    }, 0);
    const premiumdecayPut =
      (strikeArray
        .reduce((acc, curr) => {
          const change =
            Number(data.Puts[curr]?.LTP - data.Puts[curr]?.Prev_Close) % 100000;

          return acc + change;
        }, 0)
        .toFixed(2) *
        checkValue.premiumdecay) /
      100;

    const futureOImod = futureOI.OI % 100000;
    const totalCall = ivCall + volumeCall + coiCall + premiumdecayCall;
    const totalPut = ivPut + volumePut + coiPut + premiumdecayPut;
    const totalsum = totalCall - totalPut;
    setResult(totalsum.toFixed(2));
    setResultLoad(true);
  };
  if (!data) return <>loading...</>;
  // if (!ivValue) return <>loading...</>;
  return (
    <div>
      <table
        className="info-table"
        style={{ width: "95%", marginLeft: "1rem", marginRight: "1rem" }}
        data-theme={props.theme}
      >
        <thead>
          <tr>
            <th colSpan={5}>Call</th>{" "}
            <th className="strike-prices">Strike Prices</th>{" "}
            <th colSpan={5}>Put</th>
          </tr>
          <tr>
            {/* call columns */}
            <th>IV</th>
            <th>Volume</th>
            <th>COI</th>
            <th>Premium Decay</th>
            <th>LTP</th>
            <th className="strike-prices"></th>
            {/* put columns */}
            <th>LTP</th> <th>Premium Decay</th> <th>COI</th>
            <th>Volume</th> <th>IV</th>
          </tr>
        </thead>
        <tbody>
          {strikeArray.map((strike) => {
            const callChange = (
              data.Calls[strike]?.LTP - data.Calls[strike]?.Prev_Close
            ).toFixed(2);
            const putChange = (
              data.Puts[strike]?.LTP - data.Puts[strike]?.Prev_Close
            ).toFixed(2);
            return (
              <tr>
                {/* call columns */}
                <td>{ivValue?.Calls[strike]?.IV}</td>
                <td>{data.Calls[strike]?.Volume / number}</td>
                <td>
                  {(
                    (data?.Calls[strike]?.OI -
                      data?.Calls[strike]?.Prev_Open_Int_Close) /
                    number
                  ).toFixed(2)}
                </td>
                <td style={{ color: `${callChange < 0 ? "red" : "green"}` }}>
                  {callChange}
                </td>
                <td>{data.Calls[strike]?.LTP}</td>{" "}
                <td className="strike-prices">{strike}</td>
                {/* put columns */}
                <td>{data.Puts[strike]?.LTP}</td>
                <td style={{ color: `${putChange < 0 ? "red" : "green"}` }}>
                  {putChange}
                </td>
                <td>
                  {(data.Puts[strike]?.OI -
                    data.Puts[strike]?.Prev_Open_Int_Close) /
                    number}
                </td>
                <td>{data.Puts[strike]?.Volume / number}</td>
                <td>{ivValue?.Puts[strike]?.IV}</td>{" "}
              </tr>
            );
          })}
          <tr>
            <td>
              {strikeArray
                .reduce((acc, curr) => {
                  return acc + ivValue?.Calls[curr]?.IV;
                }, 0)
                .toFixed(2)}
            </td>
            <td>
              {strikeArray.reduce((acc, curr) => {
                return acc + data.Calls[curr].Volume;
              }, 0) / number}
            </td>
            <td>
              {strikeArray.reduce((acc, curr) => {
                const change = Number(
                  data?.Calls[curr]?.OI - data?.Calls[curr]?.Prev_Open_Int_Close
                );

                return acc + change;
              }, 0) / number}
            </td>
            <td>
              {strikeArray
                .reduce((acc, curr) => {
                  const change = Number(
                    data.Calls[curr]?.LTP - data.Calls[curr]?.Prev_Close
                  );

                  return acc + change;
                }, 0)
                .toFixed(2)}
            </td>
            <td>
              {strikeArray
                .reduce((acc, curr) => {
                  return acc + data.Calls[curr]?.LTP;
                }, 0)
                .toFixed(2)}
            </td>
            <td>Total</td>
            <td>
              {strikeArray
                .reduce((acc, curr) => {
                  return acc + data.Calls[curr]?.LTP;
                }, 0)
                .toFixed(2)}
            </td>
            <td>
              {strikeArray
                .reduce((acc, curr) => {
                  const change = Number(
                    data.Puts[curr]?.LTP - data.Puts[curr]?.Prev_Close
                  );

                  return acc + change;
                }, 0)
                .toFixed(2)}
            </td>
            <td>
              {strikeArray.reduce((acc, curr) => {
                const change = Number(
                  data?.Puts[curr]?.OI - data?.Puts[curr]?.Prev_Open_Int_Close
                );

                return acc + change;
              }, 0) / number}
            </td>

            <td>
              {strikeArray.reduce((acc, curr) => {
                return acc + data.Puts[curr].Volume;
              }, 0) / number}
            </td>
            <td>
              {strikeArray
                .reduce((acc, curr) => {
                  return acc + ivValue?.Puts[curr]?.IV;
                }, 0)
                .toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={style.card_container}>
        {resultLoad ? (
          <>
            {result}
            <br />
            <button
              className={style.closeBtn}
              type="button"
              onClick={handleClose}
            >
              close
            </button>
          </>
        ) : (
          <>
            {" "}
            paramter
            <div className={style.item_container}>
              <div className={style.item}>Future OI</div>
              <div className={style.items}>
                <div className={style.checkbox}>
                  <input
                    onChange={handleChange}
                    value="futureoi"
                    type="checkbox"
                    id="scales"
                    name="scales"
                  />
                </div>
                <div className={style.weightage}>
                  <input
                    type="text"
                    className={style.input_text}
                    placeholder="Weightage"
                    onChange={(e) => {
                      if (!allchecked.futureoi) return;
                      setCheckValue({
                        ...checkValue,
                        futureoi: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.item_container}>
              <div className={style.item}>Premium Decay</div>
              <div className={style.items}>
                <div className={style.checkbox}>
                  <input
                    onChange={handleChange}
                    value="premiumdecay"
                    type="checkbox"
                    id="scales"
                    name="scales"
                  />
                </div>
                <div className={style.weightage}>
                  <input
                    type="text"
                    className={style.input_text}
                    placeholder="Weightage"
                    onChange={(e) => {
                      if (!allchecked.premiumdecay) return;
                      setCheckValue({
                        ...checkValue,
                        premiumdecay: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.item_container}>
              <div className={style.item}>Change in OI</div>
              <div className={style.items}>
                <div className={style.checkbox}>
                  <input
                    onChange={handleChange}
                    value="coi"
                    type="checkbox"
                    id="scales"
                    name="scales"
                  />
                </div>
                <div className={style.weightage}>
                  <input
                    type="text"
                    className={style.input_text}
                    placeholder="Weightage"
                    onChange={(e) => {
                      if (!allchecked.coi) return;
                      setCheckValue({
                        ...checkValue,
                        coi: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.item_container}>
              <div className={style.item}>COI/Volume</div>
              <div className={style.items}>
                <div className={style.checkbox}>
                  <input
                    onChange={handleChange}
                    value="volume"
                    type="checkbox"
                    id="scales"
                    name="scales"
                  />
                </div>
                <div className={style.weightage}>
                  <input
                    type="text"
                    className={style.input_text}
                    placeholder="Weightage"
                    onChange={(e) => {
                      if (!allchecked.volume) return;
                      setCheckValue({
                        ...checkValue,
                        volume: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.item_container}>
              <div className={style.item}>IV</div>
              <div className={style.items}>
                <div className={style.checkbox}>
                  <input
                    onChange={handleChange}
                    value="iv"
                    type="checkbox"
                    id="scales"
                    name="scales"
                  />
                </div>
                <div className={style.weightage}>
                  <input
                    type="text"
                    className={style.input_text}
                    placeholder="Weightage"
                    onChange={(e) => {
                      if (!allchecked.iv) return;
                      setCheckValue({
                        ...checkValue,
                        iv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <button
              className={style.saveBtn}
              type="button"
              onClick={handleSubmit}
            >
              Save
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default IntradayOptionChain;
