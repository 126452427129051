import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

import "./DashboardHeader.css";
import "../App.css";

import logoSvg from "../assets/logo.svg";
import darkLogoSvg from "../assets/logo-dark.svg";
import lightModeButton from "../assets/lightModeButton.svg";
import darkModeButton from "../assets/darkModeButton.svg";
import userIcon from "../assets/user-icon.svg";
import redDown from "../assets/down-arrow-red.png";
import greenUp from "../assets/green-arrow-up.png";

import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CardContent, Card } from "@mui/material";
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";

const DashBoardHeader = (props) => {
  const [error, setError] = useState("");
  const [userImgUrl, setUserImgUrl] = useState("");
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser) {
      // console.log(currentUser)
      if (currentUser.photoURL) {
        setUserImgUrl(currentUser.photoURL);
        return;
      }
      const userDocRef = doc(db, "users", currentUser.uid);
      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();

          if (userData && userData.hasOwnProperty("image_url")) {
            setUserImgUrl(userData.image_url);
            // You can store the imageUrl in a variable here or perform other actions.
          } else {
            setUserImgUrl("");
          }
        }
      });
    }
  }, [currentUser]);

  const handleLogout = async () => {
    setError("");
    try {
      await logout();
      navigate("/");
    } catch {}
  };
  const breakPoint = "lg";
  return (
    <>
      <Navbar
        data-theme={props.theme}
        variant={props.theme}
        className={
          `dashboard-header navbar-custom pb-2 pt-2 ` +
          (window.innerWidth > 1000 ? " ps-5 pe-5" : " ps-4 pe-4")
        }
        key={breakPoint}
        expand={breakPoint}
        collapseOnSelect
      >
        <Container fluid className="nav-container justify-content-between">
          <div className="navbar-logo-container">
            <Navbar.Brand className="navbar-logo navbar-left max-auto" href="/">
              <img
                className="navbar-logo"
                src={props.theme === "light" ? logoSvg : darkLogoSvg}
                alt="FnO-Bazaar"
              />
            </Navbar.Brand>
          </div>
          {props.liveData && (
            <div className="live-data-header" data-theme={props.theme}>
              <div
                className="live-data-header-container"
                style={{ borderRight: "1px solid grey" }}
              >
                <div className="live-data-header-card">
                  <p className="live-data-header-title">Nifty 50</p>
                  <div
                    style={
                      props.liveData.nifty.change >= 0
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    <p className="live-data-header-index-number">
                      <b>
                        <img
                          src={
                            props.liveData.nifty.change >= 0 ? greenUp : redDown
                          }
                          alt="index move"
                        />{" "}
                        {props.liveData.nifty.current}
                      </b>
                    </p>
                    <p>
                      {(props.liveData.nifty.change >= 0 ? "+" : "") +
                        props.liveData.nifty.change +
                        " (" +
                        (
                          (props.liveData.nifty.change /
                            props.liveData.nifty.current) *
                          100
                        ).toFixed(2) +
                        "%)"}
                    </p>
                  </div>
                </div>
                <div className="live-data-header-card">
                  <p className="live-data-header-title">Nifty Fut</p>
                  <div
                    style={
                      props.liveData.niftyfut.change >= 0
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    <p className="live-data-header-index-number">
                      <b>
                        <img
                          src={
                            props.liveData.niftyfut.change >= 0
                              ? greenUp
                              : redDown
                          }
                          alt="index move"
                        />{" "}
                        {props.liveData.niftyfut.current}
                      </b>
                    </p>
                    <p>
                      {(props.liveData.niftyfut.change >= 0 ? "+" : "") +
                        props.liveData.niftyfut.change +
                        " (" +
                        (
                          (props.liveData.niftyfut.change /
                            props.liveData.niftyfut.current) *
                          100
                        ).toFixed(2) +
                        "%)"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="live-data-header-container">
                <div
                  className="live-data-header-card"
                  style={{ marginLeft: "8px" }}
                >
                  <div>
                    <p className="live-data-header-title">Nifty Bank</p>
                  </div>
                  <div
                    style={
                      props.liveData.bankNifty.change >= 0
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    <p className="live-data-header-index-number">
                      <b>
                        <img
                          src={
                            props.liveData.bankNifty.change >= 0
                              ? greenUp
                              : redDown
                          }
                          alt="index move"
                        />{" "}
                        {props.liveData.bankNifty.current}
                      </b>
                    </p>
                    <p>
                      {(props.liveData.bankNifty.change >= 0 ? "+" : "") +
                        props.liveData.bankNifty.change +
                        " (" +
                        (
                          (props.liveData.bankNifty.change /
                            props.liveData.bankNifty.current) *
                          100
                        ).toFixed(2) +
                        "%)"}
                    </p>
                  </div>
                </div>
                <div
                  className="live-data-header-card"
                  style={{ marginLeft: "8px" }}
                >
                  <div>
                    <p className="live-data-header-title">BankNifty Fut</p>
                  </div>
                  <div
                    style={
                      props.liveData.bankNiftyFut.change >= 0
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    <p className="live-data-header-index-number">
                      <b>
                        <img
                          src={
                            props.liveData.bankNiftyFut.change >= 0
                              ? greenUp
                              : redDown
                          }
                          alt="index move"
                        />{" "}
                        {props.liveData.bankNiftyFut.current}
                      </b>
                    </p>
                    <p>
                      {(props.liveData.bankNiftyFut.change >= 0 ? "+" : "") +
                        props.liveData.bankNiftyFut.change +
                        " (" +
                        (
                          (props.liveData.bankNiftyFut.change /
                            props.liveData.bankNiftyFut.current) *
                          100
                        ).toFixed(2) +
                        "%)"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${breakPoint}`}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${breakPoint}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${breakPoint}`}
            data-theme={props.theme}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${breakPoint} ${props.theme}`}
              >
                Navigation
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                id="dashboard-nav"
                className={`justify-content-center flex-grow-1 dashboard-navbar-left ${props.theme}`}
                navbarScroll
                data-theme={props.theme}
              >
                <Nav.Link
                  as={Link}
                  onClick={() => {
                    props.tabSwitcher("option");
                  }}
                  className={props.curTab === "option" ? "active" : "inactive"}
                >
                  Option
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => {
                    props.tabSwitcher("future");
                  }}
                  className={props.curTab === "future" ? "active" : "inactive"}
                >
                  Future
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => {
                    props.tabSwitcher("stock");
                  }}
                  className={props.curTab === "stock" ? "active" : "inactive"}
                >
                  Stock
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => {
                    props.tabSwitcher("dailyactivities");
                  }}
                  className={
                    props.curTab === "dailyactivities" ? "active" : "inactive"
                  }
                >
                  Daily Activities
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => {
                    props.tabSwitcher("others");
                  }}
                  className={props.curTab === "others" ? "active" : "inactive"}
                >
                  Others
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => {
                    props.tabSwitcher("portfolio");
                  }}
                  className={
                    props.curTab === "portfolio" ? "active" : "inactive"
                  }
                >
                  Portfolio
                </Nav.Link>
              </Nav>
              <button
                id="light-dark-toggle"
                className={`ms-${breakPoint}-5`}
                onClick={props.themeSwitcher}
              >
                <img
                  style={{ height: "34px" }}
                  src={
                    props.theme === "light" ? lightModeButton : darkModeButton
                  }
                  alt="Light Mode"
                />
              </button>
              {props.liveData && (
                <div
                  style={{ marginLeft: "20px" }}
                  className="live-data-header container-width"
                  data-theme={props.theme}
                >
                  <div
                    className="live-data-header-container "
                    style={{ borderRight: "1px solid grey" }}
                  >
                    <div className="live-data-header-card">
                      {/* <p className=''>Down from day's high</p><br></br> */}
                      <p className="live-data-header-title">Nifty 50</p>
                      <div
                        style={
                          props.liveData.nifty.todaysHigh -
                            props.liveData.nifty.current <=
                          0
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        <p className="live-data-header-index-number">
                          <b>
                            <img
                              src={
                                props.liveData.nifty.todaysHigh -
                                  props.liveData.nifty.current <=
                                0
                                  ? greenUp
                                  : redDown
                              }
                              alt="index move"
                            />{" "}
                            {(
                              props.liveData.nifty.todaysHigh -
                              props.liveData.nifty.current
                            ).toFixed(2)}
                          </b>
                        </p>
                        {/* <p>
                                        {(props.liveData.nifty.todaysHigh-props.liveData.nifty.current<=0 ? '+' : '') + props.liveData.nifty.change + " (" + (props.liveData.nifty.change/props.liveData.nifty.current*100).toFixed(2) + "%)"}
                                    </p> */}
                      </div>
                    </div>
                    <div className="live-data-header-card">
                      <p className="live-data-header-title">Bank Nifty</p>
                      {/* {console.log(props.liveData.bankNifty.todaysHigh)} */}
                      <div
                        style={
                          props.liveData.bankNifty.todaysHigh -
                            props.liveData.bankNifty.current <=
                          0
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        <p className="live-data-header-index-number">
                          <b>
                            <img
                              src={
                                props.liveData.bankNifty.todaysHigh -
                                  props.liveData.bankNifty.current <=
                                0
                                  ? greenUp
                                  : redDown
                              }
                              alt="index move"
                            />{" "}
                            {(
                              props.liveData.bankNifty.todaysHigh -
                              props.liveData.bankNifty.current
                            ).toFixed(2)}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{}} className="live-data-header-container">
                    <div
                      className="live-data-header-card"
                      style={{ marginLeft: "8px" }}
                    >
                      <div>
                        <p className="live-data-header-title">Nifty 50</p>
                      </div>

                      <div
                        style={
                          props.liveData.nifty.current -
                            props.liveData.nifty.todaysLow >=
                          0
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        <p className="live-data-header-index-number">
                          <b>
                            <img
                              src={
                                props.liveData.nifty.current -
                                  props.liveData.nifty.todaysLow >=
                                0
                                  ? greenUp
                                  : redDown
                              }
                              alt="index move"
                            />{" "}
                            {(
                              props.liveData.nifty.current -
                              props.liveData.nifty.todaysLow
                            ).toFixed(2)}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div
                      className="live-data-header-card"
                      style={{ marginLeft: "8px" }}
                    >
                      <div>
                        <p className="live-data-header-title">Bank Nifty</p>
                      </div>
                      <div
                        style={
                          props.liveData.bankNifty.current -
                            props.liveData.bankNifty.todaysLow >=
                          0
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        <p className="live-data-header-index-number">
                          <b>
                            <img
                              src={
                                props.liveData.bankNifty.current -
                                  props.liveData.bankNifty.todaysLow >=
                                0
                                  ? greenUp
                                  : redDown
                              }
                              alt="index move"
                            />{" "}
                            {(
                              props.liveData.bankNifty.current -
                              props.liveData.bankNifty.todaysLow
                            ).toFixed(2)}
                          </b>
                        </p>
                        {/* <p>
                                        {(props.liveData.bankNiftyFut.change>=0 ? '+' : '') + props.liveData.bankNiftyFut.change + " (" + (props.liveData.bankNiftyFut.change/props.liveData.bankNiftyFut.current*100).toFixed(2) + "%)"}
                                    </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Nav
                id="dashboard-header-usericon"
                className={`align-items-center gap-8 flex-grow-1 flex-end`}
                navbarScroll
              >
                <NavDropdown
                  title={
                    <img
                      className="dashboard-header-user-icon"
                      src={userImgUrl ? userImgUrl : userIcon}
                      alt="user-settings"
                    />
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item as={"button"}>My Profile</NavDropdown.Item>
                  <NavDropdown.Item as={"button"}>
                    My Subscription
                  </NavDropdown.Item>
                  <NavDropdown.Item as={"button"}>My Settings</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    as={"button"}
                    onClick={handleLogout}
                    style={{ color: "blue" }}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {error && <p className="dashboard-header-error">{error}</p>}
    </>
  );
};

export default DashBoardHeader;
